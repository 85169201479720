import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Image, FloatingLabel } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import Images from "../../CRMs/crm2.0";

const TspForm = ({ validated, submit }) => {
  const {
    plannerData,
    onChangeHandler,
    handleBlur,
    handleKeyPress,
    handleFocus,
  } = usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);
  const submitFormdata = (event) => {
    submit(event, formData);
  };
  return (
    <>
      <Card.Body className="fegli-section2 tspForm-section">
        <Form
          name="tspForm"
          onSubmit={submitFormdata}
          className="employee-form"
          noValidate
          validated={validated}
        >
          <div className="text-center">
            <div className="head-title">THRIFT SAVINGS PLAN (TSP)</div>
          </div>

          <Row>
            <Col md={6}>
              <div className="sub-title">
                <p className="mb-2">
                  The Thrift Savings Plan (TSP) is a retirement savings plan for
                  federal employees, similar to the savings and tax benefits of
                  a 401(k) offered to employees in the private sector. Congress
                  established the TSP in the Federal Employees' Retirement
                  System Act of 1986 to provide retirement income for federal
                  employees.
                </p>
              </div>
              <div className="section-head">EMPLOYER MATCHES</div>
              <table className="section-table">
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}></td>
                    <td>FERS</td>
                    <td>CSRS</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>
                      1%-3% of basic pay are matched
                    </td>

                    <td>100%</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td
                      style={{ textAlign: "left", borderBottom: "1px solid " }}
                    >
                      4%-5% of basic pay are matched
                    </td>
                    <td style={{ borderBottom: "1px solid " }}>50%</td>
                    <td style={{ borderBottom: "1px solid " }}>0%</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>6% +</td>
                    <td>0%</td>
                    <td>0%</td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-2">
                <div className="section-head">TSP TRADITIONAL BASICS</div>
                <ul
                  style={{
                    fontSize: "14px",
                    listStyleType: "disc",
                    marginBottom: "15px",
                  }}
                >
                  <li>Not taxed today. Pay tax when money is withdrawn.</li>
                  <li>Taxed on growth and interest accumulated.</li>
                </ul>
              </div>
              <div>
                <p style={{ fontSize: "14px" }}>
                  <b>NOTE: Roth and Traditional payroll contributions</b> are
                  both included in payroll tax calculations (e.g., Social
                  Security taxes).
                </p>
              </div>
              <div className="mt-2">
                <div className="section-head">INVESTMENT OF TSP ACCOUNTS</div>
                <div className="sub-title">
                  <p>
                    There are six investment funds for TSP accounts, the G Fund,
                    C Fund, F Fund, S Fund, I Fund and the Lifecycle funds, L
                    Fund. All employees may elect to invest any portion of their
                    current account balances and/or future contributions between
                    the funds in any way they choose. You will not be protected
                    by either the U.S. Government or the Federal Retirement
                    Thrift Investment board against investment loss in the
                    Funds, nor do they guarantee a return on your investment.
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <div className="section-head">FUND MANAGEMENT</div>
                <div className="semi-title">G Fund</div>
                <div className="sub-title">
                  <p>
                    The G Fund assets are managed internally by the Federal
                    Retirement Thrift Investment Board. The G Fund buys a
                    nonmarketable U.S. Treasury security that is guaranteed by
                    the U.S. Government. This means that the G Fund will not
                    lose money. The F, C, S, and I Funds are index funds, each
                    of which is invested in order to replicate the risk and
                    return characteristics of its appropriate benchmark index as
                    follows:
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <ul
                  style={{
                    fontSize: "14px",
                    fontStyle: "italic",
                    listStyleType: "disc",
                    marginBottom: "15px",
                    marginTop: "15px",
                  }}
                >
                  <li>
                    The F Fund's objective is to match the performance of the
                    Barclays Capital U.S. Aggregate Bond Index.
                  </li>
                  <li>
                    The C Fund's objective is to match the performance of the
                    Standard & Poor’s 500 (S&P 500) Index.
                  </li>
                  <li>
                    The S Fund's objective is to match the performance of the
                    Dow Jones U.S. Completion TSM Index.
                  </li>
                  <li>
                    The I Fund's objective is to match the performance of the
                    Morgan Stanley Capital International EAFE (Europe,
                    Australasia, Far East) Index.
                  </li>
                </ul>
              </div>
              <div>
                <div className="semi-title">L Fund</div>
                <div className="sub-title">
                  <p>
                    The L Funds are invested in the five individual TSP funds
                    based on professionally determined asset allocations. The
                    objective of the L Fund is to provide professionally
                    diversified portfolios based on various time horizons, using
                    the G, F, C, S, and I Funds.
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <div className="section-head">ACCESSING INFORMATION</div>
                <div className="sub-title">
                  <p>
                    The Thrift-Line is an automated telephone service for
                    participants which provides current account information 24
                    hours a day, 7 days a week. The Thrift-Line is 877-968-3778.
                    You will need to know your PIN and S.S. numbers. Also
                    accessible online through TSP website: www.tsp.gov
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <div className="sub-title">
                  <p>
                    You can use the Thrift-Line or the TSP Website for the
                    following:
                  </p>
                </div>
                <ul
                  style={{
                    fontSize: "14px",
                    listStyleType: "disc",
                    marginBottom: "15px",
                  }}
                >
                  <li>Account balance. (Total you have in each fund.)</li>
                  <li>Interfund transfer request. (1 per month. 4 a year.)</li>
                  <li>Loan information. (Status of a loan.)</li>
                  <li>Status of a withdrawal.</li>
                  <li>Most recent rates of return on the various Funds.</li>
                  <li>Change your Personal Identification Number (PIN)</li>
                </ul>
              </div>
              <div>
                <div className="section-head">EMERGENCY FUNDS</div>
                <div>
                  <ul style={{ fontSize: "14px", listStyleType: "disc" }}>
                    <li>
                      TSP ROTH and Traditional Accounts are great accumulation
                      vehicles for retirement planning.
                    </li>
                    <li>Emergency access during your career is limited.</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} xs={12}>
              <div className="Tsp2-heading">
                Individual Fund Returns (2002-2020)
              </div>
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginBottom: "15px",
                }}
              >
                <Image
                  style={{ display: "inline", width: "100%" }}
                  src={Images.riskGraph}
                />
              </div>
            </Col>
          </Row>
          <div className="panel-heading">
            <div className="panel-heading">
              <i className="fa-solid fa-star start-icon"></i>
              MFB Tip: Cone of Risk
            </div>
          </div>
          <div className="sub-title">
            <p>
              As you evaluate your tolerance for risk throughout your career,
              and as you are heading into retirement, you should be considering
              the impact of risk on your retirement assets. You should look at
              the various TSP Fund options as the illustrated cone gets wider
              and wider from the G Fund to the I Fund. This is called "The Cone
              of Risk". It is important that you align your TSP balance
              allocations and new contributions with the level of risk you are
              the most comfortable accepting, according to your time horizon.
              The basic concept is that most individuals have a higher risk
              tolerance early in their careers. But, as you approach retirement,
              most individuals risk tolerance reduces considerably due to the
              lack of time to recover from major swings in the stock market.
            </p>
          </div>
          <div className="mt-2">
            <div className="textbox-wrapper">
              <select
                name="lite_last_time_looked_at_tsp_account"
                value={formData.lite_last_time_looked_at_tsp_account}
                className={
                  formData.lite_last_time_looked_at_tsp_account && "has-val"
                }
                onChange={(event) => onChangeHandler(event, setformData)}
              >
                <option value=""></option>
                <option value="Within past year">Within past year</option>
                <option value="Within past 5 years">Within past 5 years</option>
                <option value="More than 5 years ago">
                  More than 5 years ago
                </option>
                <option value="I'm not sure">I'm not sure</option>
              </select>

              <div className="floating-placeholder">
                When was the last time you looked at your TSP account?
              </div>
            </div>
          </div>
          <div className="mt-2">
            <FloatingLabel
              controlId="floatingInput"
              label="TSP Account Balance"
              className="my-3"
            >
              <Form.Control
                autoComplete="off"
                className="tsp-2-textbox  required-input"
                type="text"
                name="tsp_s2_balance"
                placeholder="TSP Account Balance"
                value={formData.tsp_s2_balance}
                onBlur={(e) => handleBlur(e, true, setformData)}
                onKeyPress={(e) => handleKeyPress(e)}
                onFocus={(event) => handleFocus(event, setformData)}
                onChange={(event) => onChangeHandler(event, setformData)}
                isInvalid={validated && !formData.tsp_s2_balance}
                required
              />
            </FloatingLabel>
          </div>

          <Row>
            <Col md={6}>
              <div>
                <div className="section-head">TSP LOAN PROGRAM</div>
                <p
                  style={{
                    fontStyle: "italic",
                    fontWeight: "700",
                    fontSize: "14px",
                  }}
                >
                  Eligibility
                </p>
                <div>
                  <ul
                    style={{
                      fontSize: "14px",

                      listStyleType: "disc",
                      marginBottom: "15px",
                    }}
                  >
                    <li>Current employee in a pay status.</li>
                    <li>
                      TSP account must have at least $1,000 In employee
                      contributions and associated earnings.
                    </li>
                    <li>
                      Loan balance must be within limits of federal tax law.
                    </li>
                    <li>
                      Amount left in paycheck after loan payment and other
                      deductions must equal at least 10% of basic pay.
                    </li>
                    <li>
                      Must document expenses for the allowed purposes (described
                      below) or demonstrate financial hardship.
                    </li>
                    <li>
                      {" "}
                      <b>FERS</b> employees must get spousal consent.
                    </li>
                    <li>
                      The loan Interest rate is the G Fund rate on the date the
                      application is processed.
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="section-head">TSP ROTH VS ROTH IRA</div>

                <div>
                  <ul
                    style={{
                      fontSize: "14px",

                      listStyleType: "disc",
                      marginBottom: "15px",
                    }}
                  >
                    <li>
                      <b>TSP</b> Traditional - Not taxed today and pay tax when
                      money is withdrawn.
                    </li>
                    <li>
                      Emergency Access during your career is limited to hardship
                      withdrawal and TSP Loan.
                    </li>
                    <li>
                      Five year restriction to withdrawing ROTH TSP funds
                      without penalty
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <p
                  style={{
                    fontWeight: "700",
                    fontStyle: "italic",
                    fontSize: "14px",
                  }}
                >
                  Purposes
                </p>
              </div>
              <div>
                <ul
                  style={{
                    fontSize: "14px",
                    listStyleType: "disc",
                    marginBottom: "15px",
                  }}
                >
                  <li>
                    <b className="purpose-b">
                      Purchase of a Primary Residence -
                    </b>{" "}
                    Primary residence includes a house, condominium, townhouse,
                    or mobile home which is not used on a transient basis.
                  </li>
                  <li>
                    <b className="purpose-b">General Purpose Loans -</b>
                    Loans are available for any purpose according to plan
                    guidelines. You may have one general loan and one
                    residential loan from your TSP account at any one time.
                  </li>
                  <li>
                    <b className="purpose-b">Financial hardship -</b>
                    participants may also qualify for a hardship loan.
                  </li>
                </ul>
              </div>
              <div>
                <p
                  style={{
                    fontWeight: "700",
                    fontStyle: "italic",
                    fontSize: "14px",
                  }}
                >
                  Terms of Loan
                </p>
              </div>
              <div>
                <ul
                  style={{
                    fontSize: "14px",

                    listStyleType: "disc",
                    marginBottom: "15px",
                  }}
                >
                  <li>Residential is 1 to 15 years</li>
                  <li>General Purpose is 1 to 5 years</li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="mt-2">
                <div className="section-head">ROTH TSP HIGHLIGHTS</div>

                <div className="sub-title">
                  <p>
                    In 2012 the Roth option was introduced to TSP participants.
                    This new feature will allow you to contribute some or all of
                    your contributions to the Roth TSP. With Roth contributions,
                    you pay taxes now. However, when you withdraw these
                    contributions and their earning, you will not have to pay
                    taxes on them as long as you are 59 1/2 or older and you
                    have had Roth contributions for five years or more when you
                    receive your distributions.
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <div className="section-head">TSP MODERNIZATION ACT</div>
              </div>
              <div>
                <div className="sub-title">
                  <p>
                    What's changed? You now have more options for how and when
                    you can access money from your TSP account. These options
                    fall into the following categories:
                  </p>
                </div>
                <ul
                  style={{
                    fontSize: "14px",
                    listStyleType: "disc",
                    marginBottom: "15px",
                  }}
                >
                  <li>
                    After you separate from service, you can take multiple
                    post-separation partial withdrawals.
                  </li>
                  <li>
                    If you're 59 1/2 or older and still working in federal
                    civilian or uniformed service, you can take up to four
                    in-service withdrawals each year.
                  </li>
                  <li>
                    You can choose whether your withdrawal should come from your
                    Roth balance, your traditional balance, or a proportional
                    mix of both.
                  </li>
                  <li>
                    You no longer need to make a full withdrawal election after
                    you turn 73 and are separated from federal service. (You
                    will still need to receive IRS required minimum
                    distributions (RMDs)).
                  </li>
                  <li>
                    If you're a separated participant, you can take monthly,
                    quarterly, or annual payments.
                  </li>
                  <li>
                    You can stop, start, or make changes to your installment
                    payments at any time.
                  </li>
                  <li>
                    You now have enhanced online tools to help you make
                    withdrawals in the My Account section of{" "}
                    <a
                      style={{ color: "#337ab7", textDecoration: "none" }}
                      href="https://tsp.gov/"
                    >
                      tsp.gov
                    </a>
                    .
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          {validated && (
            <div className="red-text text-center mt-2">
              *Complete required fields to continue
            </div>
          )}
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default TspForm;
