import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Form } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import { convertValue, formatValue } from "../../../services/helperService";
import Images from "../../CRMs/crm2.0";
import moment from "moment";

const GapForm = ({ submit, formData }) => {
  const [calculatedData, setCalculatedData] = useState({});
  const { plannerData } = usePlannerContext();
  useEffect(() => {
    const fersAnnuityArr = [
      plannerData["fersBasicAnnuity"],
      plannerData["fersBasicAnnuity95"],
      plannerData["fersBasicAnnuity90"],
    ];
    const valueToUse = plannerData["valueToUse"]
      ? plannerData["valueToUse"] - 1
      : 0;
    const fersBasicAnnuity = convertValue(fersAnnuityArr[valueToUse]);
    const annualPay = convertValue(plannerData["pay_s2_1"]);
    const s8s8Income = convertValue(plannerData["s8s2_income_amount"]);
    let tsp_s2_balance = convertValue(plannerData["tsp_s2_balance"]);
    const today = annualPay / 12;

    tsp_s2_balance =
      !isEligibleFor55(plannerData["dob"]) && plannerData["ver_eligible"] == "1"
        ? 0
        : tsp_s2_balance * 0.0045;
    const reterement = fersBasicAnnuity + s8s8Income + tsp_s2_balance;
    const gap = today - convertValue(reterement);
    setCalculatedData({
      today: formatValue(today),
      reterement: formatValue(reterement),
      gap: formatValue(gap),
      fersBasicAnnuity: formatValue(fersBasicAnnuity),
      s8s8Income: formatValue(s8s8Income),
      tsp_s2_balance: formatValue(tsp_s2_balance),
      totalAmount: formatValue(fersBasicAnnuity + s8s8Income + tsp_s2_balance),
    });
    console.log(isEligibleFor55(plannerData["dob"]));
  }, [plannerData]);

  const submitFormdata = (event) => {
    submit(event, formData);
  };

  const isEligibleFor55 = (dob) => {
    if (!dob || !dob.isValid()) return false; // ID: 27

    const currentYear = moment().year();
    const birthYear = dob.year();
    const age = currentYear - birthYear;

    const hasHadBirthday =
      dob.month() < moment().month() ||
      (dob.month() === moment().month() && dob.date() <= moment().date());

    return age >= 55 || (age === 54 && hasHadBirthday);
  };

  return (
    <>
      <Card.Body className="gap-section">
        <div className="text-center">
          <div className="head-title">
            Filling The Income <span className="light-red">(GAP)</span>{" "}
          </div>
        </div>
        <Form
          name="FersForm"
          noValidate
          onSubmit={submitFormdata}
          className="employee-form"
        >
          <div className="single-field">
            <div className="single-field single-container">
              <div className="d-flex justify-content-between mb-2">
                <div className="image-continer">
                  <img src={Images.dollarGreen} className="image-size" alt="" />
                  <img src={Images.dollarGreen} className="image-size" alt="" />
                  <br />
                  <img src={Images.dollarGreen} className="image-size" alt="" />
                  <img src={Images.dollarGreen} className="image-size" alt="" />
                  <h3 className="title-text mt-2 text-center">TODAY</h3>
                  <h4 className="description-text">
                    ${" "}
                    <span className="price-text">
                      {calculatedData["today"]}
                    </span>
                  </h4>
                  <p className="text-item">Monthly Income</p>
                </div>
                <div className="mt-5 image-continer">
                  <img src={Images.gapArrow} className="d-inline" alt="" />
                </div>
                <div className="text-center image-continer">
                  <img src={Images.dollarGreen} className="image-size" alt="" />
                  <img
                    src={Images.dollarDisable}
                    className="image-size"
                    alt=""
                  />
                  <br />
                  <img src={Images.dollarGreen} className="image-size" alt="" />
                  <img
                    src={
                      !isEligibleFor55(plannerData["dob"]) < 55
                        ? Images.dollarDisable
                        : Images.dollarGreen
                    }
                    className="image-size"
                    alt=""
                  />
                  <h3 className="title-text text-center mt-2 fw-bold">
                    RETIREMENT
                  </h3>
                  <h4 className="description-text">
                    ${" "}
                    <span className="price-text">
                      {calculatedData["reterement"]}
                    </span>
                  </h4>
                  <p className="text-item">
                    Reduced Monthly Income at Retirement
                  </p>
                </div>
                <div className="mt-5 image-continer">
                  <h1 className="text-center fw-bold">=</h1>
                </div>
                <div className="image-continer">
                  <img
                    src={Images.dollarGreen}
                    className="image-size opacity-0"
                    alt=""
                  />
                  <img
                    src={Images.dollarDisable}
                    className="image-size"
                    alt=""
                  />
                  <br />
                  <img
                    src={Images.dollarGreen}
                    className="image-size opacity-0"
                    alt=""
                  />
                  <img
                    src={Images.dollarGreen}
                    className="image-size opacity-0"
                    alt=""
                  />
                  <h3
                    className="title-text text-center mt-2 fw-bold"
                    style={{ color: "#2e506b" }}
                  >
                    THE{" "}
                    <span style={{ fontSize: "28px" }} className="light-red">
                      (GAP)
                    </span>
                  </h3>
                  <h4 className="description-text">
                    ${" "}
                    <span className="price-text light-red">
                      ({calculatedData["gap"]})
                    </span>
                  </h4>
                  <p className="text-item">Loss in Monthly Income</p>
                </div>
              </div>
            </div>

            <div className="single-field gap-container">
              <div className="d-flex">
                <div>
                  <img src={Images.dollarGreen} className="image-size" alt="" />
                </div>
                <div className="text-description">
                  <div className="sub-description">
                    ${calculatedData["fersBasicAnnuity"]}
                  </div>
                </div>
                <div className="float-left text-center w-50">
                  <div className="sub-description">FERS Pension</div>
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <img src={Images.dollarGreen} className="image-size" alt="" />
                </div>
                <div className="text-description">
                  <div className="sub-description">
                    ${calculatedData["s8s8Income"]}
                  </div>
                </div>
                <div className="float-left text-center w-50">
                  <div className="sub-description">Social Security</div>
                </div>
              </div>
              {!isEligibleFor55(plannerData["dob"]) &&
              plannerData["ver_eligible"] == "1" ? (
                <div className="d-flex">
                  <div>
                    <img
                      src={Images.dollarDisable}
                      className="image-size"
                      alt=""
                    />
                  </div>
                  <div className="text-description">
                    <div className="amount-text">$0.00</div>
                  </div>
                  <div className="float-left text-center w-50">
                    <div
                      className="amount-text"
                      style={{ paddingBottom: "0px" }}
                    >
                      Thrift Savings Plan
                    </div>
                    <p style={{ fontSize: "14px", paddingBottom: "15px" }}>
                      *Special circumstances withdrawals may be available
                    </p>
                  </div>
                </div>
              ) : (
                <div className="d-flex">
                  <div>
                    <img
                      src={Images.dollarGreen}
                      className="image-size"
                      alt=""
                    />
                  </div>
                  <div className="text-description">
                    <div className="sub-description">
                      ${calculatedData["tsp_s2_balance"]}
                    </div>
                  </div>
                  <div className="float-left text-center w-50">
                    <div className="sub-description">Thrift Savings Plan</div>
                  </div>
                </div>
              )}
              <div className="d-flex">
                <div>
                  <img
                    src={Images.dollarDisable}
                    className="image-size"
                    alt=""
                  />
                </div>
                <div className="text-description">
                  <div className="amount-text">$0.00</div>
                </div>
                <div className="float-left text-center w-50">
                  <div className="amount-text">Other / ROTH IRA</div>
                </div>
              </div>
            </div>
            <div
              className="single-field"
              style={{
                width: "60%",
                margin: "0px auto",
                marginBottom: "50px",
              }}
            >
              <div className="d-flex">
                <div className="opacity-0">
                  <img
                    src={Images.dollarDisable}
                    className="image-size"
                    alt=""
                  />
                </div>
                <div className="text-description text-nowrap">
                  <div
                    style={{
                      fontWeight: 600,
                      margin: "0px auto",
                      padding: " 15px 0px",
                      fontSize: "27px",
                    }}
                  >
                    ${calculatedData["totalAmount"]}
                  </div>
                </div>
                <div className="float-left text-center w-50 opacity-0;"></div>
              </div>
            </div>
          </div>
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default GapForm;
