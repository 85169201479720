import tspRateList from "../constants/benefits/tsp-ret.json";
import moment from "moment";

export const Handle401 = (error) => {
  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status == 401
  ) {
    const url = window.location.pathname;
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("benefitPlan");
    if (url && url != "/login") {
      localStorage.setItem("url", url);
      window.location.href = "https://myagentcrm.com/crm/bpg-manager/";
    }
    return;
  }
};

export const extractIdAndScreenName = () => {
  // ID: 36
  const url = window.location.pathname;

  const urlParts = url.split("/");

  // ID: 37
  if (urlParts.length < 4 || urlParts[0] !== "" || urlParts[1] !== "bpg") {
    return "https://myagentcrm.com/crm/bpg-manager/";
  }

  // ID: 38
  const id = urlParts[2];
  const screenName = urlParts[3];

  console.log(id);

  // ID: 39
  return (
    "https://myagentcrm.com/mba/benefits-planner/?id=00000-" +
    id +
    "#" +
    (screenName ? screenName : "welcome")
  );
};

export const formatValue = (data) => {
  let value = "";
  if (data) {
    value = data && data.toString().replaceAll(",", "");

    value = parseFloat(value);
  }
  let formattedPrice = "";
  if (value) {
    formattedPrice = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
      .format(value)
      .replace("$", "");
  }
  return formattedPrice ? formattedPrice : "0.00";
};

export const formatCalculatedValue = (value) => {
  if (value) {
    let formattedPrice = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
      .format(value)
      .replace("$", "");

    return formattedPrice;
  } else {
    return value;
  }
};

export const getRetiermentYears = (formData) => {
  let months = 0;
  let years =
    formData["s2s5_age_to_start_taking_income"] &&
    formData["s2s5_age_to_start_taking_income"].toString().match(/\d+/)
      ? formData["s2s5_age_to_start_taking_income"].toString().match(/\d+/)[0]
      : 0;
  if (years) {
    months =
      formData["s2s5_age_to_start_taking_income"] &&
      formData["s2s5_age_to_start_taking_income"].toString().match(/\d+/) &&
      formData["s2s5_age_to_start_taking_income"].toString().match(/\d+/)[1];
    months = months && parseFloat(months);
  }

  return parseFloat(years) + parseFloat(months);
};

export const getMonthlyPayment = (amount, data) => {
  let MRA = Math.floor(data);
  let payload = {};
  tspRateList.map((val) => {
    if (val["age"] == MRA) {
      const key =
        Object.keys(val) &&
        Object.keys(val).find((name) => name.includes(amount));
      payload = { ...payload, [val.type]: val[key] };
      return val;
    }
  });

  return payload;
};

export const showValue = (data, isDollar = true) => {
  try {
    if (!data || data == "0" || data == "0.00") {
      return "N/A";
    }
    let amount = formatValue(data);
    if (isDollar) {
      if (amount && amount.toString().includes(".00")) {
        return "$" + amount.replace(".00", "");
      } else {
        return "$" + amount;
      }
    } else {
      return amount;
    }
  } catch (error) {
    console.log("error==> ", data);
  }
};

export const roundUpToNextThousand = (num) => {
  let value = convertValue(num);
  if (value > 999) {
    const lastThreeDigits = value % 1000;
    if (lastThreeDigits !== 0) {
      value += 1000 - lastThreeDigits;
    }
    return formatCalculatedValue(value);
  } else {
    if (value) {
      value = 1000;
      return formatValue(value);
    } else {
      value = 0;
      return formatValue(value);
    }
  }
};

export const convertValue = (value) => {
  let roundedValue =
    value && value.toString() && value.toString().replaceAll(",", "");
  roundedValue =
    roundedValue && parseFloat(roundedValue) ? parseFloat(roundedValue) : 0;
  return roundedValue;
};

export const integerValue = (value) => {
  let roundedValue =
    value && value.toString() && value.toString().replaceAll(",", "");
  roundedValue =
    roundedValue && parseInt(roundedValue) ? parseInt(roundedValue) : 0;
  return roundedValue;
};

export const getTokenAndId = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let token = params.get("token");
  let url = params.get("url");
  let bpgId = "";
  if (url) {
    if (url !== "/login") {
      localStorage.setItem("url", url);
    }
    const match = url.match(/bpg\/(\d+)/);
    bpgId = match && match[1] ? match[1] : bpgId;
  }

  return { token, bpgId, url };
};

var articleWindow = null;
export const openInNewTab = (Tab) => {
  articleWindow = window.open(
    window.location.origin + "/agent-compliance-and-assistance-screen/" + Tab,
    "popup",
    "width=900,height=900"
  );
};

export const updateArticleWindow = (tab) => {
  if (articleWindow == null) return;
  articleWindow.location.href =
    window.location.origin + "/agent-compliance-and-assistance-screen/" + tab;
};

export const closeArticlePopup = () => {
  if (articleWindow == null) return;
  articleWindow.close();
  articleWindow = null;
};

export const showPdfStatus = (dob, currentTab) => {
  let status = false;
  const age = moment().diff(dob, "years", true);

  const summary = [
    "summary",
    "benefits",
    "retirement",
    "retirement-s2",
    "more-about-you",
    "more-about-you-s2",
    "fers-annuity",
    "tsp-ret",
    "pensions-and-income",
    "expenses",
    "es-ret",
    "tsp-options",
    "conclusion",
    "client-survey",
    "protection",
  ];
  const conclusion = ["conclusion", "client-survey", "protection"];
  if (age < 55) {
    if (summary.includes(currentTab)) {
      status = true;
    }
  }
  if (age >= 55) {
    if (conclusion.includes(currentTab)) {
      status = true;
    }
  }
  return status;
};

export const formatInitialFormData = (data, payload = {}) => {
  let lifeAnnuity = "";
  let age = 0;
  if (data["tsp_opt_full_or_partial_wid"] == "1") {
    lifeAnnuity = "tsp_opt_full_or_partial_wid";
  }
  if (data["tsp_opt_tsp_monthly_pay"] == "1") {
    lifeAnnuity = "tsp_opt_tsp_monthly_pay";
  }
  if (data["tsp_opt_single_life_annuity_tsp"] == "1") {
    lifeAnnuity = "tsp_opt_single_life_annuity_tsp";
  }
  if (data["tsp_opt_single_life_annuity_private_sector"] == "1") {
    lifeAnnuity = "tsp_opt_single_life_annuity_private_sector";
  }
  if (data["tsp_opt_joint_life_annuity_tsp"] == "1") {
    lifeAnnuity = "tsp_opt_joint_life_annuity_tsp";
  }
  if (data["tsp_opt_joint_life_annuity_private_sector"] == "1") {
    lifeAnnuity = "tsp_opt_joint_life_annuity_private_sector";
  }

  if (data["dob"] && moment(data["dob"]) && moment(data["dob"]).isValid()) {
    age = moment().diff(moment(data["dob"]), "years", true);
    age = age.toFixed(2);
  }

  return {
    ...data,
    lifeAnnuity,
    bpgId: data["bpgId"],
    gender: data["gender"] ? data["gender"] : "male",
    family_status: data["family_status"] ? data["family_status"] : "single",
    spouse_employment: data["spouse_employment"]
      ? data["spouse_employment"]
      : "Working",

    divorce_decree_include_retirement_benefits_ex_spouse: data[
      "divorce_decree_include_retirement_benefits_ex_spouse"
    ]
      ? data["divorce_decree_include_retirement_benefits_ex_spouse"]
      : "yes",
    do_you_have_children: data["do_you_have_children"]
      ? data["do_you_have_children"]
      : "no",
    lite_pay_career: data["lite_pay_career"] ? data["lite_pay_career"] : "yes",
    have_military_service:
      data["have_military_service"] == "yes"
        ? data["have_military_service"]
        : "no",
    s2s3_receiving_pension:
      data["s2s3_receiving_pension"] == "yes"
        ? data["s2s3_receiving_pension"]
        : "no",
    s2s3_receiving_disability_pension:
      data["s2s3_receiving_disability_pension"] == "yes"
        ? data["s2s3_receiving_disability_pension"]
        : "no",
    deposit_made: data["deposit_made"] == "yes" ? data["deposit_made"] : "no",
    lite_pay_agency: data["lite_pay_agency"] ? data["lite_pay_agency"] : "USPS",

    howKnowledgeable: data["howKnowledgeable"] ? data["howKnowledgeable"] : "5",
    howWelAnswered: data["howWelAnswered"] ? data["howWelAnswered"] : "5",
    benefitedEarlier: data["benefitedEarlier"]
      ? data["benefitedEarlier"]
      : "yes",
    recommended: data["recommended"] ? data["recommended"] : "yes",

    comfortable_with_your_current_liquid:
      data["comfortable_with_your_current_liquid"] == "yes" ? "yes" : "no",
    s2s5_have_you_obtained: data["s2s5_have_you_obtained"]
      ? data["s2s5_have_you_obtained"]
      : "yes",
    ptf_time: data["ptf_time"] == "yes" ? data["ptf_time"] : "no",
    s2s6_ret_disability_receiving_soc_sec_payments:
      data["s2s6_ret_disability_receiving_soc_sec_payments"] == "yes"
        ? "yes"
        : "no",
    s2s6_spouse_employed_annual_income:
      data["s2s6_spouse_employed_annual_income"],
    s2s6_spouse_employed_desired_retirement_age:
      data["s2s6_spouse_employed_desired_retirement_age"],
    s2s6_spouse_employed_est_monthly_pension:
      data["s2s6_spouse_employed_est_monthly_pension"],
    s2s6_spouse_employed_age_to_start_social_security:
      data["s2s6_spouse_employed_age_to_start_social_security"],
    s2s6_spouse_employed_est_mon_soc_sec_inc:
      data["s2s6_spouse_employed_est_mon_soc_sec_inc"],
    s2s6_spouse_ret_disability_monthly_payment:
      data["s2s6_spouse_ret_disability_monthly_payment"],
    s2s6_spouse_ret_dis_age_to_start_soc_sec:
      data["s2s6_spouse_ret_dis_age_to_start_soc_sec"],
    s2s6_spouse_ret_dis_est_monthly_soc_sec_income:
      data["s2s6_spouse_ret_dis_est_monthly_soc_sec_income"],
    pay_s2_tsp_r_percent:
      data["pay_s2_100"] == "%" ? data["pay_s2_tsp_r_percent"] : "",
    pay_s2_tsp_percent:
      data["pay_s2_25"] == "%" ? data["pay_s2_tsp_percent"] : "",

    pre1989_part_time_service:
      data["pre1989_part_time_service"] == "yes"
        ? data["pre1989_part_time_service"]
        : "no",

    have_other_pension:
      data["have_other_pension"] == "yes" ? data["have_other_pension"] : "no",

    have_other_pension_name: data["have_other_pension_name"],

    s10_inv_statement_available: data["s10_inv_statement_available"]
      ? data["s10_inv_statement_available"]
      : "yes",
    s10_inv_statement_available_ext: data["s10_inv_statement_available_ext"]
      ? data["s10_inv_statement_available_ext"]
      : "yes",
    s10_liquid_inv_statement_available: data[
      "s10_liquid_inv_statement_available"
    ]
      ? data["s10_liquid_inv_statement_available"]
      : "",
    s10_liquid_inv_statement_available_ext: data[
      "s10_liquid_inv_statement_available_ext"
    ]
      ? data["s10_liquid_inv_statement_available_ext"]
      : "",
    real_estate_income_remaining_mortgage:
      data["real_estate_income_remaining_mortgage"] == "no"
        ? data["real_estate_income_remaining_mortgage"]
        : "yes",
    fegli_line3: data["fegli_line3"]
      ? formatValue(data["fegli_line3"])
      : formatValue("2000"),

    amountType: data["amountType"] ? data["amountType"] : "bi-weekly",

    bpg_ret_f1: data["bpg_ret_f1"] ? data["bpg_ret_f1"] : "no",

    bpg_ret_f2: data["bpg_ret_f2"] ? data["bpg_ret_f2"] : "no",

    bpg_ret_f4: data["bpg_ret_f4"] ? data["bpg_ret_f4"] : "no",
    s8s2_currently_receiving_social_security:
      data["s8s2_currently_receiving_social_security"] == "yes"
        ? data["s8s2_currently_receiving_social_security"]
        : "no",
    s8_will_receive_social_sec_payment: data[
      "s8_will_receive_social_sec_payment"
    ]
      ? data["s8_will_receive_social_sec_payment"]
      : "yes",

    outstanding_tsp_loan:
      data["outstanding_tsp_loan"] == "yes"
        ? data["outstanding_tsp_loan"]
        : "no",
    s13_q1: data["s13_q1"] == "yes" ? data["s13_q1"] : "no",
    dob: data["dob"] ? moment(data["dob"]) : null,
    age,
    spouse_dob: data["spouse_dob"] ? moment(data["spouse_dob"]) : null,
    seniority_date: data["seniority_date"]
      ? moment(data["seniority_date"])
      : null,

    desired_retirement_date: data["desired_retirement_date"]
      ? moment(data["desired_retirement_date"])
      : null,
    lite_pay_start_year: data["lite_pay_start_year"]
      ? moment(data["lite_pay_start_year"])
      : null,
    pay_s2_36: formatValue(data["pay_s2_36"]),
    pay_s2_1: formatValue(data["pay_s2_1"]),
    pay_s2_18: data["pay_s2_18"] ? formatValue(data["pay_s2_18"]) : "",
    pay_s2_29: data["pay_s2_29"] ? formatValue(data["pay_s2_29"]) : "",

    pay_s2_working_hours: formatValue(data["pay_s2_working_hours"]),
    pay_s2_working_hours_amount: formatValue(
      data["pay_s2_working_hours_amount"]
    ),
    pay_s2_overtime_hours: formatValue(data["pay_s2_overtime_hours"]),
    pay_s2_overtime_hours_amount: formatValue(
      data["pay_s2_overtime_hours_amount"]
    ),
    pay_s2_2: data["pay_s2_2"] ? formatValue(data["pay_s2_2"]) : "",
    pay_s2_34: data["pay_s2_34"] ? formatValue(data["pay_s2_34"]) : "",
    pay_s2_13: data["pay_s2_13"] ? formatValue(data["pay_s2_13"]) : "",
    pay_s2_5: data["pay_s2_5"] ? formatValue(data["pay_s2_5"]) : "",
    pay_s2_8: data["pay_s2_8"] ? formatValue(data["pay_s2_8"]) : "",
    pay_s2_26: data["pay_s2_26"] ? formatValue(data["pay_s2_26"]) : "",
    pay_tsp_r_1: data["pay_tsp_r_1"] ? formatValue(data["pay_tsp_r_1"]) : "",
    pay_retire_amount: data["pay_retire_amount"]
      ? formatValue(data["pay_retire_amount"])
      : "",
    cost_basic_life: formatValue(data["cost_basic_life"]),
    fegli_cost_option_a: formatValue(data["fegli_cost_option_a"]),
    fegli_cost_option_b: formatValue(data["fegli_cost_option_b"]),
    fegli_cost_option_c: formatValue(data["fegli_cost_option_c"]),
    pay_s2_28: formatValue(data["pay_s2_28"]),
    pay_s2_30: formatValue(data["pay_s2_30"]),
    pay_s2_32: formatValue(data["pay_s2_32"]),
    pay_s2_20: formatValue(data["pay_s2_20"]),
    pay_s2_22: formatValue(data["pay_s2_22"]),
    pay_s2_24: formatValue(data["pay_s2_24"]),
    fegli_line7: formatValue(data["fegli_line7"]),
    fegli_line8: formatValue(data["fegli_line8"]),
    fegli_line10: formatValue(data["fegli_line10"]),
    fegli_basic_and_option_a: formatValue(data["fegli_basic_and_option_a"]),
    fegli_basic_and_option_a_tomorrow: formatValue(
      data["fegli_basic_and_option_a_tomorrow"]
    ),
    fegli_s2_private_sector_coverage: formatValue(
      data["fegli_s2_private_sector_coverage"]
    ),
    fegli_s2_age_90_coverage: formatValue(data["fegli_s2_age_90_coverage"]),
    basic_life: formatValue(data["basic_life"]),
    option_a: formatValue(data["option_a"]),
    total_life_insurance: formatValue(data["total_life_insurance"]),
    optionCSpouce: formatValue(data["optionCSpouce"]),
    optionCChildren: formatValue(data["optionCChildren"]),
    age60OptionB: formatValue(data["age60OptionB"]),
    age65OptionB: formatValue(data["age65OptionB"]),
    age70OptionB: formatValue(data["age70OptionB"]),
    age80OptionB: formatValue(data["age80OptionB"]),
    age_62_ss_benefit: formatValue(data["age_62_ss_benefit"]),
    age_62_ss_benefit_final: formatValue(data["age_62_ss_benefit_final"]),
    monthly_gross_income: formatValue(data["monthly_gross_income"]),
    high3Avg: formatValue(data["pay_s2_1"]),
    fersBasicAnnuity: formatValue(data["fersBasicAnnuity"]),
    fersBasicAnnuity1: formatValue(data["fersBasicAnnuity1"]),
    fersBasicAnnuity2: formatValue(data["fersBasicAnnuity2"]),
    fersBasicAnnuity25: formatValue(data["fersBasicAnnuity25"]),
    fersBasicAnnuity50: formatValue(data["fersBasicAnnuity50"]),
    fersBasicAnnuity90: formatValue(data["fersBasicAnnuity90"]),
    fersBasicAnnuity95: formatValue(data["fersBasicAnnuity95"]),
    expenses_field1: formatValue(data["expenses_field1"]),
    expenses_field11: formatValue(data["expenses_field11"]),
    expenses_field2: formatValue(data["expenses_field2"]),
    expenses_field22: formatValue(data["expenses_field22"]),
    expenses_field3: formatValue(data["expenses_field3"]),
    expenses_field33: formatValue(data["expenses_field33"]),
    expenses_field4: formatValue(data["expenses_field4"]),
    expenses_field44: formatValue(data["expenses_field44"]),
    expenses_field5: formatValue(data["expenses_field5"]),
    expenses_field55: formatValue(data["expenses_field55"]),
    expenses_field6: formatValue(data["expenses_field6"]),
    expenses_field66: formatValue(data["expenses_field66"]),
    expenses_field7: formatValue(data["expenses_field7"]),
    expenses_field77: formatValue(data["expenses_field77"]),
    ...payload,
  };
};

export const getPayload = (page, data) => {
  let payload = {
    bpgId: data["id"],
    profileid: data["profileid"],
  };

  switch (page) {
    case "pay":
      payload = {
        ...payload,
        firstname: data["firstname"],
        lastname: data["lastname"],
        spouse_firstname: data["spouse_firstname"],
        spouse_lastname: data["spouse_lastname"],
        spouse_dob:
          data["spouse_dob"] && moment(data["spouse_dob"]).format("YYYY-MM-DD"),

        age: data["age"],
        dob: data["dob"] && moment(data["dob"]).format("YYYY-MM-DD"),

        gender: data["gender"],
        family_status: data["family_status"],
        spouse_employment: data["spouse_employment"],
        do_you_have_children: data["do_you_have_children"],
        divorce_decree_include_retirement_benefits_ex_spouse:
          data["divorce_decree_include_retirement_benefits_ex_spouse"],
        lite_pay_career: data["lite_pay_career"],
        have_military_service: data["have_military_service"],
        s2s3_receiving_pension: data["s2s3_receiving_pension"],
        s2s3_receiving_disability_pension:
          data["s2s3_receiving_disability_pension"],
        deposit_made: data["deposit_made"],
        lite_pay_agency: data["lite_pay_agency"],
        no_of_children: data["no_of_children"],
        dependent_children: data["dependent_children"],
        lite_pay_start_year:
          data["lite_pay_start_year"] &&
          moment(data["lite_pay_start_year"]).format("YYYY-MM-DD"),
        military_service_years: data["military_service_years"],
        military_service_months: data["military_service_months"],
        state: data["state"],

        started_on: data["started_on"],
        special_provisions: data["special_provisions"] == "1" ? 1 : 0,
        ver_eligible: data["ver_eligible"] == "1" ? 1 : 0,
      };
      break;

    case "pay-s2":
      payload = {
        ...payload,
        pay_s2_25: data["pay_s2_25"],
        pay_s2_100: data["pay_s2_100"],
        pay_s2_tsp_r_percent:
          data["pay_s2_100"] == "%" ? data["pay_s2_tsp_r_percent"] : "",
        pay_s2_tsp_percent:
          data["pay_s2_25"] == "%" ? data["pay_s2_tsp_percent"] : "",
        pay_s2_36: convertValue(data["pay_s2_36"]),
        pay_s2_1: convertValue(data["pay_s2_1"]),
        pay_s2_2: convertValue(data["pay_s2_2"]),
        pay_s2_working_hours: convertValue(data["pay_s2_working_hours"]),
        pay_s2_working_hours_amount: convertValue(
          data["pay_s2_working_hours_amount"]
        ),
        pay_s2_overtime_hours: convertValue(data["pay_s2_overtime_hours"]),
        pay_s2_overtime_hours_amount: convertValue(
          data["pay_s2_overtime_hours_amount"]
        ),
        pay_s2_18: convertValue(data["pay_s2_18"]),
        pay_s2_29: convertValue(data["pay_s2_29"]),
        pay_s2_34: convertValue(data["pay_s2_34"]),
        pay_s2_13: convertValue(data["pay_s2_13"]),
        pay_s2_5: convertValue(data["pay_s2_5"]),
        pay_s2_8: convertValue(data["pay_s2_8"]),
        pay_s2_26: convertValue(data["pay_s2_26"]),
        pay_tsp_r_1: convertValue(data["pay_tsp_r_1"]),
        pay_retire: data["pay_retire"] ? data["pay_retire"] : "",
        pay_retire_amount: convertValue(data["pay_retire_amount"]),
        pay_s2_15: data["pay_s2_15"] ? data["pay_s2_15"] : "",
        cost_basic_life: convertValue(data["cost_basic_life"]),
        fegli_cost_option_a: convertValue(data["fegli_cost_option_a"]),
        fegli_line9: convertValue(data["fegli_line9"]),
        fegli_cost_option_b: convertValue(data["fegli_cost_option_b"]),
        selectOptionCMultiple: convertValue(data["selectOptionCMultiple"]),
        fegli_cost_option_c: convertValue(data["fegli_cost_option_c"]),
        pay_s2_28: convertValue(data["pay_s2_28"]),
        pay_s2_30: convertValue(data["pay_s2_30"]),
        pay_s2_32: convertValue(data["pay_s2_32"]),
        pay_s2_20: convertValue(data["pay_s2_20"]),
        pay_s2_22: convertValue(data["pay_s2_22"]),
        pay_s2_24: convertValue(data["pay_s2_24"]),
        checkboxBasicLife: data["checkboxBasicLife"],
        checkboxOptionA: data["checkboxOptionA"],
        high3Avg: data["pay_s2_1"] ? convertValue(data["pay_s2_1"]) : "",
      };
      break;

    case "fegli":
      payload = {
        ...payload,
        fegli_line1: convertValue(data["fegli_line1"]),
        fegli_line2: convertValue(data["fegli_line2"]),
        fegli_line3: convertValue(data["fegli_line3"]),
        fegli_line4: convertValue(data["fegli_line4"]),
        fegli_line5: convertValue(data["fegli_line5"]),
        fegli_line6: convertValue(data["fegli_line6"]),
        fegli_line7: convertValue(data["fegli_line7"]),
        fegli_line8: convertValue(data["fegli_line8"]),
        fegli_line9: convertValue(data["fegli_line9"]),
        fegli_line10: convertValue(data["fegli_line10"]),
        age60OptionB: convertValue(data["age60OptionB"]),
        age65OptionB: convertValue(data["age65OptionB"]),
        age70OptionB: convertValue(data["age70OptionB"]),
        age80OptionB: convertValue(data["age80OptionB"]),
        fegli_basic_and_option_a: convertValue(
          data["fegli_basic_and_option_a"]
        ),
        fegli_basic_and_option_a_tomorrow: convertValue(
          data["fegli_basic_and_option_a_tomorrow"]
        ),
        fegli_s2_private_sector_coverage: convertValue(
          data["fegli_s2_private_sector_coverage"]
        ),
        fegli_s2_age_90_coverage: convertValue(
          data["fegli_s2_age_90_coverage"]
        ),
        basic_life: convertValue(data["basic_life"]),
        option_a: convertValue(data["option_a"]),
        total_life_insurance: convertValue(data["total_life_insurance"]),
        optionCSpouce: convertValue(data["optionCSpouce"]),
        optionCChildren: convertValue(data["optionCChildren"]),
        retirement_date_unknown: data["retirement_date_unknown"] ? 1 : 0,
        s2s5_have_you_obtained: data["s2s5_have_you_obtained"],
        fegli_s2_notes: data["fegli_s2_notes"],
      };
      break;
    case "fers":
      payload = {
        ...payload,
        s2s5_age_to_start_taking_income:
          data["s2s5_age_to_start_taking_income"],
        desired_retirement_date: data["desired_retirement_date"]
          ? moment(data["desired_retirement_date"]).format("YYYY-MM-DD")
          : null,
        s2s5_have_you_obtained: data["s2s5_have_you_obtained"],
        valueToUse: data["valueToUse"] ? data["valueToUse"] : 1,
        retirement_date_unknown: data["retirement_date_unknown"] ? 1 : 0,
        high3Avg: convertValue(data["high3Avg"]),
        yearsOfService: convertValue(data["yearsOfService"]),
        yearsOfService1: convertValue(data["yearsOfService1"]),
        yearsOfService2: convertValue(data["yearsOfService2"]),
        fersBasicAnnuity: convertValue(data["fersBasicAnnuity"]),
        fersBasicAnnuity1: convertValue(data["fersBasicAnnuity1"]),
        fersBasicAnnuity2: convertValue(data["fersBasicAnnuity2"]),
        fersBasicAnnuity25: convertValue(data["fersBasicAnnuity25"]),
        fersBasicAnnuity95: convertValue(data["fersBasicAnnuity95"]),
        fersBasicAnnuity50: convertValue(data["fersBasicAnnuity50"]),
        fersBasicAnnuity90: convertValue(data["fersBasicAnnuity90"]),
        realMRA: data["realMRA"],
        realYos: data["realYos"],
        mraAge: data["mraAge"],
      };
      break;
    case "fers-s2":
      payload = {
        ...payload,
        age_62_ss_benefit: convertValue(data.age_62_ss_benefit),
        yearsOfServiceat62: convertValue(data.yearsOfServiceat62),
        age_62_ss_benefit_final: convertValue(data.age_62_ss_benefit_final),
        monthly_gross_income: convertValue(data.monthly_gross_income),
        reduced_monthly_income: convertValue(data.reduced_monthly_income),
        loss_in_monthly_income: convertValue(data.loss_in_monthly_income),
        surviving_spouse_monthly_income:
          data.surviving_spouse_monthly_income &&
          !isNaN(parseFloat(data.surviving_spouse_monthly_income))
            ? convertValue(data.surviving_spouse_monthly_income)
            : "",
      };
      break;

    case "tsp":
      payload = {
        ...payload,
        lite_last_time_looked_at_tsp_account:
          data["lite_last_time_looked_at_tsp_account"],
        tsp_s2_balance: convertValue(data["tsp_s2_balance"]),
      };
      break;

    case "ss":
      payload = {
        ...payload,
        s8s2_currently_receiving_social_security:
          data["s8s2_currently_receiving_social_security"],
        s8_will_receive_social_sec_payment:
          data["s8_will_receive_social_sec_payment"],
        s8s2_income_amount: convertValue(data["s8s2_income_amount"]),
        s8s2_age_to_begin: convertValue(data["s8s2_age_to_begin"]),
        s8s2_ss_amount_verified: data["s8s2_ss_amount_verified"],
      };
      break;
    case "es":
      payload = {
        ...payload,
        bpg_ret_f1: data["bpg_ret_f1"],
        bpg_ret_f2: data["bpg_ret_f2"],
        bpg_ret_f4: data["bpg_ret_f4"],
        bpg_ret_f5: data["bpg_ret_f5"],
      };
      break;
    case "gap":
      break;
    case "benefits":
      break;
    case "client-survey":
      payload = {
        ...payload,
        howKnowledgeable: data["howKnowledgeable"],
        howWelAnswered: data["howWelAnswered"],
        benefitedEarlier: data["benefitedEarlier"],
        recommended: data["recommended"],
        refFirstName: data["refFirstName"],
        refLastName: data["refLastName"],
        refPhone: data["refPhone"],
        refEmail: data["refEmail"],
        refInputFields: data["refInputFields"],
      };
      console.log("payload ", payload);
      break;
    case "protection":
      break;
    case "retirement":
      break;
    case "summary":
      payload = { ...payload, agentNotes: data["agentNotes"] };
      break;

    case "retirement-s2":
      payload = {
        ...payload,
        retirement_s2: data["retirement_s2"] ? 1 : 0,
      };
      break;
    case "more-about-you":
      payload = {
        ...payload,
        s1q1: data["s1q1"],
        q1: data["s1q1"],
        screen1OtherValue: data["screen1OtherValue"],
      };
      break;
    case "more-about-you-s2":
      payload = {
        ...payload,
        agency: data["agency"],
        seniority_date:
          data["seniority_date"] &&
          moment(data["seniority_date"]).format("YYYY-MM-DD"),
        estYearService: data["estYearService"],
        seniorityUnknown: data["seniorityUnknown"] ? 1 : 0,
        ptf_time: data["ptf_time"],
        s2s3_receiving_pension: data["s2s3_receiving_pension"],
        deposit_made: data["deposit_made"],
        pre1989_part_time_service: data["pre1989_part_time_service"],
        have_military_service: data["have_military_service"],
        s2s2_proration_factor: data["s2s2_proration_factor"],
        military_service_years: data["military_service_years"],
        military_service_months: data["military_service_months"],
        pre1989_part_time_service_years:
          data["pre1989_part_time_service_years"],
        pre1989_part_time_service_months:
          data["pre1989_part_time_service_months"],
      };
      break;
    case "protection":
      payload = { ...payload };
      break;

    case "fers-annuity":
      payload = {
        ...payload,
        s2s5_age_to_start_taking_income:
          data["s2s5_age_to_start_taking_income"],
        s2s5_have_you_obtained: data["s2s5_have_you_obtained"],
        retirement_date_unknown: data["retirement_date_unknown"] ? 1 : 0,
        desired_retirement_date: data["desired_retirement_date"]
          ? moment(data["desired_retirement_date"]).format("YYYY-MM-DD")
          : null,
      };
      break;
    case "tsp-ret":
      payload = {
        ...payload,
        screen12_balance_of_traditional_tsp: convertValue(
          data["screen12_balance_of_traditional_tsp"]
        ),
        screen12_balance_of_roth_tsp: convertValue(
          data["screen12_balance_of_roth_tsp"]
        ),
        s12s2_tsp_bal_verified_by: data["s12s2_tsp_bal_verified_by"],
        screen12_fund_type: data["screen12_fund_type"],
        screen12_fund_type_percentage: data["screen12_fund_type_percentage"],
        outstanding_tsp_loan: data["outstanding_tsp_loan"],
        outstanding_tsp_loan_amount: convertValue(
          data["outstanding_tsp_loan_amount"]
        ),
        traditional_fixed_purpose: data["traditional_fixed_purpose"],
        traditional_fixed_purpose_remaining_years_to_payoff:
          data["traditional_fixed_purpose_remaining_years_to_payoff"],
        s13_q1: data["s13_q1"],
        s13_purchase_description: data["s13_purchase_description"],
        s13_total_amount_needed: convertValue(data["s13_total_amount_needed"]),
        tsp_ret_field3: data["tsp_ret_field3"],
        tsp_ret_field4: data["tsp_ret_field4"],
        tsp_ret_field5: data["tsp_ret_field5"],
        tsp_ret_field6: data["tsp_ret_field6"],
        spouse_risk_tol_level: data["spouse_risk_tol_level"],
      };
      break;
    case "pensions-and-income":
      payload = {
        ...payload,
        have_other_pension: data["have_other_pension"],
        spouse_employment_status: data["spouse_employment_status"],
        have_other_pension_name: data["have_other_pension_name"],
        have_other_pension_monthly_amount: convertValue(
          data["have_other_pension_monthly_amount"]
        ),
        s10_monthly_military_pension: convertValue(
          data["s10_monthly_military_pension"]
        ),
        s10_monthly_disability_amount: convertValue(
          data["s10_monthly_disability_amount"]
        ),
        s10_inv_current_bal: convertValue(data["s10_inv_current_bal"]),

        additional_sources_of_income_at_retire:
          data["additional_sources_of_income_at_retire"],
        s10_liquid_invest_acc_name: data["s10_liquid_invest_acc_name"],
        s10_liquid_inv_current_bal: convertValue(
          data["s10_liquid_inv_current_bal"]
        ),
        s10_liquid_inv_acc_type: data["s10_liquid_inv_acc_type"],
        s10_liquid_invest_acc_name_ext: data["s10_liquid_invest_acc_name_ext"],
        s10_liquid_inv_current_bal_ext: data["s10_liquid_inv_current_bal_ext"],
        s10_liquid_inv_acc_type_ext: data["s10_liquid_inv_acc_type_ext"],
        s10_liquid_inv_statement_available_ext:
          data["s10_liquid_inv_statement_available_ext"],
        s10_invest_acc_name_ext: data["s10_invest_acc_name_ext"],
        s10_inv_current_bal_ext: data["s10_inv_current_bal_ext"],
        s10_inv_acc_type_ext: data["s10_inv_acc_type_ext"],
        s10_inv_acc_type: data["s10_inv_acc_type"],
        s10_inv_statement_available_ext:
          data["s10_inv_statement_available_ext"],

        s10_invest_acc_name: data["s10_invest_acc_name"],
        s10_addIncome401k_name: data["s10_addIncome401k_name"],
        s10_addIncome401k_current_balance: convertValue(
          data["s10_addIncome401k_current_balance"]
        ),
        s10_addIncome401k_accType: data["s10_addIncome401k_accType"],
        real_estate_income_monthly_income: convertValue(
          data["real_estate_income_monthly_income"]
        ),
        real_estate_income_remaining_mortgage_amount: convertValue(
          data["real_estate_income_remaining_mortgage_amount"]
        ),
        real_estate_income_remaining_mortgage:
          data["real_estate_income_remaining_mortgage"] == "yes" ? "yes" : "no",
        additional_sources_of_income_at_retire_other_income_amount:
          convertValue(
            data["additional_sources_of_income_at_retire_other_income_amount"]
          ),
        additional_sources_of_income_at_retire_other_income_name:
          data["additional_sources_of_income_at_retire_other_income_name"],
        s2s6_spouse_employed_annual_income: convertValue(
          data["s2s6_spouse_employed_annual_income"]
        ),
        s2s6_spouse_employed_desired_retirement_age:
          data["s2s6_spouse_employed_desired_retirement_age"],
        s2s6_spouse_employed_est_monthly_pension: convertValue(
          data["s2s6_spouse_employed_est_monthly_pension"]
        ),
        s2s6_spouse_employed_age_to_start_social_security:
          data["s2s6_spouse_employed_age_to_start_social_security"],
        s2s6_spouse_employed_est_mon_soc_sec_inc: convertValue(
          data["s2s6_spouse_employed_est_mon_soc_sec_inc"]
        ),
        s2s6_spouse_ret_disability_monthly_payment: convertValue(
          data["s2s6_spouse_ret_disability_monthly_payment"]
        ),
        s2s6_ret_disability_receiving_soc_sec_payments:
          data["s2s6_ret_disability_receiving_soc_sec_payments"] == "yes"
            ? "yes"
            : "no",
        have_other_pension_being_taken:
          data["have_other_pension_being_taken"] == "yes" ? "yes" : "no",
        s2s6_spouse_ret_dis_soc_sec_monthl_income: convertValue(
          data["s2s6_spouse_ret_dis_soc_sec_monthl_income"]
        ),
        s2s6_spouse_ret_dis_age_to_start_soc_sec:
          data["s2s6_spouse_ret_dis_age_to_start_soc_sec"],
        s2s6_spouse_ret_dis_est_monthly_soc_sec_income: convertValue(
          data["s2s6_spouse_ret_dis_est_monthly_soc_sec_income"]
        ),
      };
      break;
    case "expenses":
      payload = {
        ...payload,
        expenses_field1: convertValue(data["expenses_field1"]),
        expenses_field11: convertValue(data["expenses_field11"]),
        expenses_field2: convertValue(data["expenses_field2"]),
        expenses_field22: convertValue(data["expenses_field22"]),
        expenses_field3: convertValue(data["expenses_field3"]),
        expenses_field33: convertValue(data["expenses_field33"]),
        expenses_field4: convertValue(data["expenses_field4"]),
        expenses_field44: convertValue(data["expenses_field44"]),
        expenses_field5: convertValue(data["expenses_field5"]),
        expenses_field55: convertValue(data["expenses_field55"]),
        expenses_field6: convertValue(data["expenses_field6"]),
        expenses_field66: convertValue(data["expenses_field66"]),
        expenses_field7: convertValue(data["expenses_field7"]),
        expenses_field77: convertValue(data["expenses_field77"]),
        expenses_field_monthly_total: convertValue(
          data["expenses_field_monthly_total"]
        ),
        expenses_field_monthly_total22: convertValue(
          data["expenses_field_monthly_total22"]
        ),
      };
      break;
    case "es-ret":
      payload = {
        ...payload,
        comfortable_with_your_current_liquid:
          data["comfortable_with_your_current_liquid"] == "yes" ? "yes" : "no",
        s14_account_type: data["s14_account_type"],
        s14_institution_name: data["s14_institution_name"],
        s14_balance: data["s14_balance"],
      };
      break;

    case "tsp-options":
      let annunity = {
        tsp_opt_full_or_partial_wid: "0",
        tsp_opt_tsp_monthly_pay: "0",
        tsp_opt_single_life_annuity_tsp: "0",
        tsp_opt_single_life_annuity_private_sector: "0",
        tsp_opt_joint_life_annuity_tsp: "0",
        tsp_opt_joint_life_annuity_private_sector: "0",
      };

      const option = data["lifeAnnuity"];
      annunity[option] = "1";
      payload = {
        ...payload,
        tsp_opt_amount: convertValue(data["tsp_opt_amount"]),
        ...annunity,
      };
      break;
    case "email-report":
      payload = {
        ...payload,
        finalCheckToSendPdf: data["finalCheckToSendPdf"],
        toEmail: data["toEmail"],
        attach_budget_worksheet:
          data["attach_budget_worksheet"] == "1" ? "1" : "0",
        attach_beneficiary_changes_forms:
          data["attach_beneficiary_changes_forms"] == "1" ? "1" : "0",
        attach_summary_report: data["attach_summary_report"] == "1" ? "1" : "0",
        attach_follow_fb_sms: data["attach_follow_fb_sms"] == "1" ? "1" : "0",
      };
      break;

    case "conclusion":
      payload = {
        ...payload,
        conclusion_ret_inc_pro_other: data["conclusion_ret_inc_pro_other"],
        fegli_plan_to_handle_this_issue_fegli:
          data["fegli_plan_to_handle_this_issue_fegli"],
        fegli_plan_to_handle_this_issue_tsp:
          data["fegli_plan_to_handle_this_issue_tsp"],
        fegli_plan_to_handle_this_issue_ret_income:
          data["fegli_plan_to_handle_this_issue_ret_income"],
        agentNotes: data["agentNotes"],
      };
      break;
    case "client-survey":
      payload = {
        ...payload,
        howKnowledgeable: data["howKnowledgeable"],
        howWelAnswered: data["howWelAnswered"],
        benefitedEarlier: data["benefitedEarlier"],
        recommended: data["recommended"],
      };
      break;

    default:
      payload = {};
      break;
  }
  return payload;
};

export const get_c4_code = (c4) => {
  let code = "";
  switch (c4) {
    case "A":
      code = "Ineligible";
      break;
    case "B":
      code = "No Benefits";
      break;
    case "C":
      code = "BL";
      break;
    case "D":
      code = "BL,A";
      break;
    case "E":
      code = "BL,C";
      break;
    case "F":
      code = "BL,A,C";
      break;
    case "G":
      code = "BL,B-1";
      break;
    case "H":
      code = "BL,A,B-1";
      break;
    case "I":
      code = "BL,B-1,C";
      break;
    case "J":
      code = "BL,A,B-1,C";
      break;
    case "K":
      code = "BL,B-2";
      break;
    case "L":
      code = "BL,A,B-2";
      break;
    case "M":
      code = "BL,B-2,C";
      break;
    case "N":
      code = "BL,A,B-2,C";
      break;
    case "O":
      code = "BL,B-3";
      break;
    case "P":
      code = "BL,A,B-3";
      break;
    case "Q":
      code = "BL,B-3,C";
      break;
    case "R":
      code = "BL,A,B-3,C";
      break;
    case "S":
      code = "BL,B-4";
      break;
    case "T":
      code = "BL,A,B-4";
      break;
    case "U":
      code = "BL,B-4,C";
      break;
    case "V":
      code = "BL,A,B-4,C";
      break;
    case "W":
      code = "BL,B-5";
      break;
    case "X":
      code = "BL,A,B-5";
      break;
    case "Y":
      code = "BL,B-5,C";
      break;
    case "Z":
      code = "BL,A,B-5,C";
      break;
    default:
      code = "";
      break;
  }
  return code;
};

export const get_cost_of_option_a = (age) => {
  let optionA = 0;
  if (age < 35) {
    optionA = 0.2;
  } else if (age >= 35 && age < 40) {
    optionA = 0.2;
  } else if (age >= 40 && age < 45) {
    optionA = 0.3;
  } else if (age >= 45 && age < 50) {
    optionA = 0.6;
  } else if (age >= 50 && age < 55) {
    optionA = 1.0;
  } else if (age >= 55 && age < 60) {
    optionA = 1.8;
  } else if (age >= 60) {
    optionA = 6.0;
  }

  return optionA;
};

export const get_cost_of_option_b = (age, line10) => {
  let optionB = line10;
  optionB /= 1000;
  if (age < 35) {
    optionB *= 0.043;
  } else if (age >= 35 && age < 40) {
    optionB *= 0.043;
  } else if (age >= 40 && age < 45) {
    optionB *= 0.065;
  } else if (age >= 45 && age < 50) {
    optionB *= 0.13;
  } else if (age >= 50 && age < 55) {
    optionB *= 0.217;
  } else if (age >= 55 && age < 60) {
    optionB *= 0.39;
  } else if (age >= 60 && age < 65) {
    optionB *= 0.867;
  } else if (age >= 65 && age < 70) {
    optionB *= 1.04;
  } else if (age >= 70 && age < 75) {
    optionB *= 1.863;
  } else if (age >= 75 && age < 80) {
    optionB *= 3.9;
  } else {
    optionB *= 6.24;
  }
  return optionB;
};

export const get_cost_of_option_c = (age, optionCMultiple) => {
  let c = 1;
  if (age < 35) {
    c *= 0.43;
  } else if (age >= 35 && age < 40) {
    c *= 0.52;
  } else if (age >= 40 && age < 45) {
    c *= 0.8;
  } else if (age >= 45 && age < 50) {
    c *= 1.15;
  } else if (age >= 50 && age < 55) {
    c *= 1.8;
  } else if (age >= 55 && age < 60) {
    c *= 2.88;
  } else if (age >= 60 && age < 65) {
    c *= 5.27;
  } else if (age >= 65 && age < 70) {
    c *= 6.13;
  } else if (age >= 70 && age < 75) {
    c *= 8.3;
  } else if (age >= 75 && age < 80) {
    c *= 12.48;
  } else {
    c *= 16.9;
  }
  const optionCCost = optionCMultiple * c;
  return optionCCost;
};

export const getMraAge = (dobDate) => {
  const bornYear = dobDate.year();

  let mraAge = 0;

  if (bornYear < 1948) {
    mraAge = 662;
  }
  if (bornYear == 1949) {
    mraAge = 664;
  }
  if (bornYear == 1950) {
    mraAge = 666;
  }
  if (bornYear == 1951) {
    mraAge = 668;
  }
  if (bornYear >= 1953 && bornYear <= 1964) {
    mraAge = 672;
  }
  if (bornYear == 1965) {
    mraAge = 674;
  }
  if (bornYear == 1966) {
    mraAge = 676;
  }
  if (bornYear == 1967) {
    mraAge = 678;
  }
  if (bornYear == 1968) {
    mraAge = 680;
  }
  if (bornYear == 1969) {
    mraAge = 682;
  }
  if (bornYear >= 1970) {
    mraAge = 684;
  }

  return mraAge / 12;
};
