import React, { useEffect, useState } from "react";
import { Card, Form, Image, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { usePlannerContext } from "../../contexts/planner.contexts";
import { openInNewTab } from "../../services/helperService";
import userService from "../../services/UserService";

const Container = ({ children }) => {
  const [checkOffline, setCheckOffline] = useState(false);
  const { plannerData, Tab, loader } = usePlannerContext();
  const navigate = useNavigate();

  const handleOnlineIcon = () => {
    setCheckOffline(false);
  };

  const handleOfflineIcon = () => {
    setCheckOffline(true);
  };

  useEffect(() => {
    // ID: 32
    window.addEventListener("online", handleOnlineIcon);
    window.addEventListener("offline", handleOfflineIcon);

    // ID: 33
    return () => {
      window.removeEventListener("online", handleOnlineIcon);
      window.removeEventListener("offline", handleOfflineIcon);
    };
  }, []);

  const handleNavigate = () => {
    let url = window.location.pathname;
    const idPattern = /\/(\d+)(\/|$)/;
    const match = url.match(idPattern);

    let bpgId = match && match[1];

    if (bpgId) {
      navigate(`/bpg/${bpgId}/welcome`);
    }
  };

  return (
    <>
      <header className="bpg-header">
        <div>
          <nav
            id="mainNav"
            className="container-fluid navbar navbar navbar-expand navbar-dark bg-faded"
          >
            <div className="container-fluid">
              <div onClick={() => handleNavigate()} className="navbar-brand">
                <img src="/images/logo4.svg" alt="" />
                {loader && (
                  <Spinner
                    animation="grow"
                    role="status"
                    variant="light"
                    size="sm"
                  />
                )}{" "}
                {checkOffline && (
                  <span
                    className="overview-link"
                    style={{ fontSize: "18px", fontWeight: "bolder" }}
                  >
                    - Offline Mode
                  </span>
                )}
              </div>
              <button
                aria-label="Toggle navigation"
                type="button"
                className="navbar-toggler"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <span className="navbar-text mobile-menu">
                <div>
                  <span className="c-pointer">
                    <Link
                      to="benefits-overview"
                      className="overview-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Benefits Overview
                    </Link>
                  </span>{" "}
                  -{" "}
                  {loader ? (
                    <Spinner
                      animation="grow"
                      role="status"
                      variant="light"
                      size="sm"
                    />
                  ) : (
                    <span className="me-2">
                      {(plannerData && plannerData.firstname
                        ? plannerData.firstname
                        : "") +
                        " " +
                        (plannerData && plannerData.lastname
                          ? plannerData.lastname
                          : "")}
                    </span>
                  )}{" "}
                  |{" "}
                  <span
                    className="ms-2 c-pointer"
                    onClick={() => openInNewTab(Tab)}
                  >
                    Benefits Planner
                  </span>
                </div>
              </span>
            </div>
          </nav>
        </div>
      </header>
      <div className="benefit-container">{children}</div>
      <div id="footer">
        <p
          className="copy"
          style={{
            color: "#888",
            fontSize: "14px",
            margin: 0,
            lineHeight: "1.6em",
            padding: "7px",
            textAlign: "center",
          }}
        >
          © Copyright MyAgentCRM. All rights reserved.{" "}
          <a
            style={{ color: "#337ab7", TextDecoder: "none" }}
            href="https://myagentcrm.com/privacy/"
            itemprop="item"
          >
            <span itemprop="name">Privacy Policy</span>
          </a>{" "}
          |{" "}
          <a
            style={{ color: "#337ab7", TextDecoder: "none" }}
            href="https://myagentcrm.com/terms/"
            itemprop="item"
          >
            <span itemprop="name">Terms of Use</span>
          </a>
        </p>
      </div>
    </>
  );
};

export default Container;
