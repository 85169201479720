import moment from "moment";
import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import userService from "../../../services/UserService";

const AboutForm2 = ({ submit, validated, handleKeyPress }) => {
  const {
    plannerData,
    handleDateChange,
    handleRadioButton,
    onChangeHandler,
    handleOnchangeCheckbox,
    handleBlur,
  } = usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);

  // ID: 28

  const disableEnableBtn = () => {
    const {
      agency,
      seniorityUnknown,
      estYearService,
      seniority_date,
      ptf_time,
      s2s2_proration_factor,
    } = formData;

    return (
      (!agency ||
        (seniorityUnknown && (!estYearService || estYearService === "0")) ||
        (!seniorityUnknown && !seniority_date) ||
        (ptf_time === "yes" && !s2s2_proration_factor)) &&
      userService.getAccess().bpg_skip_req != 1
    );
  };
  const submitFormdata = (event) => {
    submit(event, formData);
  };
  return (
    <>
      <div className="qs-box-more-continue single-screen">
        <h1 className="question">A LITTLE MORE ABOUT YOU</h1>
        <Form
          id="form_more_about_you_s2"
          name="aboutForm"
          className="subform"
          noValidate
          validated={validated}
          onSubmit={submitFormdata}
        >
          <div className="answers">
            <div className="sub-section">
              <h1 className="question">Agency, SCD &amp; PTF</h1>
              <div className="single-field">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Agency"
                  className="mb-3 floatingInput "
                >
                  <Form.Control
                    autoComplete="off"
                    className="about-inputs remove-focus required-input"
                    name="agency"
                    value={formData.agency}
                    type="text"
                    placeholder="Agency"
                    onChange={(event) => onChangeHandler(event, setformData)}
                  />
                </FloatingLabel>
              </div>

              <div className="single-field ">
                <div className="about-input-wrapper haveHint">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="What is your service computation date (SCD)?"
                      value={
                        formData["seniority_date"]
                          ? formData["seniority_date"]
                          : null
                      }
                      maxDate={moment()}
                      disabled={formData.seniorityUnknown == "1"}
                      className={`required-input normal-picker w-100 ml-icon floatingInput ${
                        validated && !formData.seniority_date
                          ? "is-invalid"
                          : ""
                      } `}
                      onChange={(date) =>
                        handleDateChange(date, "seniority_date", setformData)
                      }
                      slotProps={{
                        textField: {
                          required: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <a
                    href="javascript:void(0)"
                    className="qs-hint1 hint hint-disable"
                  >
                    <OverlayTrigger
                      rootClose
                      trigger="click"
                      key={"left"}
                      placement={"left"}
                      overlay={
                        <Popover id={`popover-positioned-left`}>
                          <Popover.Body>
                            SCD is typically the date you became a career
                            employee. It can also be adjusted by buying back
                            military time or making service credit redeposits.
                            It may be referred to as your seniority date.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <i className="fa-solid fa-circle-info info-popover"></i>
                    </OverlayTrigger>
                  </a>
                </div>
              </div>
              <div className="single-field">
                <label className="about-continue-check-text">
                  <input
                    type="checkbox"
                    name="seniorityUnknown"
                    checked={formData.seniorityUnknown == "1" ? true : false}
                    onChange={(event) => {
                      handleOnchangeCheckbox(
                        event,
                        event.target.checked ? true : null,
                        setformData
                      );
                    }}
                  />{" "}
                  Check if the service computation date (SCD) is unknown.
                </label>
              </div>

              {formData.seniorityUnknown == "1" && (
                <div className="single-field">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Estimated creditable years of service:"
                    className="mb-3   thrift-label"
                  >
                    <Form.Control
                      autoComplete="off"
                      className="about-inputs remove-focus required-input"
                      type="number"
                      placeholder="Estimated creditable years of service:"
                      name="estYearService"
                      value={formData.estYearService}
                      onBlur={(e) => handleBlur(e, false, setformData)}
                      onChange={(event) => onChangeHandler(event, setformData)}
                      isInvalid={validated && !formData.estYearService}
                      required
                    />
                  </FloatingLabel>
                </div>
              )}
              <div className="single-field">
                <div className="d-flex align-items-center">
                  <div className="mx-2 about-continue-check-text">
                    PTF time:
                    <OverlayTrigger
                      rootClose
                      trigger="click"
                      key={"top"}
                      placement={"top"}
                      overlay={
                        <Popover id={`popover-positioned-top`}>
                          <Popover.Body>
                            PTF time may result in a proration of your high
                            three average salary. Typically, employees with PTF
                            time will need to request a “hand calculated”
                            annuity estimate from your agency. Regular Computer
                            Generated Annuity Estimates are typically not
                            available and, if available, may be inaccurate.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <i className="fa-solid fa-circle-info info-popover"></i>
                    </OverlayTrigger>
                  </div>
                  <div className="mx-2 d-flex">
                    <div className="mx-2">
                      <input
                        id="ptf_timeYes"
                        type="radio"
                        name="ptf_time"
                        checked={formData.ptf_time == "yes" ? true : false}
                        onChange={(event) => {
                          handleOnchangeCheckbox(event, "yes", setformData);
                        }}
                      />{" "}
                      <label
                        htmlFor="ptf_timeYes"
                        className="about-continue-check-text"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="mx-2">
                      <input
                        id="ptf_timeNo"
                        type="radio"
                        name="ptf_time"
                        checked={formData.ptf_time == "no" ? true : false}
                        onChange={(event) => {
                          handleOnchangeCheckbox(event, "no", setformData);
                        }}
                      />{" "}
                      <label
                        htmlFor="ptf_timeNo"
                        className="about-continue-check-text"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {formData.ptf_time == "yes" && (
                  <>
                    <div>
                      <p className="about-continue-check-text mt-3 mb-2">
                        Proration Factor:
                      </p>
                    </div>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter percentage"
                      className="my-2 floatingInput"
                    >
                      <Form.Control
                        autoComplete="off"
                        className="about-inputs remove-focus"
                        type="number"
                        name="s2s2_proration_factor"
                        placeholder="Enter percentage"
                        value={formData.s2s2_proration_factor}
                        onChange={(event) =>
                          onChangeHandler(event, setformData)
                        }
                        onBlur={(e) => handleBlur(e, false, setformData)}
                        isInvalid={validated && !formData.s2s2_proration_factor}
                        required
                      />
                    </FloatingLabel>
                  </>
                )}
              </div>
            </div>
            <div className="sub-section">
              <h1 className="question">Military &amp; Part-Time Service</h1>

              <div className="d-flex align-items-center">
                <div className="mb-2 mx-4">
                  <div className="mx-2 text-about">
                    Do you have Military service?
                  </div>
                </div>
                <div className="mx-2 d-flex">
                  <div className="mx-2">
                    <input
                      id="aboutMilitaryServiceYes"
                      type="radio"
                      name="have_military_service"
                      checked={formData.have_military_service == "yes"}
                      onChange={(event) => {
                        handleOnchangeCheckbox(event, "yes", setformData);
                      }}
                    />{" "}
                    <label
                      className="text-about"
                      htmlFor="aboutMilitaryServiceYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="mx-2">
                    <input
                      id="aboutMilitaryServiceNo"
                      type="radio"
                      name="have_military_service"
                      checked={formData.have_military_service == "no"}
                      onChange={(event) => {
                        handleOnchangeCheckbox(event, "no", setformData);
                      }}
                    />{" "}
                    <label
                      className="text-about"
                      htmlFor="aboutMilitaryServiceNo"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              {formData.have_military_service == "yes" && (
                <div>
                  <div className="">
                    <div className="single-field">
                      <div className="mx-2 my-2 text-about">
                        How many years and months?
                      </div>

                      <div className="d-flex ">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Years"
                          className="mb-3  floatingInput-milartry-service"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="about-inputs remove-focus"
                            type="number"
                            name="military_service_years"
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                            value={formData.military_service_years}
                            onBlur={(e) => handleBlur(e, false, setformData)}
                            placeholder="Years"
                            isInvalid={
                              validated && !formData.military_service_years
                            }
                            required
                          />
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Months"
                          className="mb-3  floatingInput-milartry-service"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="about-inputs remove-focus"
                            type="number"
                            name="military_service_months"
                            value={formData.military_service_months}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                            onBlur={(e) => handleBlur(e, false, setformData)}
                            placeholder="Months"
                            isInvalid={
                              validated && !formData.military_service_months
                            }
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="single-field">
                      <div className="mx-2 text-about">
                        Are you currently receiving a Military pension?
                      </div>
                    </div>
                    <div className="mx-2 d-flex">
                      <div className="mx-2">
                        <input
                          id="aboutMilitaryPensionYes"
                          type="radio"
                          name="s2s3_receiving_pension"
                          checked={formData.s2s3_receiving_pension == "yes"}
                          onChange={(event) => {
                            handleRadioButton(event, "yes", setformData);
                          }}
                        />{" "}
                        <label
                          className="text-about"
                          htmlFor="aboutMilitaryPensionYes"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="mx-2 text-about">
                        <input
                          id="aboutMilitaryPensionNo"
                          type="radio"
                          name="s2s3_receiving_pension"
                          checked={formData.s2s3_receiving_pension == "no"}
                          onChange={(event) => {
                            handleRadioButton(event, "no", setformData);
                          }}
                        />{" "}
                        <label
                          className="text-about"
                          htmlFor="aboutMilitaryPensionNo"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="single-field">
                      <div className="mx-2 text-about">
                        Are you also receiving military disability pension?
                      </div>
                    </div>
                    <div className="mx-2 d-flex">
                      <div className="mx-2">
                        <input
                          id="aboutMilitaryDisabilityPensionYes"
                          type="radio"
                          name="s2s3_receiving_disability_pension"
                          checked={
                            formData.s2s3_receiving_disability_pension == "yes"
                          }
                          onChange={(event) => {
                            handleRadioButton(event, "yes", setformData);
                          }}
                        />{" "}
                        <label
                          className="text-about"
                          htmlFor="aboutMilitaryDisabilityPensionYes"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="mx-2 text-about">
                        <input
                          id="aboutMilitaryDisabilityPensionNo"
                          type="radio"
                          name="s2s3_receiving_disability_pension"
                          checked={
                            formData.s2s3_receiving_disability_pension == "no"
                          }
                          onChange={(event) => {
                            handleRadioButton(event, "no", setformData);
                          }}
                        />{" "}
                        <label
                          className="text-about"
                          htmlFor="aboutMilitaryDisabilityPensionNo"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="single-field">
                      <div className="mx-2 text-about">
                        Deposit completed, or in process of completion?
                      </div>
                    </div>
                    <div className="mx-2 d-flex">
                      <div className="mx-2">
                        <input
                          id="aboutMilitaryDepositYes"
                          type="radio"
                          name="deposit_made"
                          checked={formData.deposit_made == "yes"}
                          onChange={(event) => {
                            handleRadioButton(event, "yes", setformData);
                          }}
                        />{" "}
                        <label
                          className="text-about"
                          htmlFor="aboutMilitaryDepositYes"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="mx-2">
                        <input
                          id="aboutMilitaryDepositNo"
                          type="radio"
                          name="deposit_made"
                          checked={formData.deposit_made == "no"}
                          onChange={(event) => {
                            handleRadioButton(event, "no", setformData);
                          }}
                        />{" "}
                        <label
                          className="text-about"
                          htmlFor="aboutMilitaryDepositNo"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center">
                <div className="mb-2 mx-4">
                  <div className="mx-2 text-about">
                    Do you have pre-1989 part time service?
                  </div>
                </div>
                <div className="mx-2 d-flex">
                  <div className="mx-2">
                    <input
                      id="prePartTimeYes"
                      type="radio"
                      name="pre1989_part_time_service"
                      checked={formData.pre1989_part_time_service == "yes"}
                      onChange={(event) => {
                        handleRadioButton(event, "yes", setformData);
                      }}
                    />{" "}
                    <label className="text-about" htmlFor="prePartTimeYes">
                      Yes
                    </label>
                  </div>
                  <div className="mx-2">
                    <input
                      id="prepartTimeNo"
                      type="radio"
                      name="pre1989_part_time_service"
                      checked={formData.pre1989_part_time_service == "no"}
                      onChange={(event) => {
                        handleRadioButton(event, "no", setformData);
                      }}
                    />{" "}
                    <label className="text-about" htmlFor="prepartTimeNo">
                      No
                    </label>
                  </div>
                </div>
              </div>
              {formData.pre1989_part_time_service == "yes" && (
                <div>
                  <div className="single-field">
                    <div className="mx-2 my-2 text-about">
                      How many years and months?
                    </div>
                    <div>
                      <div className="d-flex ">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Years"
                          className="mb-3  floatingInput-milartry-service"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="about-inputs remove-focus"
                            type="number"
                            name="pre1989_part_time_service_years"
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                            value={formData.pre1989_part_time_service_years}
                            onBlur={(e) => handleBlur(e, false, setformData)}
                            placeholder="Years"
                            isInvalid={
                              validated &&
                              !formData.pre1989_part_time_service_years
                            }
                            required
                          />
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Months"
                          className="mb-3  floatingInput-milartry-service"
                        >
                          <Form.Control
                            autoComplete="off"
                            className="about-inputs remove-focus"
                            type="number"
                            name="pre1989_part_time_service_months"
                            value={formData.pre1989_part_time_service_months}
                            onChange={(event) =>
                              onChangeHandler(event, setformData)
                            }
                            onBlur={(e) => handleBlur(e, false, setformData)}
                            placeholder="Months"
                            isInvalid={
                              validated &&
                              !formData.pre1989_part_time_service_months
                            }
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="single-field">
              <div className="personal-text-wrapper">
                <div className="personal-heading">
                  Personal Profile - ITEMS OF IMPORTANCE
                </div>
                <div className="personal-sub-heading">
                  *Verify Military Time is credited.
                  <br />
                  **If Married and/or Children. Verify beneficiary designations
                  are correct and survivorship options are discussed. Including
                  TSP Annuity.
                </div>
              </div>
            </div>
          </div>
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button
                className="btn-next btn question-next"
                type="submit"
                disabled={disableEnableBtn()}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AboutForm2;
