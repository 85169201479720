import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        registration.update();
        console.log("Service Worker registered:", registration);
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}

window.onbeforeunload = confirmExit;
function confirmExit() {
  const queuedRequests =
    JSON.parse(localStorage.getItem("queuedRequests")) || [];
  if (queuedRequests.length > 0)
    return "You have attempted to leave this page. Are you sure?";
}

root.render(<App />);

reportWebVitals();
