import React, { useEffect, useRef, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import HubspotContactForm from "./HubspotContactForm";

const ClientSurveyForm = ({ submit }) => {
  const initialFormState = {
    refFirstname: "",
    refLastName: "",
    refPhone: "",
    refEmail: "",
  };
  const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const { plannerData, handleOnchangeCheckbox, handleKeyPress } =
    usePlannerContext();
  const [inputFields, setInputFields] = useState([
    {
      refFirstName: "",
      refLastName: "",
      refPhone: "",
      refEmail: "",
    },
  ]);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setformData] = useState({});
  const [refInputFields, setRefInputFields] = useState([]);
  const [userData, setUserData] = useState({});

  const addInputField = () => {
    setInputFields([...inputFields, initialFormState]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleinputChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  const handleFormSub = () => {
    setFormSubmitted(true);
  };

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);

      const jsonString = plannerData.refInputFields;
      if (jsonString) {
        const unescapedString = jsonString.replace(/&quot;/g, '"'); // ID: 19
        const jsonData = JSON.parse(unescapedString); // ID: 20

        const fieldsData = jsonData
          ? jsonData.filter((d) => d.refEmail != "" && d)
          : [];

        setRefInputFields(fieldsData);
      }
    }

    setUserData({ ...JSON.parse(localStorage.getItem("access")) });
  }, [plannerData]);

  const submitFormdata = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let payload = {
      refEmail: [],
      refFirstName: [],
      refLastName: [],
      refPhone: [],
    };
    let emailValidated = true;

    if (inputFields && inputFields.length > 0) {
      inputFields.map(({ refEmail, refFirstName, refLastName, refPhone }) => {
        if (refEmail && refEmail.length > 0 && !emailReg.test(refEmail)) {
          emailValidated = false;
        }
        payload["refEmail"].push(refEmail);
        payload["refFirstName"].push(refFirstName);
        payload["refLastName"].push(refLastName);
        payload["refPhone"].push(refPhone);
      });

      payload["refInputFields"] = JSON.stringify([
        ...refInputFields,
        ...inputFields,
      ]);
    }

    if (emailValidated) {
      payload = { ...formData, ...payload };
      submit(event, payload);
    }
  };

  return (
    <>
      <Card.Body className="client-survey-section">
        <div className="qs-box-survey single-screen" data-screenid="">
          <h1 className="question-survey">CLIENT SURVEY</h1>
          <HubspotContactForm handleFormSub={handleFormSub} />
          <Form
            id="form_client_survey"
            className="subform"
            noValidate
            onSubmit={submitFormdata}
          >
            <div className="btn-wrapper">
              <div className="text-center mt-4">
                <button
                  className={
                    "btn-next btn question-next " +
                    (formSubmitted ? "" : "question-next-sep")
                  }
                  type="submit"
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Card.Body>
    </>
  );
};

export default ClientSurveyForm;
