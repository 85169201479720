import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Image, Row, Stack, Table } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import {
  convertValue,
  formatCalculatedValue,
  formatValue,
  integerValue,
  roundUpToNextThousand,
} from "../../../services/helperService";
import Images from "../../CRMs/crm2.0";
import FederalEmployeeCostModal from "../../modal/federalEmployee-cost";
import capitalList from "../../../constants/benefits/capital_legacy_reates.json";
import seniorList from "../../../constants/benefits/senior_choice_rates.json";
import "./overView.scss";

const OverView = ({ validated, submit }) => {
  const { plannerData } = usePlannerContext();
  const [isCapitalLegacy, setCapitalLegacy] = useState(true);
  const [legencyDetail, setLegencyDetail] = useState({});
  const [formData, setformData] = useState({});
  const [optionA, setOptionA] = useState("");
  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      let fersStatus = false;
      if (
        plannerData &&
        plannerData["available_screens"] &&
        plannerData["available_screens"].length > 0
      ) {
        let fers = plannerData["available_screens"].find(
          (screen) => screen.code == "fers"
        );
        let fersAnnunity = plannerData["available_screens"].find(
          (screen) => screen.code == "fers-annuity"
        );
        if (
          (fers && fers["completed"] == 1) ||
          (fersAnnunity && fersAnnunity["completed"] == 1)
        ) {
          fersStatus = true;
        }
      }

      const line8and2 = roundUpToNextThousand(plannerData.pay_s2_1);

      let roundedValue =
        convertValue(line8and2) + convertValue(plannerData["fegli_line3"]);
      let formatedValue = formatCalculatedValue(roundedValue);

      const factor = multifactor();
      let line7 = convertValue(formatedValue);

      line7 =
        convertValue(factor) &&
        convertValue(factor) * convertValue(formatedValue);

      const line2 = convertValue(line8and2);
      const factor2 = convertValue(plannerData["fegli_line9"]);
      const line10 = convertValue(factor2) && convertValue(factor2) * line2;

      const age60 = line10 ? (convertValue(line10) / 1000) * 0.867 : "0.00";

      const age65 = line10 ? (convertValue(line10) / 1000) * 1.04 : "0.00";

      const age70 = line10 ? (convertValue(line10) / 1000) * 1.863 : "0.00";

      const age80 = line10 ? (convertValue(line10) / 1000) * 6.24 : "0.00";

      const optionACheckbox =
        plannerData["checkboxOptionA"] == "1" ? true : false;

      const basicOptionA = optionACheckbox
        ? convertValue(formatedValue) + 10000
        : convertValue(formatedValue);

      const fegli_basic_and_option_a_tomorrow = basicOptionA * 0.25;
      let fegli_s2_age_90_coverage = fegli_basic_and_option_a_tomorrow / 2;

      const optionCSpouce =
        convertValue(plannerData["selectOptionCMultiple"]) * 5000;
      const optionCChildren =
        convertValue(plannerData["selectOptionCMultiple"]) * 2500;
      const option_a = optionACheckbox ? "10,000.00" : "0.00";
      const total_life_insurance =
        convertValue(formatedValue) +
        convertValue(line7) +
        convertValue(line10) +
        convertValue(option_a);
      fegli_s2_age_90_coverage =
        fegli_s2_age_90_coverage +
        convertValue(plannerData["fegli_s2_private_sector_coverage"]);
      // ID: 29
      let payload = {};
      if (plannerData && plannerData["retirement_date_unknown"] == 1) {
        payload = handleFersCheckbox(true, plannerData);
      } else {
        payload = handleFersCheckbox(false, plannerData);
      }

      const pay_s2_1 = convertValue(plannerData["pay_s2_1"]);
      const groseeIncome = pay_s2_1 / 12;
      const fersAnnuityArr = [
        plannerData["fersBasicAnnuity"],
        plannerData["fersBasicAnnuity95"],
        plannerData["fersBasicAnnuity90"],
      ];
      const valueToUse = plannerData["valueToUse"]
        ? plannerData["valueToUse"] - 1
        : 0;
      let fersBasicAnnuity = convertValue(fersAnnuityArr[valueToUse]);

      let loss_in_monthly_income = groseeIncome - fersBasicAnnuity;
      loss_in_monthly_income = loss_in_monthly_income
        ? Math.abs(loss_in_monthly_income)
        : "";
      let surviving_spouse_monthly_income = payload["fersBasicAnnuity50"];

      let survivorshipDeduction = 0;
      if (plannerData.family_status == "married") {
        survivorshipDeduction = (fersBasicAnnuity * 10) / 100;
      }
      let FEHB = plannerData["pay_s2_32"]
        ? convertValue(plannerData["pay_s2_32"])
        : 0;
      FEHB = (FEHB * 26) / 12;
      let incomeTaxDeduction = (fersBasicAnnuity * 15) / 100;
      fersBasicAnnuity = fersBasicAnnuity ? fersBasicAnnuity.toFixed(2) : 0;
      FEHB = FEHB ? FEHB.toFixed(2) : 0;
      incomeTaxDeduction = incomeTaxDeduction
        ? incomeTaxDeduction.toFixed(2)
        : 0;
      survivorshipDeduction = survivorshipDeduction
        ? survivorshipDeduction.toFixed(2)
        : 0;
      const netPay =
        fersBasicAnnuity - survivorshipDeduction - FEHB - incomeTaxDeduction;

      if (
        plannerData["family_status"] == "single" ||
        plannerData["family_status"] == "divorced"
      ) {
        surviving_spouse_monthly_income = "N/A - Single";
      }

      let startTakingIncome = "";
      if (payload["s2s5_age_to_start_taking_income"]) {
        startTakingIncome = payload["s2s5_age_to_start_taking_income"]
          .toString()
          .replaceAll("amp;", "");
      }

      setformData({
        ...plannerData,
        ...payload,
        fersStatus,
        selectOptionCMultiple: convertValue(
          plannerData["selectOptionCMultiple"]
        ),
        survivorshipDeduction: formatCalculatedValue(survivorshipDeduction),
        FEHB: formatCalculatedValue(FEHB),
        incomeTaxDeduction: formatCalculatedValue(incomeTaxDeduction),
        netPay: formatCalculatedValue(netPay),
        startTakingIncome,
        fegli_line4: formatedValue,
        fegli_line2: line8and2,
        fegli_line5: formatedValue,
        fegli_line6: factor,
        fegli_line7: formatValue(line7),
        fegli_line8: line8and2,
        fegli_line9: factor2,
        fegli_line10: formatValue(line10),
        age60OptionB: formatCalculatedValue(age60),
        age65OptionB: formatCalculatedValue(age65),
        age70OptionB: formatCalculatedValue(age70),
        age80OptionB: formatCalculatedValue(age80),
        fegli_basic_and_option_a: formatCalculatedValue(basicOptionA),
        fegli_basic_and_option_a_tomorrow: formatCalculatedValue(
          fegli_basic_and_option_a_tomorrow
        ),
        basic_life: formatedValue,
        option_a,
        total_life_insurance: formatCalculatedValue(total_life_insurance),
        optionCSpouce: formatCalculatedValue(optionCSpouce),
        optionCChildren: formatCalculatedValue(optionCChildren),
        fegli_s2_age_90_coverage: formatCalculatedValue(
          fegli_s2_age_90_coverage
        ),
        age_62_ss_benefit_final: payload["Srs"],

        monthly_gross_income: groseeIncome
          ? formatCalculatedValue(groseeIncome)
          : "0.00",
        reduced_monthly_income: fersBasicAnnuity
          ? formatCalculatedValue(fersBasicAnnuity)
          : "0.00",
        loss_in_monthly_income: loss_in_monthly_income
          ? formatCalculatedValue(loss_in_monthly_income)
          : "0.00",
        surviving_spouse_monthly_income,
      });
    }
  }, [plannerData]);

  const handleFersCheckbox = (isChecked, data = formData) => {
    let payload = {
      ...data,
      retirement_date_unknown: isChecked ? 1 : 0,
    };
    let servicePayload = {};

    const retiermentYears = data["retirementYears"]
      ? data["retirementYears"]
      : 0;
    const retirementMonths = data["retirementMonths"]
      ? data["retirementMonths"]
      : 0;
    let s2s5_age_to_start_taking_income = "";
    if (data["retirementMonths"] == "0") {
      s2s5_age_to_start_taking_income = retiermentYears;
    } else {
      s2s5_age_to_start_taking_income =
        retiermentYears + " & " + retirementMonths + " mos. ";
    }

    if (isChecked) {
      let newMRA = parseFloat(data["retirementYears"]);
      newMRA += data["retirementMonths"]
        ? parseFloat(data["retirementMonths"]) / 12
        : 0;

      payload = {
        ...payload,
        retiermentYears,

        MRA: newMRA,
        s2s5_age_to_start_taking_income,
        desired_retirement_date: data.retirementDate
          ? moment(data.retirementDate)
          : null,
        retirementMonths: data["retirementMonths"],
        retirementYears: data["retirementYears"],
      };
    }

    let service = getServices(payload);
    service = service && service > 0 ? convertValue(service) : 0;
    servicePayload = getServicePayload(service, payload);
    let diffMonth = moment(payload["desired_retirement_date"]).diff(
      moment(),
      "months"
    );

    if (diffMonth < 0) {
      diffMonth = 0;
    }

    let srsyears = service;

    if (
      plannerData.have_military_service == "yes" &&
      plannerData.s2s3_receiving_pension == "no"
    ) {
      let month = convertValue(plannerData.military_service_years) * 12;
      month = month + convertValue(plannerData.military_service_months);
      let year = month / 12;
      srsyears = srsyears - year;
    }
    const Srs =
      convertValue(plannerData["age_62_ss_benefit"]) * (srsyears / 40);

    return {
      ...payload,
      ...servicePayload,
      diffMonth: diffMonth ? diffMonth : 0,
      yearsOfService: service ? service.toFixed(2) : 0,
      yearsOfServiceat62: convertValue(srsyears).toFixed(2),
      Srs: formatCalculatedValue(Srs),
    };
  };

  const getServices = (payload) => {
    let militaryYears = 0;
    if (
      payload &&
      payload["have_military_service"] == "yes" &&
      payload["s2s3_receiving_pension"] == "no"
    ) {
      let serviceYears = moment.duration({
        years: parseInt(payload["military_service_years"]),
        months: parseInt(payload["military_service_months"]),
      });
      militaryYears = serviceYears.asYears();
    }

    let service = "";
    if (militaryYears) {
      let startDate = moment(payload["lite_pay_start_year"])
        .subtract(militaryYears, "years")
        .format("YYYY-MM-DD");
      if (payload["desired_retirement_date"]) {
        service = moment(payload["desired_retirement_date"]).diff(
          startDate,
          "years",
          true
        );
      }
    } else {
      if (payload["desired_retirement_date"]) {
        service = moment(payload["desired_retirement_date"]).diff(
          payload["lite_pay_start_year"],
          "years",
          true
        );
      }
    }
    return convertValue(service);
  };

  const getServicePayload = (service, data = formData) => {
    let payload = {};

    if (data["special_provisions"] == "1") {
      let yearsOfService1 = "";
      let yearsOfService2 = "";
      if (service > 20) {
        yearsOfService1 = 20;
        yearsOfService2 = service - 20;
      } else {
        yearsOfService1 = service;
        yearsOfService2 = 0;
      }

      let finalValue1 = "";
      let finalValue2 = "";
      if (yearsOfService1) {
        let multiplier = convertValue(data["high3Avg"]);

        finalValue1 = (yearsOfService1 * multiplier * 1.7) / 100 / 12;
      }

      if (yearsOfService2) {
        let multiplier = convertValue(data["high3Avg"]);

        finalValue2 = (yearsOfService2 * multiplier * 1) / 100 / 12;
      }

      let fersBasicAnnuity = finalValue1 + finalValue2;
      const fersBasicAnnuity25 = fersBasicAnnuity * 0.25;
      const fersBasicAnnuity95 = fersBasicAnnuity * 0.95;
      const fersBasicAnnuity50 = fersBasicAnnuity * 0.5;
      const fersBasicAnnuity90 = fersBasicAnnuity * 0.9;

      payload = {
        yearsOfService1: formatCalculatedValue(yearsOfService1),
        yearsOfService2: formatCalculatedValue(yearsOfService2),
        fersBasicAnnuity1: formatCalculatedValue(finalValue1),
        fersBasicAnnuity2: formatCalculatedValue(finalValue2),
        fersBasicAnnuity: formatCalculatedValue(fersBasicAnnuity),
        fersBasicAnnuity25: formatCalculatedValue(fersBasicAnnuity25),
        fersBasicAnnuity95: formatCalculatedValue(fersBasicAnnuity95),
        fersBasicAnnuity50: formatCalculatedValue(fersBasicAnnuity50),
        fersBasicAnnuity90: formatCalculatedValue(fersBasicAnnuity90),
      };
    } else if (data["ver_eligible"] == 1) {
      let finalValue1 = "";
      if (service) {
        let multiplier = convertValue(data["high3Avg"]);

        finalValue1 = (service * multiplier * 1) / 100 / 12;
      }

      let fersBasicAnnuity = finalValue1;
      const fersBasicAnnuity25 = fersBasicAnnuity * 0.25;
      const fersBasicAnnuity95 = fersBasicAnnuity * 0.95;
      const fersBasicAnnuity50 = fersBasicAnnuity * 0.5;
      const fersBasicAnnuity90 = fersBasicAnnuity * 0.9;

      payload = {
        yearsOfService1: formatCalculatedValue(service),
        yearsOfService2: formatCalculatedValue(service),
        fersBasicAnnuity1: formatCalculatedValue(fersBasicAnnuity),
        fersBasicAnnuity2: formatCalculatedValue(fersBasicAnnuity),
        fersBasicAnnuity: formatCalculatedValue(fersBasicAnnuity),
        fersBasicAnnuity25: formatCalculatedValue(fersBasicAnnuity25),
        fersBasicAnnuity95: formatCalculatedValue(fersBasicAnnuity95),
        fersBasicAnnuity50: formatCalculatedValue(fersBasicAnnuity50),
        fersBasicAnnuity90: formatCalculatedValue(fersBasicAnnuity90),
      };
    } else if (service >= 20 && data["MRA"] >= 62) {
      payload = handlefersCalculations(
        service,
        "yearsOfService",

        true,
        data
      );
    } else {
      payload = handlefersCalculations(
        service,
        "yearsOfService",

        true,
        data
      );
    }

    return payload;
  };
  const handlefersCalculations = (
    value,
    name,
    isJustcalculations = false,
    values = formData
  ) => {
    let precentage = 1;

    if (
      name == "yearsOfService" &&
      value >= 20 &&
      values["MRA"] >= 62 &&
      isJustcalculations
    ) {
      precentage = 1.1;
    }
    if (values["yearsOfService"] >= 20 && values["MRA"] >= 62) {
      precentage = 1.1;
    }
    if (name == "yearsOfService" || name == "high3Avg") {
      let data = convertValue(value);
      let multiplier =
        name == "yearsOfService"
          ? convertValue(values["high3Avg"])
          : convertValue(values["yearsOfService"]);

      const finalValue = (data * multiplier * precentage) / 100 / 12;
      const fersBasicAnnuity25 = finalValue * 0.25;
      const fersBasicAnnuity95 = finalValue * 0.95;
      const fersBasicAnnuity50 = finalValue * 0.5;
      const fersBasicAnnuity90 = finalValue * 0.9;
      let payload = {
        [name]: value,
        fersBasicAnnuity: formatCalculatedValue(finalValue),
        fersBasicAnnuity25: formatCalculatedValue(fersBasicAnnuity25),
        fersBasicAnnuity95: formatCalculatedValue(fersBasicAnnuity95),
        fersBasicAnnuity50: formatCalculatedValue(fersBasicAnnuity50),
        fersBasicAnnuity90: formatCalculatedValue(fersBasicAnnuity90),
      };

      return payload;
    }
  };
  const multifactor = () => {
    const age = moment().diff(plannerData["dob"], "years", true);
    let multifactor = 0;
    if (age <= 35) {
      multifactor = 1.0;
    }
    if (age >= 36) {
      multifactor = 0.6;
    }
    if (age >= 37) {
      multifactor = 0.8;
    }
    if (age >= 38) {
      multifactor = 0.7;
    }
    if (age >= 39) {
      multifactor = 0.6;
    }
    if (age >= 40) {
      multifactor = 0.5;
    }
    if (age >= 41) {
      multifactor = 0.4;
    }
    if (age >= 41) {
      multifactor = 0.3;
    }
    if (age >= 43) {
      multifactor = 0.2;
    }
    if (age >= 44) {
      multifactor = 0.1;
    }
    if (age >= 45) {
      multifactor = 0;
    }
    return multifactor;
  };

  useEffect(() => {
    let legacyStatus = true;
    let payload = {};
    const dobDate = moment(formData["dob"]);
    let age = moment().diff(dobDate, "years", true);
    let legencyList = capitalList;
    if (age) {
      if (age >= 55) {
        legacyStatus = false;
        legencyList = seniorList;
      }
      age = Math.floor(age);
    } else {
      age = 0;
    }
    const legencyData = legencyList.find((item) => item["age"] == age);
    if (legencyData) {
      const lifeInsuranceCapital = [
        {
          name: "Year 1",
          value: formatValue(legencyData["li_amnt_year_1"]),
          protection: formatValue(legencyData["pro_amnt_year_1"]),
        },
        {
          name: "Year 2",
          value: formatValue(legencyData["li_amnt_year_2"]),
          protection: formatValue(legencyData["pro_amnt_year_2"]),
        },
        {
          name: "Year 11",
          value: formatValue(legencyData["li_amnt_year_11"]),
          protection: formatValue(legencyData["pro_amnt_year_11"]),
        },
        {
          name: "Age 65",
          value: formatValue(legencyData["li_amnt_age_65"]),
          protection: formatValue(legencyData["pro_amnt_age_65"]),
        },
      ];

      const lifeInsuranceSenior = [
        {
          name: "Year 1",
          value: formatValue(legencyData["li_amnt_year_1"]),
          protection: formatValue(legencyData["pro_amnt_year_1"]),
        },
      ];

      const otherInsuranceCapital = [
        {
          name: "Year 1",
          value: formatValue(legencyData["ri_amnt_year_1"]),
          protection: formatValue(legencyData["val_at_1_per_year_1"]),
        },
        {
          name: "Year 2",
          value: formatValue(legencyData["ri_amnt_year_2"]),
          protection: formatValue(legencyData["val_at_1_per_year_2"]),
        },
        {
          name: "Year 11",
          value: formatValue(legencyData["ri_amnt_year_11"]),
          protection: formatValue(legencyData["val_at_1_per_year_11"]),
        },
        {
          name: "Age 65",
          value: formatValue(legencyData["ri_amnt_age_65"]),
          protection: formatValue(legencyData["val_at_1_per_age_65"]),
        },
      ];

      let otherInsuranceSenior = [
        {
          name: "Year 1",
          value: formatValue(legencyData["trade_amnt_year_1"]),
          protection: formatValue(legencyData["val_at_1_per_year_1"]),
        },
      ];

      if (age <= 65) {
        otherInsuranceSenior = [
          ...otherInsuranceSenior,
          {
            name: "Age 65",
            value: formatValue(legencyData["trade_amnt_age_65"]),
            protection: formatValue(legencyData["val_at_1_per_age_65"]),
          },
        ];
      }
      if (age <= 70) {
        otherInsuranceSenior = [
          ...otherInsuranceSenior,
          {
            name: "Age 70",
            value: formatValue(legencyData["trade_amnt_age_70"]),
            protection: formatValue(legencyData["val_at_1_per_age_70"]),
          },
        ];
      }
      payload = {
        title: legacyStatus ? "CAPITAL LEGACY" : "SENIOR CHOICE",
        "bi-weekly": legacyStatus ? "105" : legencyData["bwlc"],
        secondTitle: legacyStatus ? "ROTH IRA" : "FPAR (Optional)",
        lifeInsurance: legacyStatus
          ? lifeInsuranceCapital
          : lifeInsuranceSenior,
        otherInsurance: legacyStatus
          ? otherInsuranceCapital
          : otherInsuranceSenior,
      };
    } else {
      payload = {
        title: "CAPITAL LEGACY",
        "bi-weekly": "$105",
        secondTitle: "ROTH IRA",
        lifeInsurance: {
          name: "N/A",
          value: "N/A",
          protection: "N/A",
        },
        otherInsurance: {
          name: "N/A",
          value: "N/A",
          protection: "N/A",
        },
      };
    }
    setLegencyDetail(payload);
    setCapitalLegacy(legacyStatus);
  }, [formData["dob"]]);

  const Planner = () => (
    <div>
      <Row>
        <Image src={Images.social} />
      </Row>

      <div
        className="w-100 padding-10"
        style={{
          whiteSpace: "normal",

          border: "2px solid #333",
        }}
      >
        <table className="fears-formula-table mx-1 overView-title">
          <colgroup>
            <col span="1" style={{ width: "30%" }} />
            <col span="1" style={{ width: "5%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "10%" }} />
            <col span="1" style={{ width: "35%" }} />
          </colgroup>
          <tr>
            <td>
              {" "}
              <div className="input-wrapper align-items-center font-17">
                <div>$</div>
                <div className="text-wrapper">
                  <Form.Control
                    className="input-box w-100"
                    type="text"
                    name="age_62_ss_benefit"
                    value={formatValue(formData.age_62_ss_benefit)}
                    readOnly
                  />
                </div>
              </div>
            </td>
            <td>
              <div className="mx-1"> X</div>
            </td>
            <td>
              <div className="text-wrapper input-wrapper font-17">
                <Form.Control
                  className="input-box m-0 w-100"
                  type="text"
                  name="yearsOfServiceat62"
                  value={formatValue(formData.yearsOfServiceat62)}
                  readOnly
                />
              </div>
            </td>
            <td className="font-17">
              <div className="text-nowrap  mx-1">/ 40 =</div>
            </td>

            <td>
              <div className="input-wrapper align-items-center font-17">
                <div>$</div>
                <div className="text-wrapper">
                  <Form.Control
                    className="input-box w-100"
                    type="text"
                    name="age_62_ss_benefit_final"
                    value={formatValue(formData.age_62_ss_benefit_final)}
                    readOnly
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="font-17">
              <p
                align="center"
                className="field-description-text2"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                *Age 62 SS
              </p>
            </td>
            <td></td>
            <td className="font-17">
              <p align="center" className="field-description-text2">
                Years
              </p>
            </td>
            <td></td>
            <td className="font-17">
              <p align="center" className="field-description-text2">
                Benefit
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );

  return (
    <div className="overView">
      <Card.Body>
        <div className=" paystub-section">
          <div className="text-center">
            <div className="head-title">Paystub Review</div>
          </div>
          <div className="mt-3 over-view-container m-auto">
            <div className="d-flex align-items-center mt-3">
              <div>
                <div className="overView-title text-nowrap">
                  <div>First Name </div>
                </div>
              </div>
              <div className="title-wrapper">
                <Form.Control
                  type="text"
                  readOnly
                  className="ms-1 required-input"
                  value={formData.firstname}
                />
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <div>
                <div className="overView-title text-nowrap">
                  <div>Last Name </div>
                </div>
              </div>
              <div className="title-wrapper">
                <Form.Control
                  type="text"
                  readOnly
                  name="pay_s2_36 "
                  className="ms-1 required-input"
                  value={formData.lastname}
                />
              </div>
            </div>
            <div className="d-flex align-items-center text-align-center mt-3">
              <div>
                <div className=" overView-title text-nowrap">Gender</div>
              </div>
              <Row className="mx-2">
                <Col md={6} className=" d-flex">
                  <input
                    id="male"
                    type="radio"
                    name="gender"
                    value="male"
                    checked={formData.gender == "male"}
                    readOnly
                  />{" "}
                  <label className="radioLable mx-2" htmlFor="male">
                    Male
                  </label>
                </Col>
                <Col md={6} className="d-flex">
                  <input
                    id="female"
                    type="radio"
                    name="gender"
                    checked={formData.gender == "female"}
                    readOnly
                  />{" "}
                  <label className="radioLable mx-2" htmlFor="female">
                    Female
                  </label>
                </Col>
              </Row>
            </div>

            <div className="d-flex align-items-center mt-3">
              <div>
                <div className="overView-title text-nowrap">
                  <div>Date Of Birth </div>
                </div>
              </div>

              <div className="title-wrapper">
                <Form.Control
                  type="text"
                  readOnly
                  className="form-element required-input"
                  value={
                    formData["dob"] && moment(formData["dob"]).isValid()
                      ? moment(formData["dob"]).format("MM/DD/yyyy")
                      : ""
                  }
                />
              </div>
            </div>
            <div className="d-flex align-items-center  mt-3">
              <div>
                <div className="overView-title text-nowrap">Family Status</div>
              </div>
              <Row className="mx-2">
                <Col md={4} className="d-flex">
                  <input
                    id="single"
                    type="radio"
                    name="family_status"
                    className="mx-1"
                    readOnly
                    checked={formData.family_status == "single"}
                  />{" "}
                  <label className="radioLable" htmlFor="single">
                    Single
                  </label>
                </Col>
                <Col md={4} className="d-flex">
                  <input
                    id="married"
                    type="radio"
                    name="family_status"
                    className="mx-1"
                    readOnly
                    checked={formData.family_status == "married"}
                  />{" "}
                  <label className="radioLable" htmlFor="married">
                    Married
                  </label>
                </Col>
                <Col md={4} className="d-flex">
                  <input
                    id="divorced"
                    type="radio"
                    name="family_status"
                    className="mx-1"
                    checked={formData.family_status == "divorced"}
                    readOnly
                  />{" "}
                  <label className="radioLable" htmlFor="divorced">
                    Divorced
                  </label>
                </Col>
              </Row>
            </div>
            <div className="d-flex align-items-center mt-3">
              <div>
                <div className="overView-title text-nowrap">
                  Do you have children?{" "}
                </div>
              </div>
              <Row className="mx-2">
                <Col md={6} className="d-flex">
                  <input
                    id="do_you_have_childrenyes"
                    type="radio"
                    name="do_you_have_children"
                    checked={formData.do_you_have_children == "yes"}
                    readOnly
                  />{" "}
                  <label
                    className="radioLable mx-2"
                    htmlFor="do_you_have_childrenyes"
                  >
                    Yes
                  </label>
                </Col>
                <Col className="d-flex">
                  <input
                    id="do_you_have_childrenNo"
                    type="radio"
                    name="do_you_have_children"
                    checked={formData.do_you_have_children == "no"}
                    readOnly
                  />{" "}
                  <label
                    className="radioLable mx-2"
                    htmlFor="do_you_have_childrenNo"
                  >
                    No
                  </label>
                </Col>
              </Row>
            </div>
          </div>
          {isCapitalLegacy && <div className="border-top m-auto"></div>}
          <div className="mt-3 over-view-container m-auto ">
            {isCapitalLegacy && (
              <div className="d-flex align-items-center mt-3">
                <div>
                  <div className="overView-title text-nowrap">
                    <div>Retirement Code </div>
                  </div>
                </div>
                <div className="title-wrapper">
                  <Form.Control
                    type="text"
                    readOnly
                    className="ms-1 required-input"
                    value={formData.pay_retire}
                  />
                </div>
              </div>
            )}
            {!isCapitalLegacy && (
              <div className="d-flex align-items-center text-align-center mt-3">
                <div>
                  <div className="overView-title text-nowrap">Career</div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <div className="mx-2">
                    <input
                      id="carreryes"
                      type="radio"
                      name="lite_pay_career"
                      checked={formData.lite_pay_career == "yes"}
                      readOnly
                    />{" "}
                    <label className="radioLable" htmlFor="carreryes">
                      Yes
                    </label>
                  </div>
                  <div className="mx-2">
                    <input
                      id="carrerNo"
                      type="radio"
                      name="lite_pay_career"
                      checked={formData.lite_pay_career == "no"}
                    />{" "}
                    <label className="radioLable" htmlFor="carrerNo">
                      No
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex align-items-center mt-3">
              <div className="overView-title text-nowrap">
                <div> {isCapitalLegacy ? "Hire Date" : "Start Date"} </div>
              </div>

              <div className="title-wrapper">
                <Form.Control
                  type="text"
                  readOnly
                  className="form-element required-input"
                  value={
                    formData["lite_pay_start_year"] &&
                    moment(formData["lite_pay_start_year"]).isValid()
                      ? moment(formData["lite_pay_start_year"]).format(
                          "MM/DD/yyyy"
                        )
                      : ""
                  }
                />
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <div>
                <div className="overView-title">
                  {isCapitalLegacy
                    ? "Military service"
                    : "Past military service?"}
                </div>
              </div>
              <Row className="mx-2">
                <Col className="d-flex">
                  <input
                    id="militaryServiceyes"
                    type="radio"
                    name="have_military_service"
                    checked={formData.have_military_service == "yes"}
                    readOnly
                  />{" "}
                  <label
                    className="radioLable mx-2"
                    htmlFor="militaryServiceyes"
                  >
                    Yes
                  </label>
                </Col>
                <Col className="d-flex">
                  <input
                    id="militaryServiceNo"
                    type="radio"
                    name="have_military_service"
                    checked={formData.have_military_service == "no"}
                    readOnly
                  />{" "}
                  <label
                    className="radioLable mx-2"
                    htmlFor="militaryServiceNo"
                  >
                    No
                  </label>
                </Col>
              </Row>
            </div>
          </div>
          {isCapitalLegacy && (
            <div className="d-flex align-items-center mt-3 over-view-container m-auto">
              <div>
                <div className=" overView-title text-nowrap">
                  <div>Base Rate of Pay </div>
                </div>
              </div>
              <div className="title-wrapper">
                <Form.Control
                  type="text"
                  readOnly
                  className="ms-1 required-input"
                  value={formData.pay_s2_1}
                />
              </div>
            </div>
          )}
          {isCapitalLegacy && (
            <div className="d-flex align-items-center mt-3 over-view-container m-auto">
              <div>
                <div className="overView-title text-nowrap">
                  <div>FEGLI Code</div>
                </div>
              </div>
              <div className="title-wrapper">
                <Form.Control
                  type="text"
                  readOnly
                  className="ms-1 required-input"
                  value={formData.pay_s2_15}
                />
              </div>
            </div>
          )}
          {isCapitalLegacy && (
            <div className="d-flex mt-3 over-view-container m-auto">
              <div>
                <div className="radioLable text-nowrap ">
                  <div>Options</div>
                </div>
              </div>
              <div>
                <div className="title-wrapper d-flex">
                  <Row className="mx-1">
                    <Col md={6} className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        name="checkboxBasicLife"
                        checked={formData.checkboxBasicLife ? true : false}
                      />{" "}
                      <label className="mx-1 radioLable">Basic </label>
                    </Col>
                    <Col md={6} className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        name="checkboxBasicLife"
                        checked={formData.fegli_line9 ? true : false}
                      />{" "}
                      <label className="mx-1 radioLable">Option B</label>
                    </Col>
                    <Col md={6} className="d-flex align-items-center  ">
                      <input
                        type="checkbox"
                        name="checkboxBasicLife"
                        checked={
                          convertValue(formData.checkboxOptionA) ? true : false
                        }
                      />{" "}
                      <label className="mx-1 radioLable">Option A </label>
                    </Col>
                    <Col md={6} className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        name="checkboxBasicLife"
                        checked={
                          convertValue(formData.selectOptionCMultiple)
                            ? true
                            : false
                        }
                      />{" "}
                      <label className="mx-1 radioLable">Option C</label>
                    </Col>
                  </Row>
                </div>
                <div className="title-wrapper d-flex"></div>
              </div>
            </div>
          )}
          {isCapitalLegacy && <div className="border-top m-auto"></div>}
          <div className="mt-3 over-view-container m-auto">
            <div className="d-flex align-items-center mt-3">
              <div>
                <div className="overView-title">Agency</div>
              </div>
              <Row className="mx-2 ">
                <Col
                  md={6}
                  sm={6}
                  xs={6}
                  className=" d-flex  align-item-center"
                >
                  <input
                    id="USPS"
                    type="radio"
                    name="lite_pay_agency"
                    checked={formData.lite_pay_agency == "USPS"}
                    readOnly
                  />{" "}
                  <label className="radioLable mx-2" htmlFor="USPS">
                    USPS
                  </label>
                </Col>
                <Col md={6} className="d-flex align-item-center">
                  <input
                    id="Non-Postal"
                    type="radio"
                    name="lite_pay_agency"
                    checked={formData.lite_pay_agency == "Other"}
                    readOnly
                  />{" "}
                  <label
                    className="radioLable mx-2 text-nowrap"
                    htmlFor="Non-Postal"
                  >
                    Non-Postal
                  </label>
                </Col>
              </Row>
            </div>
            <div className="d-flex align-items-center mb-3">
              <input
                id="provisions"
                type="checkbox"
                className="label-check-fa allow_email_correspondence report-section-input"
                name="special_provisions"
                checked={formData["special_provisions"] === "1" ? true : false}
                readOnly
              />{" "}
              <label
                htmlFor="provisions"
                className="radioLable px-2 text-nowrap"
              >
                {" "}
                Check for Special Provisions/6(c)
              </label>
            </div>
            <div className="d-flex align-items-center mb-3">
              <input
                id="ver_eligible"
                type="checkbox"
                className="label-check-fa allow_email_correspondence report-section-input"
                name="ver_eligible"
                checked={formData["ver_eligible"] === "1" ? true : false}
                readOnly
              />{" "}
              <label
                htmlFor="ver_eligible"
                className="radioLable px-2 text-nowrap"
              >
                {" "}
                VER Eligible (Early Out)
              </label>
            </div>
          </div>

          {!isCapitalLegacy && (
            <div>
              <div className="d-flex align-items-center over-view-container m-auto mt-3">
                <div>
                  <div className="overView-title text-nowrap">
                    <div>Net Pay </div>
                  </div>
                </div>
                <div className="title-wrapper">
                  <Form.Control
                    type="text"
                    readOnly
                    name="pay_s2_36 "
                    className="ms-1 required-input"
                    value={formData.pay_s2_36}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center over-view-container m-auto mt-3">
                <div>
                  <div className="overView-title text-nowrap">
                    <div>Annual Pay </div>
                  </div>
                </div>
                <div className="title-wrapper">
                  <Form.Control
                    type="text"
                    readOnly
                    name="pay_s2_36 "
                    className="ms-1 required-input"
                    value={formData.pay_s2_1}
                  />
                </div>
              </div>
              <div
                className="over-view-container m-auto p-3 mb-2 mt-2"
                style={{ border: "1px solid #333" }}
              >
                <div className="overView-title text-nowrap">Paid Hours</div>
              </div>
              <div className="over-view-container m-auto paid-hours ">
                <Table
                  className="mx-1 my-3 table-content text-body paystub-table"
                  bordered
                >
                  <colgroup>
                    <col span="1" style={{ width: "60%" }} />
                    <col span="1" style={{ width: "20%" }} />
                    <col span="1" style={{ width: "20%" }} />
                  </colgroup>
                  <thead>
                    <tr className="text-center">
                      <th className="text-nowrap">Description</th>
                      <th className="text-nowrap">Hours</th>
                      <th className="text-nowrap">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-nowrap">WORK HOURS</td>
                      <td className="transperentNumber ">
                        <Form.Control
                          className="w-100 text-end required-input"
                          type="text"
                          placeholder="0.00"
                          value={formData.pay_s2_working_hours}
                          readOnly
                        />
                      </td>
                      <td className="transperentNumber ">
                        <Form.Control
                          type="text"
                          className="text-end required-input"
                          placeholder="0.00"
                          name="pay_s2_working_hours_amount"
                          value={formData.pay_s2_working_hours_amount}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-nowrap">OVERTIME HOURS</td>
                      <td className="transperentNumber ">
                        <Form.Control
                          type="text"
                          className="text-end required-input"
                          placeholder="0.00"
                          name="pay_s2_overtime_hours"
                          value={formData.pay_s2_overtime_hours}
                          readOnly
                        />
                      </td>
                      <td className="transperentNumber ">
                        <Form.Control
                          type="text"
                          placeholder="0.00"
                          className="text-end required-input"
                          name="pay_s2_overtime_hours_amount"
                          value={formData.pay_s2_overtime_hours_amount}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <b>
                          <span className="red">*</span>Total Hours Gross Pay:
                        </b>
                      </td>
                      <td className={"highlightNumber"}>
                        {" "}
                        <Form.Control
                          type="text"
                          className="text-end required-input"
                          placeholder="0.00"
                          name="pay_s2_2"
                          value={formData.pay_s2_2}
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          {!isCapitalLegacy && (
            <>
              <div className="over-view-container m-auto">
                <Table
                  className="paystub-table  table-content text-body"
                  bordered
                >
                  <thead>
                    <tr>
                      <th colspan="2" className="text-left">
                        Annual & Sick Leave
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="w-75 ">
                        <span className="red">*</span>=Available AL Balance
                      </td>
                      <td className={"highlightNumber"}>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="pay_s2_18"
                          value={formData.pay_s2_18}
                          className="text-end required-input"
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="red">*</span>=Current SL Balance
                      </td>
                      <td className={"highlightNumber"}>
                        <Form.Control
                          type="text"
                          placeholder="0"
                          name="pay_s2_29"
                          value={formData.pay_s2_29}
                          className="text-end  required-input"
                          readOnly
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </>
          )}
          {!isCapitalLegacy && (
            <div className="over-view-container m-auto ">
              <Table className="paystub-table mt-3 text-body" bordered>
                <colgroup>
                  <col span="1" style={{ width: "70%" }} />
                  <col span="1" style={{ width: "30%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th style={{ width: "20%" }} className="text-center">
                      Description
                    </th>
                    <th>Pay Period</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="red">*</span>Social Security
                    </td>
                    <td className={"highlightNumber"}>
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_34"
                        value={formData.pay_s2_34}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="red">*</span>Medicare
                    </td>
                    <td className={"highlightNumber"}>
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_13"
                        value={formData.pay_s2_13}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="red">*</span>Federal Tax
                    </td>
                    <td className={"highlightNumber"}>
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_5"
                        value={formData.pay_s2_5}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>State Income Tax</td>
                    <td className="transperentNumber text-end d-flex justify-content-end">
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_8"
                        value={formData.pay_s2_8}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="tsp-container ">
                      <div> Thrift Saving Plan</div>
                      <div className=" align-items-center mx-2">
                        <div className="d-flex mx-2 fw-bold">
                          <div>
                            <input
                              id="thriftSavingcheck"
                              type="radio"
                              name="pay_s2_25"
                              checked={
                                formData["pay_s2_25"] == "$" ? true : false
                              }
                            />
                          </div>
                          <label className="mx-1" htmlFor="thriftSavingcheck">
                            Fixed
                          </label>
                        </div>
                        <div className="mx-2 d-flex align-items-center  fw-bold">
                          <div>
                            <input
                              id="thriftSavingcheck"
                              type="radio"
                              name="pay_s2_25"
                              checked={
                                formData["pay_s2_25"] == "%" ? true : false
                              }
                            />
                          </div>
                          <div className="mx-2 d-flex align-items-center highlightHalfNumber w-25">
                            <Form.Control
                              id="thriftSavingcheck2"
                              type="number"
                              step="1"
                              name="pay_s2_tsp_percent"
                              value={formData.pay_s2_tsp_percent}
                              className="text-end required-input p-0"
                              readOnly
                            />
                          </div>
                          <div className="mx-1">%</div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="text-end transperentNumber d-flex justify-content-end">
                        <Form.Control
                          type="text"
                          placeholder="0.00"
                          name="pay_s2_26"
                          value={formData.pay_s2_26}
                          className="text-end  required-input"
                          readOnly
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="tsp-container">
                      <div> Thrift Saving Plan R</div>
                      <div className=" mx-2">
                        <div className="d-flex mx-2 fw-bold">
                          <div>
                            <input
                              id="thriftSavingcheck3"
                              type="radio"
                              name="pay_s2_100"
                              checked={
                                formData["pay_s2_100"] == "$" ? true : false
                              }
                            />
                          </div>
                          <label className="mx-1" htmlFor="thriftSavingcheck3">
                            Fixed
                          </label>
                        </div>
                        <div className="mx-2 d-flex align-items-center  fw-bold">
                          <div>
                            <input
                              type="radio"
                              name="pay_s2_100"
                              value="Custome"
                              checked={formData["pay_s2_100"] == "%"}
                            />
                          </div>

                          <div className="mx-2 d-flex align-items-center highlightHalfNumber w-25">
                            {" "}
                            <Form.Control
                              value={formData.pay_s2_tsp_r_percent}
                              className="text-end required-input p-0"
                              isInvalid={
                                validated &&
                                (!formData.pay_s2_tsp_r_percent ||
                                  formData.pay_s2_tsp_r_percent == "0")
                              }
                              required
                            />
                          </div>
                          <div className="mx-1">%</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-end transperentNumber d-flex justify-content-end">
                        <Form.Control
                          type="text"
                          placeholder="0.00"
                          name="pay_tsp_r_1"
                          value={formData.pay_tsp_r_1}
                          className="text-end  required-input"
                          readOnly
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center transperentNumber">
                        <div>Retire</div>
                      </div>
                    </td>
                    <td className="transperentNumber text-end d-flex justify-content-end">
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_retire_amount"
                        value={formData.pay_retire_amount}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  {formData["lite_pay_agency"] == "USPS" && (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <div>FEGLI Code</div>
                        </div>
                      </td>
                      <td className="transperentNumber text-end d-flex justify-content-end">
                        <Form.Control
                          type="text"
                          name="pay_s2_15"
                          value={formData.pay_s2_15}
                          className="text-end  required-input"
                          readOnly
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <div className="d-flex">
                        <label className="mx-1">Basic Life </label>
                      </div>
                    </td>
                    <td className="transperentNumber  d-flex justify-content-end">
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="cost_basic_life"
                        value={formData.cost_basic_life}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex">
                        <label className="mx-1" htmlFor="optionACheck">
                          Option A
                        </label>
                      </div>
                    </td>
                    <td className="transperentNumber text-end d-flex justify-content-end">
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="fegli_cost_option_a"
                        value={formData.fegli_cost_option_a}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex">
                        <div className="mx-1">Option B</div>
                      </div>
                    </td>
                    <td className="transperentNumber text-end d-flex justify-content-end">
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="fegli_cost_option_b"
                        value={formData.fegli_cost_option_b}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex">
                        <div className="mx-1">Option C</div>
                      </div>
                    </td>
                    <td className="transperentNumber text-end d-flex justify-content-end">
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="fegli_cost_option_c"
                        value={formData.fegli_cost_option_c}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>FED-V</td>
                    <td className="transperentNumber text-end d-flex justify-content-end">
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_28"
                        value={formData.pay_s2_28}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>FED-D</td>
                    <td
                      className={`text-end d-flex justify-content-end ${
                        formData["pay_s2_30"]
                          ? "transperentNumber"
                          : "greyNumber"
                      }
                        `}
                    >
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_30"
                        value={formData.pay_s2_30 ? formData.pay_s2_30 : ""}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>FEHB</td>
                    <td
                      className={`text-end d-flex justify-content-end ${
                        formData["pay_s2_32"]
                          ? "transperentNumber"
                          : "greyNumber"
                      }
                        `}
                    >
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_32"
                        value={formData.pay_s2_32 ? formData.pay_s2_32 : ""}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Allotment</td>
                    <td
                      className={`text-end d-flex justify-content-end ${
                        formData["pay_s2_20"]
                          ? "transperentNumber"
                          : "greyNumber"
                      }
                        `}
                    >
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_20"
                        value={formData.pay_s2_20 ? formData.pay_s2_20 : ""}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Allotment</td>
                    <td
                      className={`text-end d-flex justify-content-end ${
                        formData["pay_s2_22"]
                          ? "transperentNumber"
                          : "greyNumber"
                      }
                        `}
                    >
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_22"
                        value={formData.pay_s2_22 ? formData.pay_s2_22 : ""}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Allotment</td>
                    <td
                      className={`text-end d-flex justify-content-end  ${
                        formData["pay_s2_24"]
                          ? "transperentNumber"
                          : "greyNumber"
                      }
                        `}
                    >
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_24"
                        value={formData.pay_s2_24 ? formData.pay_s2_24 : ""}
                        className="text-end  required-input"
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Net Pay (Net To Bank): </b>
                    </td>
                    <td className=" text-end d-flex justify-content-end">
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        name="pay_s2_36"
                        value={formData.pay_s2_36}
                        className="text-end fw-bold w-100 required-input"
                        readOnly
                        isInvalid={validated && !formData.pay_s2_36}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="text-center">
          <div className="head-title">FEGLI Coverage</div>
        </div>
        <div className="mt-3 over-view-container m-auto">
          <Stack gap={2}>
            <div className="">
              <div>
                <div className="mt-3">
                  <div className="title-bold">
                    <span className="">BASIC LIFE </span>
                  </div>
                  <div className="common-title">
                    <p>
                      Your basic insurance amount depends on your annual basic
                      pay. (Excludes COLA)
                    </p>
                  </div>
                  <table className="text-body table-td ">
                    <colgroup>
                      {" "}
                      <col span="1" style={{ width: "10%" }} />
                      <col span="1" style={{ width: "5%" }} />
                      <col span="1" style={{ width: "50%" }} />
                      <col span="1" style={{ width: "40%" }} />
                    </colgroup>
                    <tbody className="text-start">
                      <tr>
                        <td>Line 1 </td>
                        <td className="p-0 text-center">-</td>
                        <td className="text-start">Your current basic pay</td>
                        <td>
                          <div className="input-wrapper">
                            $
                            <Form.Control
                              className="input-box input-section fw-bold"
                              type="text"
                              name="fegli_line1"
                              value={formatValue(formData.pay_s2_1)}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Line 2 </td>
                        <td className="p-0 text-center">-</td>
                        <td>
                          If not an exact thousand,<br></br> round up to the
                          next thousand
                        </td>
                        <td>
                          <div className="input-wrapper">
                            $
                            <Form.Control
                              className="input-box input-section fw-bold"
                              type="text"
                              name="fegli_line2"
                              value={formatValue(formData["fegli_line2"])}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Line 3 </td>
                        <td className="p-0 text-center">-</td>
                        <td>Add $2,000</td>
                        <td>
                          <div className="input-wrapper">
                            ${" "}
                            <Form.Control
                              className="input-box input-section fw-bold"
                              type="text"
                              name="fegli_line3"
                              value={formatValue(formData.fegli_line3)}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Line 4 </td>
                        <td className="fw-bold text-center">-</td>

                        <td>
                          <b>Total Insurance </b>
                          <br></br>(Add lines 2 and 3)
                        </td>
                        <td>
                          <div className="input-wrapper">
                            <b> $ </b>
                            <Form.Control
                              className="input-box input-section fw-bold"
                              type="text"
                              name="fegli_line4"
                              value={formatValue(formData.fegli_line4)}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="">
              <div className="">
                <div>
                  <div className="title-bold  mt-3">
                    <span>EXTRA BENEFIT </span>
                  </div>
                  <table className="text-body table-td">
                    <colgroup>
                      <col span="1" style={{ width: "10%" }} />
                      <col span="1" style={{ width: "5%" }} />
                      <col span="1" style={{ width: "50%" }} />
                      <col span="1" style={{ width: "40%" }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>Line 5 </td>
                        <td className="p-0 text-center">-</td>
                        <td>Basic Life Insurance (Line 4)</td>
                        <td>
                          <div className="input-wrapper">
                            ${" "}
                            <Form.Control
                              className="input-box input-section fw-bold"
                              type="text"
                              name="fegli_line5"
                              value={formatValue(formData.fegli_line5)}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Line 6 </td>
                        <td className="p-0 text-center">-</td>
                        <td>Multiplication Factor</td>
                        <td>
                          <div
                            className="input-wrapper w-50"
                            style={{ float: "right" }}
                          >
                            X{" "}
                            <Form.Control
                              className="input-box  fw-bold"
                              type="text"
                              name="fegli_line6"
                              readOnly
                              value={formData.fegli_line6}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="fw-bold">
                        <td>Line 7</td>
                        <td className="p-0 text-center">-</td>
                        <td>Total Extra Benefit</td>
                        <td>
                          <div className="input-wrapper">
                            $
                            <Form.Control
                              className="input-box input-section fw-bold"
                              type="text"
                              name="fegli_line7"
                              value={formatValue(formData.fegli_line7)}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div>
                <div>
                  <table className="w-100 text-body table-td">
                    <colgroup>
                      <col span="1" style={{ width: "60%" }} />
                      <col span="1" style={{ width: "30%" }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <div className="fw-bold title-bold text-nowrap">
                            <div> OPTION A </div>
                          </div>
                        </td>
                        <td>
                          <div className="fw-bold input-wrapper">
                            $
                            <Form.Control
                              type="text"
                              placeholder="0.00"
                              className="input-box input-section fw-bold "
                              value={optionA}
                              onChange={(e) => setOptionA(e.target.value)}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="">
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div className="mt-3 title-bold mt-3">
                            <div className="d-flex justify-content-center">
                              <div className="text-nowrap">
                                OPTION B - ADDITIONAL{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <FederalEmployeeCostModal
                        age60={formData["age60OptionB"]}
                        age65={formData["age65OptionB"]}
                        age70={formData["age70OptionB"]}
                        age80={formData["age80OptionB"]}
                      />
                    </div>
                  </div>
                  <table className="text-body table-td">
                    <colgroup>
                      <col span="1" style={{ width: "10%" }} />
                      <col span="1" style={{ width: "5%" }} />
                      <col span="1" style={{ width: "50%" }} />
                      <col span="1" style={{ width: "40%" }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>Line 8</td>
                        <td className="p-0 text-center">-</td>
                        <td>Amount in Line 2</td>
                        <td>
                          <div className="input-wrapper">
                            ${" "}
                            <Form.Control
                              className="input-box input-section fw-bold"
                              type="text"
                              name="fegli_line8"
                              value={formatValue(formData.fegli_line8)}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Line 9</td>
                        <td className="p-0 text-center">-</td>
                        <td>Multiple</td>
                        <td>
                          <div
                            className="input-wrapper w-50"
                            style={{ float: "right" }}
                          >
                            X
                            <Form.Control
                              className="input-box fw-bold"
                              type="text"
                              name="fegli_line9"
                              value={formData.fegli_line9}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Line 10</td>
                        <td className="p-0 text-center">-</td>
                        <td>
                          <b>Total Extra Benefit</b>
                          <br></br>
                          (line 8 times line 9)
                        </td>
                        <td>
                          <div className="input-wrapper">
                            ${" "}
                            <Form.Control
                              className="input-box input-section fw-bold"
                              type="text"
                              name="fegli_line10"
                              value={formatValue(formData.fegli_line10)}
                              readOnly
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Stack>
        </div>

        <div className="mt-3 ">
          <div className="d-flex justify-content-center w-100">
            <div className="over-view-container">
              <div className="title-bold m-auto  ">
                <span>OPTION C - FAMILY </span>
              </div>
              <div className="common-title  m-auto">
                <p>
                  Option C covers eligible family members in multiples up to
                  five (5) times per unit. Each unit is worth $5,000 for spouse
                  and $2,500 for children, including adopted children and
                  stepchildren. Children must be under age 22, or if 22 or over,
                  must be incapable of self-support due to a mental or physical
                  disability. Costs below are per unit of coverage.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-5">
          <div className="head-title">FEGLI Premium</div>
        </div>
        <div className="mt-3">
          <div className="over-view-container m-auto special-contiainer">
            <div className=" mt-10">
              <div>
                <div>
                  <h6 className="title-bold">Total Life Insurance</h6>
                </div>
                <div className="common-title text-nowrap d-flex justify-content-between">
                  <div>Basic Life</div>
                  <div className="input-wrapper mt-1 input-end">
                    $
                    <Form.Control
                      className="input-box fw-bold "
                      type="text"
                      name="basic_life"
                      value={formatValue(formData.basic_life)}
                      readOnly
                    />
                  </div>
                </div>
                <div className="common-title text-nowrap d-flex justify-content-between ">
                  <div>Extra Benefit</div>
                  <div className="input-wrapper mt-1 input-end">
                    $
                    <Form.Control
                      className="input-box fw-bold "
                      type="text"
                      name="fegli_line7"
                      value={formatValue(formData.fegli_line7)}
                      readOnly
                    />
                  </div>
                </div>
                <div className="common-title text-nowrap d-flex justify-content-between">
                  <div>Option A ($10,000)</div>
                  <div className="input-wrapper mt-1 input-end">
                    $
                    <Form.Control
                      className="input-box fw-bold "
                      type="text"
                      name="option_a"
                      value={formatValue(formData.option_a)}
                      readOnly
                    />
                  </div>
                </div>
                <div className="common-title text-nowrap d-flex justify-content-between ">
                  <div>Option B - Additional</div>
                  <div className="input-wrapper mt-1 input-end">
                    $
                    <Form.Control
                      className="input-box fw-bold"
                      type="text"
                      name="fegli_line10"
                      value={formatValue(formData.fegli_line10)}
                      readOnly
                    />
                  </div>
                </div>
                <div className="border-top w-100 m-auto mt-1"></div>
                <div className="common-title text-nowrap d-flex justify-content-between mt-1 fw-bold">
                  <div>Total Life Insurance</div>
                  <div className="input-wrapper mt-1 input-end">
                    $
                    <Form.Control
                      className="input-box fw-bold"
                      type="text"
                      name="total_life_insurance"
                      value={formatValue(formData.total_life_insurance)}
                      readOnly
                    />
                  </div>
                </div>

                <div className="common-title text-nowrap d-flex justify-content-between">
                  <div>Option C - Spouse</div>
                  <div className="input-wrapper mt-1 input-end">
                    $
                    <Form.Control
                      className="input-box fw-bold"
                      type="text"
                      name="optionCSpouce"
                      value={formatValue(formData.optionCSpouce)}
                      readOnly
                    />
                  </div>
                </div>
                <div className="common-title  d-flex justify-content-between">
                  <div className="align-option-c">- Children</div>
                  <div className="input-wrapper mt-1 input-end">
                    $
                    <Form.Control
                      className="input-box fw-bold fegli-child-input"
                      type="text"
                      name="optionCChildren"
                      value={formData.optionCChildren}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="over-view-container m-auto mt-10">
            <div>
              <h4 className="title-black">
                FEGLI Premiums at Retirement (Postal)
              </h4>
              <table className="text-body first-col-text-left text-center table-striped">
                <tbody>
                  <tr>
                    <td className="table-td">Age</td>
                    <td className="table-td">Bi-Weekly</td>
                    <td className="table-td">Total Premium</td>
                  </tr>
                  <tr>
                    <td className="table-td">30 - 35</td>
                    <td className="table-td-weekly">$7.70</td>
                    <td className="table-td-weekly">$1,001</td>
                  </tr>
                  <tr>
                    <td className="table-td">35 - 39</td>
                    <td className="table-td-weekly">$7.90</td>
                    <td className="table-td-weekly">$2,028</td>
                  </tr>
                  <tr>
                    <td className="table-td">40 - 44</td>
                    <td className="table-td-weekly">$11.90</td>
                    <td className="table-td-weekly">$3,575 </td>
                  </tr>
                  <tr>
                    <td className="table-td">45 - 49</td>
                    <td className="table-td-weekly">$22.75</td>
                    <td className="table-td-weekly">$6,533</td>
                  </tr>
                  <tr>
                    <td className="table-td">50 - 54</td>
                    <td className="table-td-weekly">$37.65</td>
                    <td className="table-td-weekly">$13,558</td>
                  </tr>
                  <tr>
                    <td className="table-td">55 - 59</td>
                    <td className="table-td-weekly">$66.95</td>
                    <td className="table-td-weekly">$22,289</td>
                  </tr>
                  <tr>
                    <td className="table-td">60 - 64</td>
                    <td className="table-td-weekly">$148.15</td>
                    <td className="table-td-weekly">$41,548</td>
                  </tr>
                  <tr>
                    <td className="table-td">65 - 69</td>
                    <td className="table-td-weekly">$176.15</td>
                    <td className="table-td-weekly">$64,448</td>
                  </tr>
                  <tr>
                    <td className="table-td">70 - 74</td>
                    <td className="table-td-weekly">$304.65</td>
                    <td className="table-td-weekly">$104,052</td>
                  </tr>
                  <tr>
                    <td className="table-td">75 - 79</td>
                    <td className="table-td-weekly">$619.80</td>
                    <td className="table-td-weekly">$184,626</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <p className="text-left mx-3 table-td-weekly">
                  Note: The above FEGLI totals are cumulative and assume an Age
                  30 start date.
                </p>
              </div>
            </div>
          </div>
          <div className="border-top w-100 m-auto"></div>
          <div className="over-view-container m-auto">
            <div className="d-flex justify-content-center align-items-center mt-3 ">
              <div className="side-section">
                <div>
                  <Row>
                    <Col md={8} sm={8} xs={7} className="optionA-custom-w">
                      <div>
                        <div>
                          <h6 className="title-bold">Basic/Option A (TODAY)</h6>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} sm={4} xs={5} className="optionA-custom-w-2">
                      {" "}
                      <div className="input-wrapper">
                        <b className="fers-b">$</b>
                        <Form.Control
                          className="input-box fegli-Continue "
                          type="text"
                          name="fegli_basic_and_option_a"
                          readOnly
                          value={formatValue(formData.fegli_basic_and_option_a)}
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="common-title">
                    <p>
                      The amount of life insurance decreases at age 65 or at
                      retirement, whichever is later, at a rate of 2% a month
                      until 25% of the amount you had at time of retirement is
                      reached. This also applies for Option A.
                    </p>
                  </div>
                </div>
                <div className="mt-3">
                  <div>
                    <Row className="d-flex  align-items-center ">
                      <Col md={8} sm={8} xs={7} className="optionA-custom-w">
                        {" "}
                        <div>
                          <h6 className="title-bold">
                            Basic/Option A (TOMORROW)
                          </h6>
                        </div>
                      </Col>
                      <Col md={4} sm={4} xs={5} className="optionA-custom-w-2">
                        <div className="input-wrapper mt-1">
                          <b className="fers-b">$</b>
                          <Form.Control
                            type="text"
                            className="input-box fegli-Continue"
                            name="fegli_basic_and_option_a_tomorrow"
                            value={formData.fegli_basic_and_option_a_tomorrow}
                            readOnly
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Row className="d-flex  align-items-end">
                    <Col md={8} sm={8} xs={7} className="optionA-custom-w">
                      {" "}
                      <div>
                        <h6 className="sub-title-option">
                          Private Sector Coverage
                        </h6>
                      </div>
                    </Col>

                    <Col md={4} sm={4} xs={5} className="optionA-custom-w-2">
                      <div className="input-wrapper mt-1">
                        <b className="fers-b">$</b>
                        <Form.Control
                          className="input-box fegli-Continue"
                          name="fegli_s2_private_sector_coverage"
                          type="text"
                          value={formData.fegli_s2_private_sector_coverage}
                          readOnly
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="d-flex  align-items-end mt-1">
                    <Col md={8} sm={8} xs={7} className="optionA-custom-w">
                      {" "}
                      <div>
                        <h6
                          style={{ color: "#08497e" }}
                          className="sub-title-option"
                        >
                          Age 90 "Real Feel" Value
                        </h6>
                      </div>
                    </Col>

                    <Col md={4} sm={4} xs={5} className="optionA-custom-w-2">
                      <div className="input-wrapper ">
                        <b className="fers-b">$</b>
                        <Form.Control
                          className="input-box fegli-Continue"
                          name="fegli_s2_age_90_coverage"
                          type="text"
                          value={formData.fegli_s2_age_90_coverage}
                          readOnly
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-top m-auto"></div>
        <div className="text-center mt-3">
          <div className="head-title">FERS Annuity</div>
        </div>
        <div className="mt-3 over-view-container m-auto ">
          <div className="row">
            <div className="drd-container">
              <div className="seaction-title">
                <div>Desired Retirement Date: </div>
              </div>
            </div>
            <div className="drd-input mb-2">
              <div className="input-wrapper mo-mt-0 pt-0">
                <Form.Control
                  className="input-box fw-bold fegli-Continue text-center"
                  type="text"
                  value={
                    formData["desired_retirement_date"] &&
                    moment(formData["desired_retirement_date"]) &&
                    moment(formData["desired_retirement_date"]).isValid() &&
                    moment(formData["desired_retirement_date"]).format(
                      "MM/DD/yyyy"
                    ) + ` Age ${formData.startTakingIncome}`
                  }
                  readOnly
                />{" "}
              </div>
            </div>
          </div>

          <Row>
            <Col md={9} sm={9} xs={9} className="seaction-title text-nowrap">
              Minimum Retirement Age (MRA):
            </Col>
            <Col md={3} sm={3} xs={3}>
              <div className="input-wrapper">
                {" "}
                <Form.Control
                  className="input-box fers-input-large fegli-Continue  text-center"
                  name="fegli_s2_age_90_coverage"
                  type="text"
                  value={integerValue(formData["MRA"])}
                  readOnly
                />{" "}
              </div>
            </Col>
          </Row>

          <div className="d-flex align-items-center justify-content-center mt-3">
            <div
              style={{
                whiteSpace: "normal",
                padding: "5px 10px",
                marginBottom: "15px",
                border: "1px solid #333",
              }}
            >
              <div className="title-bold">MINIMUM RETIREMENT AGE (MRA)</div>
              <p className="minumum-age-content">
                Age 56+ w/ 30 years of Creditable Service
              </p>
              <p className="minumum-age-content">
                Age 60 w/ 20 years of Creditable Service
              </p>
              <p className="minumum-age-content">
                Age 62 w/ 5 years of Creditable Service
              </p>
            </div>
          </div>
          <div className="align-items-center mt-3">
            <div>
              {" "}
              <div className="title-bold  mt-3">
                {" "}
                FERS Annuity Income Estimate{" "}
              </div>
              {formData["special_provisions"] == "1" ? (
                <>
                  {" "}
                  <>
                    <Row className="mt-2 mb-3  ">
                      <Col
                        md={4}
                        sm={4}
                        xs={4}
                        className="px-1 common-title justify-content-end d-flex"
                      >
                        <b className="Fers-Btags  common-title">1.7%</b>{" "}
                      </Col>

                      <Col md={1} sm={1} xs={1} className="p-0">
                        <b className="Fers-Btags  common-title">X</b>
                      </Col>

                      <Col md={6} sm={6} xs={6} className="p-0">
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              readOnly
                              className="input-box fers-input-large"
                              type="text"
                              name="yearsOfService1"
                              value={
                                formData["fersStatus"]
                                  ? formData.yearsOfService1
                                  : 0
                              }
                            />
                            <p
                              align="center"
                              className="field-description-text"
                            >
                              (Total Years/Months)
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2 mb-3">
                      <Col
                        md={{ span: 1, offset: 4 }}
                        sm={{ span: 1, offset: 4 }}
                        xs={{ span: 1, offset: 4 }}
                      >
                        <b className="Fers-Btags  common-title">X</b>
                      </Col>

                      <Col md={6} sm={6} xs={6} className="p-0">
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              readOnly
                              className="input-box fers-input-large"
                              type="text"
                              name="high3Avg"
                              value={
                                formData["fersStatus"] ? formData.high3Avg : 0
                              }
                            />
                            <p
                              align="center"
                              className="field-description-text"
                            >
                              (High-Three)
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={{ span: 8, offset: 4 }}>
                        <div className="border-top w-100 m-auto"></div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        sm={{ span: 1, offset: 4 }}
                        xs={{ span: 1, offset: 4 }}
                        md={{ span: 1, offset: 4 }}
                      >
                        <b className="Fers-Btags common-title "> = </b>
                      </Col>

                      <Col md={6} sm={6} xs={6} className="p-0">
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              readOnly
                              className="input-box fers-input-large"
                              type="text"
                              name="fersBasicAnnuity1"
                              value={
                                formData["fersStatus"]
                                  ? formData.fersBasicAnnuity1
                                  : 0
                              }
                            />
                            <p className="field-bottom-title" align="center">
                              (No Survivor Benefit)
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="border-top m-auto"></div>
                    <Row className="mt-2 mb-3  ">
                      <Col
                        md={4}
                        sm={4}
                        xs={4}
                        className="px-1 common-title justify-content-end d-flex"
                      >
                        <b className="Fers-Btags  common-title">1%</b>{" "}
                      </Col>

                      <Col md={1} sm={1} xs={1} className="p-0">
                        <b className="Fers-Btags  common-title">X</b>
                      </Col>

                      <Col md={6} sm={6} xs={6} className="p-0">
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              readOnly
                              className="input-box fers-input-large"
                              type="text"
                              name="yearsOfService2"
                              value={
                                formData["fersStatus"]
                                  ? formData.yearsOfService2
                                  : 0
                              }
                            />
                            <p
                              align="center"
                              className="field-description-text"
                            >
                              (Total Years/Months)
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2 mb-3">
                      <Col
                        md={{ span: 1, offset: 4 }}
                        sm={{ span: 1, offset: 4 }}
                        xs={{ span: 1, offset: 4 }}
                      >
                        <b className="Fers-Btags  common-title">X</b>
                      </Col>

                      <Col md={6} sm={6} xs={6} className="p-0">
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              readOnly
                              className="input-box fers-input-large"
                              type="text"
                              name="high3Avg"
                              value={
                                formData["fersStatus"] ? formData.high3Avg : 0
                              }
                            />
                            <p
                              align="center"
                              className="field-description-text"
                            >
                              (High-Three)
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md={{ span: 8, offset: 4 }}>
                        <div className="border-top w-100 m-auto"></div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        sm={{ span: 1, offset: 4 }}
                        xs={{ span: 1, offset: 4 }}
                        md={{ span: 1, offset: 4 }}
                      >
                        <b className="Fers-Btags  common-title"> = </b>
                      </Col>

                      <Col md={6} sm={6} xs={6} className="p-0">
                        <div className="input-wrapper mt-1">
                          <b>$</b>
                          <div className="text-wrapper w-100">
                            <Form.Control
                              readOnly
                              className="input-box fers-input-large"
                              type="text"
                              name="fersBasicAnnuity2"
                              value={
                                formData["fersStatus"]
                                  ? formData.fersBasicAnnuity2
                                  : 0
                              }
                            />
                            <p className="field-bottom-title" align="center">
                              (No Survivor Benefit)
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                  <>
                    <div className="d-flex justify-content-left mt-3">
                      <div className="fersAnnuity">
                        Total Monthly FERS Annuity
                      </div>
                      <div className="input-wrapper mt-1 mx-3">
                        <b>$</b>
                        <div className="text-wrapper w-100">
                          <Form.Control
                            readOnly
                            className="input-box fers-input-large"
                            type="text"
                            name="fersBasicAnnuity"
                            value={
                              formData["fersStatus"]
                                ? formData.fersBasicAnnuity
                                : 0
                            }
                          />
                          <p className="field-bottom-title" align="center">
                            (No Survivor Benefit)
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                </>
              ) : (
                <>
                  {convertValue(formData["yearsOfService"]) >= 20 &&
                  convertValue(formData["MRA"]) >= 62 ? (
                    <>
                      <Row className="mt-2 mb-3  ">
                        <Col
                          md={4}
                          sm={4}
                          xs={4}
                          className=" common-title justify-content-end d-flex"
                        >
                          <b className="Fers-Btags  common-title">1% or 1.1%</b>{" "}
                        </Col>

                        <Col md={1} sm={1} xs={1} className="p-0">
                          <b className="Fers-Btags common-title">X</b>
                        </Col>

                        <Col md={6} sm={6} xs={6} className="p-0">
                          <div className="input-wrapper mt-1">
                            <b className="common-title">$</b>
                            <div className="text-wrapper w-100">
                              <Form.Control
                                readOnly
                                className="input-box fers-input-large fegli-Continue"
                                type="text"
                                name="yearsOfService"
                                value={
                                  formData["fersStatus"]
                                    ? formData.yearsOfService
                                    : 0
                                }
                              />
                              <p
                                align="center"
                                className="field-description-text"
                              >
                                (Total Years/Months)
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          md={{ span: 1, offset: 4 }}
                          sm={{ span: 1, offset: 4 }}
                          xs={{ span: 1, offset: 4 }}
                        >
                          <b className="Fers-Btags  common-title">X</b>
                        </Col>

                        <Col md={6} sm={6} xs={6} className="p-0">
                          <div className="input-wrapper mt-1">
                            <b className="common-title">$</b>
                            <div className="text-wrapper w-100">
                              <Form.Control
                                readOnly
                                className="input-box fers-input-large fegli-Continue"
                                type="text"
                                name="high3Avg"
                                value={
                                  formData["fersStatus"] ? formData.high3Avg : 0
                                }
                              />
                              <p
                                align="center"
                                className="field-description-text"
                              >
                                (High-Three)
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          md={{ span: 1, offset: 4 }}
                          sm={{ span: 1, offset: 4 }}
                          xs={{ span: 1, offset: 4 }}
                        >
                          <b className="Fers-Btags  common-title"> = </b>
                        </Col>

                        <Col md={6} sm={6} xs={6} className="p-0">
                          <div className="input-wrapper mt-1">
                            <b className="common-title">$</b>
                            <div className="text-wrapper w-100">
                              <Form.Control
                                readOnly
                                className="input-box fers-input-large fegli-Continue"
                                type="text"
                                name="fersBasicAnnuity"
                                value={
                                  formData["fersStatus"]
                                    ? formData.fersBasicAnnuity
                                    : 0
                                }
                              />
                              <p className="field-bottom-title" align="center">
                                (No Survivor Benefit)
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <>
                        <Row className="mt-2 mb-3  ">
                          <Col
                            md={4}
                            sm={4}
                            xs={4}
                            className="px-1 common-title justify-content-end d-flex"
                          >
                            <b className="Fers-Btags  common-title">
                              1% or 1.1%
                            </b>{" "}
                          </Col>

                          <Col md={1} sm={1} xs={1} className="p-0">
                            <b className="Fers-Btags common-title">X</b>
                          </Col>

                          <Col md={6} sm={6} xs={6} className="p-0">
                            <div className="input-wrapper mt-1">
                              <div className="text-wrapper w-100">
                                <Form.Control
                                  readOnly
                                  className="input-box fers-input-large fegli-Continue"
                                  type="text"
                                  name="yearsOfService"
                                  value={
                                    formData["fersStatus"] &&
                                    formData.yearsOfService
                                      ? formData.yearsOfService
                                      : "0"
                                  }
                                />
                                <p
                                  align="center"
                                  className="field-description-text"
                                >
                                  (Total Years/Months)
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>

                      <Row className="mt-2 mb-3">
                        <Col
                          md={{ span: 1, offset: 4 }}
                          sm={{ span: 1, offset: 4 }}
                          xs={{ span: 1, offset: 4 }}
                        >
                          <b className="Fers-Btags  common-title">X</b>
                        </Col>

                        <Col md={6} sm={6} xs={6} className="p-0">
                          <div className="input-wrapper mt-1">
                            <b className="common-title">$</b>
                            <div className="text-wrapper w-100">
                              <Form.Control
                                readOnly
                                className="input-box fers-input-large fegli-Continue"
                                type="text"
                                name="high3Avg"
                                value={
                                  formData["fersStatus"]
                                    ? formData.high3Avg
                                    : "0.00"
                                }
                              />
                              <p
                                align="center"
                                className="field-description-text"
                              >
                                (High-Three)
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md={{ span: 8, offset: 4 }}>
                          <div className="border-top w-100 m-auto"></div>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          md={{ span: 1, offset: 4 }}
                          sm={{ span: 1, offset: 4 }}
                          xs={{ span: 1, offset: 4 }}
                        >
                          <b className="Fers-Btags  common-title"> = </b>
                        </Col>

                        <Col md={6} sm={6} xs={6} className="p-0">
                          <div className="input-wrapper mt-1">
                            <b className="common-title">$</b>
                            <div className="text-wrapper w-100">
                              <Form.Control
                                readOnly
                                className="input-box fers-input-large fegli-Continue"
                                type="text"
                                name="fersBasicAnnuity"
                                value={
                                  formData["fersStatus"]
                                    ? formData.fersBasicAnnuity
                                    : 0
                                }
                              />
                              <p className="field-bottom-title" align="center">
                                (No Survivor Benefit)
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
              <div className="">
                <div className="title-bold ">FERS Annuity Income Estimate</div>
                <p className="common-title">
                  if the FERS Survivor Annuity with 25% survivor benefit option
                  is elected upon the death of the Annuitant designated survivor
                  beneficiary will receive:
                </p>
              </div>
              <div className="mt-3">
                <Row className="mt-2 w-100">
                  <Col md={6} sm={7} xs={7} className="p-0 mt-1 px-1">
                    {" "}
                    <h6
                      className=" d-flex align-items-center justify-content-end common-title fw-bold"
                      align="center"
                    >
                      25% Survivor Benefit
                    </h6>
                  </Col>
                  <Col md={1} sm={1} xs={1} className="p-0 text-end">
                    <b className="fers-b">$</b>
                  </Col>
                  <Col md={5} sm={4} xs={4} className=" p-0 ml-1">
                    <div className="input-wrapper d-block">
                      <Form.Control
                        className="input-box fers-input-large fegli-Continue"
                        type="text"
                        name="fersBasicAnnuity25"
                        readOnly
                        value={
                          formData["fersStatus"]
                            ? formatValue(formData.fersBasicAnnuity25)
                            : 0
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <Row className="mt-2 w-100 ">
                <Col md={6} sm={7} xs={7} className="p-0 mt-1 px-1">
                  <h6
                    className=" d-flex align-items-center justify-content-end  common-title fw-bold"
                    align="center"
                  >
                    Reduce Annuity
                  </h6>
                </Col>
                <Col md={1} sm={1} xs={1} className="p-0 text-end">
                  <b className="fers-b">$</b>
                </Col>
                <Col md={5} sm={4} xs={4} className="p-0 ml-1">
                  <div className="input-wrapper d-block">
                    <Form.Control
                      className="input-box fers-input-large fegli-Continue"
                      type="text"
                      name="fersBasicAnnuity95"
                      readOnly
                      value={
                        formData["fersStatus"]
                          ? formatValue(formData.fersBasicAnnuity95)
                          : 0
                      }
                    />
                    <p className="field-bottom-title" align="center">
                      (25% survivor benefit)
                    </p>
                  </div>
                </Col>
              </Row>{" "}
              <Row className="mt-2 w-100">
                <Col md={6} sm={7} xs={7} className="p-0 mt-1 px-1">
                  {" "}
                  <h6
                    className="common-title  d-flex align-items-center justify-content-end  fw-bold"
                    align="center"
                  >
                    50% Survivor Benefit
                  </h6>
                </Col>
                <Col md={1} sm={1} xs={1} className="p-0 text-end">
                  <b className="fers-b">$</b>
                </Col>
                <Col md={5} sm={4} xs={4} className="p-0 ml-1">
                  <div className="input-wrapper d-block">
                    <Form.Control
                      className="input-box fers-input-large fegli-Continue"
                      type="text"
                      name="fersBasicAnnuity50"
                      readOnly
                      value={
                        formData["fersStatus"]
                          ? formatValue(formData.fersBasicAnnuity50)
                          : 0
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-2 w-100 ">
                <Col md={6} sm={7} xs={7} className="p-0 mt-1 px-1">
                  <h6
                    className="common-title fw-bold   d-flex align-items-center justify-content-end"
                    align="center"
                  >
                    Reduce Annuity
                  </h6>
                </Col>
                <Col md={1} sm={1} xs={1} className="p-0 text-end">
                  <b className="fers-b">$</b>
                </Col>
                <Col md={5} sm={4} xs={4} className="p-0 ml-1">
                  <div className="input-wrapper d-block">
                    <Form.Control
                      className="input-box fers-input-large fegli-Continue"
                      type="text"
                      name="fersBasicAnnuity90"
                      readOnly
                      value={
                        formData["fersStatus"]
                          ? formatValue(formData.fersBasicAnnuity90)
                          : 0
                      }
                    />
                    <p className="field-bottom-title" align="center">
                      (50% survivor benefit){" "}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="text-center">
          <div className="head-title mt-3">FERS</div>
        </div>
        <div className="mt-3">
          <div className="over-view-container m-auto">
            <div className="title-bold">SPECIAL RETIREMENT SUPPLEMENT</div>
            <div className="common-title">
              <p>
                Special Retirement Supplement is a percentage of the amount your
                Social Security benefit will be at age 62. It was created by
                Congress to bridge the income gap you have when retiring prior
                to age 62.
              </p>
            </div>

            {formData["fersStatus"] && (
              <div>
                {((formData["MRA"] >= 50 && formData["yearsOfService"] >= 20) ||
                  formData["yearsOfService"] >= 30) &&
                formData["MRA"] < 62 &&
                (formData["special_provisions"] == "1" ||
                  formData["ver_eligible"] == "1") ? (
                  <Planner />
                ) : (
                  <>
                    {formData["MRA"] < 62 &&
                      ((formData["MRA"] >= 60 &&
                        formData["yearsOfService"] >= 20) ||
                        (formData["MRA"] < 60 &&
                          formData["yearsOfService"] >= 30)) && <Planner />}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="mt-3 over-view-container m-auto">
          <div className=" m-auto">
            <h4 className="title-black">Income at Retirement (All)</h4>
          </div>
          <div>
            <div className="title-bold m-auto ">TODAY</div>
            <div className=" d-flex align-items-center justify-content-end mt-2 ">
              <Row className="w-100 m-0 p-0">
                <Col md={8} sm={8} xs={8} className=" p-0">
                  <div>
                    <div className="fw-bold common-title d-flex align-items-center justify-content-end">
                      <div>GROSS MONTHLY INCOME: </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} sm={4} xs={4}>
                  <div className="input-wrapper p-0 d-flex align-items-center">
                    <b>$</b>
                    <Form.Control
                      type="text"
                      placeholder="0.00"
                      className="input-box fers-input-large fegli-Continue"
                      readOnly
                      value={formatValue(formData.monthly_gross_income)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className="mt-3 over-view-container m-auto">
          <div>
            <div className="title-bold  m-auto"> RETIREMENT</div>
            <div className=" m-auto d-flex align-items-center justify-content-end mt-2">
              <Row className="w-100 m-0 p-0">
                <Col md={8} sm={8} xs={8} className="p-0 m-0">
                  <div>
                    <div className="fw-bold common-title d-flex align-items-center justify-content-end">
                      <div>GROSS MONTHLY INCOME: </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} sm={4} xs={4}>
                  <div className="input-wrapper p-0  d-flex align-items-center">
                    <b>$</b>
                    <Form.Control
                      type="text"
                      placeholder="0.00"
                      className="input-box fers-input-large fegli-Continue"
                      readOnly
                      value={formatValue(formData.reduced_monthly_income)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="">
              <div className="d-flex align-items-center justify-content-end mt-2">
                <Row className="w-100 m-0 p-0">
                  <Col md={8} sm={8} xs={8} className="p-0 m-0">
                    <div>
                      <div className="">
                        <div className="section-head-title">
                          Survivorship Deduction:{" "}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} sm={4} xs={4} className="ps-4">
                    <div className="input-wrapper p-0  d-flex align-items-center">
                      <b>$</b>
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        value={formData["survivorshipDeduction"]}
                        className="input-box fers-input-large fegli-Continue"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="d-flex align-items-center justify-content-end mt-2 ">
                <Row className="w-100 m-0 p-0">
                  <Col md={8} sm={8} xs={8} className="p-0 m-0">
                    <div>
                      <div className="">
                        <div className="section-head-title p-0">
                          FEHB Premium Deduction:{" "}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} sm={4} xs={4} className="ps-4">
                    <div className="input-wrapper p-0  d-flex align-items-center">
                      <b>$</b>
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        className="input-box fers-input-large  fegli-Continue"
                        value={formData["FEHB"]}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="d-flex align-items-center justify-content-end mt-2">
                <Row className="w-100 m-0 p-0">
                  <Col md={8} sm={8} xs={8} className="p-0 m-0">
                    <div>
                      <div className="">
                        <div className="section-head-title p-0">
                          Income Tax Deduction:{" "}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} sm={4} xs={4} className="ps-4">
                    <div className="input-wrapper p-0  d-flex align-items-center">
                      <b>$</b>
                      <Form.Control
                        type="text"
                        placeholder="0.00"
                        className="input-box fers-input-large fegli-Continue"
                        value={formData["incomeTaxDeduction"]}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div
              className="border-top mt-1"
              style={{ width: "55%", marginLeft: "45%" }}
            ></div>
            <div className=" m-auto d-flex align-items-center justify-content-end mt-3">
              <Row className="w-100 m-0 p-0">
                <Col md={8} sm={8} xs={8} className="p-0 m-0">
                  <div>
                    <div className="fw-bold common-title d-flex align-items-center justify-content-end">
                      <div>Net MONTHLY INCOME: </div>
                    </div>
                  </div>
                </Col>
                <Col md={4} sm={4} xs={4}>
                  <div className="input-wrapper p-0  d-flex align-items-center">
                    <b>$</b>
                    <Form.Control
                      type="text"
                      placeholder="0.00"
                      className="input-box fers-input-large fegli-Continue"
                      value={formatValue(formData.netPay)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="mt-3 over-view-container m-auto ">
          <div className="w-100 m-auto mt-3">
            <div>
              <div className="title-black">Additional Income Sources </div>
            </div>
          </div>
          <div className="social-container m-auto fw-bold">
            <div className="d-flex align-items-center mt-3">
              <div>
                <span className="common-title">+ SOCIAL SECURITY</span>
                <span className="section-head mx-1">(Taxable Income) </span>
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <div>
                <span className="common-title">+ TSP</span>
                <span className="section-head mx-1">(Taxable Income) </span>
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <div>
                <span className="common-title ">+ OTHER/ROTH TRA</span>
                <span className="section-head-1 mx-1">(Non-Taxable) </span>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-3">
          <div className="head-title">THRIFT SAVINGS PLAN (TSP)</div>
        </div>
        <div className="mt-3">
          <div className="over-view-container m-auto ">
            <div className="mt-2">
              <div className="title-bold">TSP TRADITIONAL BASICS</div>
              <ul
                style={{
                  listStyleType: "disc",
                }}
                className="common-title"
              >
                <li>Not taxed today. Pay tax when money is withdrawn.</li>
                <li>Taxed on growth and interest accumulated.</li>
              </ul>
              <div className="mt-2 common-title">
                <b>Note: Roth and Traditional payroll contributes </b> are both
                included in payroll tax calculations (e.g.,Social Security
                taxes).
              </div>
            </div>
          </div>
          <div className="over-view-container m-auto">
            <div className="title-bold mt-3">EMPLOYER MATCHES</div>
            <table className="section-table common-title">
              <tbody>
                <tr className="common-title">
                  <td style={{ textAlign: "left" }}></td>
                  <td>FERS</td>
                  <td>CSRS</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    1%-3% of basic pay are matched
                  </td>

                  <td>100%</td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left", borderBottom: "1px solid " }}>
                    4%-5% of basic pay are matched
                  </td>
                  <td style={{ borderBottom: "1px solid " }}>50%</td>
                  <td style={{ borderBottom: "1px solid " }}>0%</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>6% +</td>
                  <td>0%</td>
                  <td>0%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="over-view-container m-auto">
            <div className="d-flex align-items-center mt-3">
              <div>
                <div className="fw-bold overView-title text-nowrap">
                  <div>Current TSP Balance: </div>
                </div>
              </div>
              <div className="title-wrapper mx-2">
                <b>$</b>
                <Form.Control
                  type="text"
                  readOnly
                  className="ms-1 input-box fers-input-large"
                  value={formatValue(formData.tsp_s2_balance)}
                />
              </div>
            </div>
          </div>
          <div className="over-view-container m-auto">
            <div className="mt-3">
              <div className="title-bold">TSP LOAN PROGRAM</div>
              <p className="italic-title">Eligibility</p>
              <div>
                <ul
                  style={{
                    listStyleType: "disc",
                  }}
                  className="ul-text"
                >
                  <li>Current employee in a pay status.</li>
                  <li>
                    TSP account must have at least $1,000 In employee
                    contributions and associated earnings.
                  </li>
                  <li>
                    Loan balance must be within limits of federal tax law.
                  </li>
                  <li>
                    Amount left in paycheck after loan payment and other
                    deductions must equal at least 10% of basic pay.
                  </li>
                  <li>
                    Must document expenses for the allowed purposes (described
                    below) or demonstrate financial hardship.
                  </li>
                  <li>
                    {" "}
                    <b>FERS</b> employees must get spousal consent.
                  </li>
                  <li>
                    The loan Interest rate is the G Fund rate on the date the
                    application is processed.
                  </li>
                </ul>
              </div>
              <div>
                <p className="italic-title">Purposes</p>
              </div>
              <div>
                <ul
                  style={{
                    listStyleType: "disc",
                  }}
                  className="ul-text"
                >
                  <li>
                    <b className="purpose-b">
                      Purchase of a Primary Residence -
                    </b>{" "}
                    Primary residence includes a house, condominium, townhouse,
                    or mobile home which is not used on a transient basis.
                  </li>
                  <li>
                    <b className="purpose-b">General Purpose Loans -</b>
                    Loans are available for any purpose according to plan
                    guidelines. You may have one general loan and one
                    residential loan from your TSP account at any one time.
                  </li>
                  <li>
                    <b className="purpose-b">Financial hardship -</b>
                    participants may also qualify for a hardship loan.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 over-view-container m-auto ">
          <div>
            <ul
              style={{
                listStyleType: "disc",
              }}
              className="ul-text"
            >
              <li>
                <b className="purpose-b">General Purpose Loans -</b>
                Loans are available for any purpose according to plan
                guidelines. You may have one general loan and one residential
                loan from your TSP account at any one time.
              </li>
              <li>
                <b className="purpose-b">Financial hardship -</b>
                participants may also qualify for a hardship loan.
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-3 over-view-container m-auto ">
          <div>
            <div className="title-bold">TSP ROTH VS ROTH IRA</div>
            <div>
              <ul
                style={{
                  listStyleType: "disc",
                }}
                className="ul-text"
              >
                <li>
                  <b>TSP</b> Traditional - Not taxed today and pay tax when
                  money is withdrawn.
                </li>
                <li>
                  Emergency Access during your career is limited to hardship
                  withdrawal and TSP Loan.
                </li>
                <li>
                  Five year restriction to withdrawing ROTH TSP funds without
                  penalty
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center">
          <div className="head-title">SOCIAL SECURITY</div>
        </div>
        <div className="mt-3 over-view-container m-auto row">
          <Col md={9} sm={9} xs={8} className=" ">
            <div>
              <div className="common-title ">
                <div>
                  <b>Estimated Social Security Benefit </b>
                </div>
              </div>
            </div>
          </Col>
          <Col md={3} sm={3} xs={4} className="input-wrapper fw-bold">
            <b className="fers-b">$ </b>
            <Form.Control
              type="text"
              placeholder="0.00"
              readOnly
              className="input-box fers-input-large  fegli-Continue"
              value={formatValue(formData["age_62_ss_benefit"])}
            />
          </Col>
        </div>
        <div className="mt-3 over-view-container m-auto">
          <div
            className=" head-title bg-white text-center w-100"
            style={{
              color: "#314975",
            }}
          >
            <b> SOCIAL SECURITY BENEFITS</b>
          </div>
          <div className="d-flex justify-content-center">
            <div>
              <div className="title-bold">
                FEDERAL EMPLOYEES WHO ARE COVERED
              </div>
              <div className="common-title-italic">
                <p>
                  All federal employees newly hired and after December 31, 1983,
                  are covered by Social Security and will pay Social Security
                  taxes. This also includes employees with previous federal
                  service (other than rehired annuitants) if their break in
                  service was a year or longer.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 over-view-container m-auto">
          <div className="d-flex justify-content-center">
            <div>
              <div className="title-bold">FULLY INSURED</div>
              <div className="common-title-italic">
                <p>
                  Being fully insured is a status achieved after a worker
                  accumulates a specific number of "quarters of coverage under
                  the Social Security system. You will need 40 quarters to be
                  fully insured for life. Therefore, an individual employed for
                  at least 10 years in jobs covered by Social Security can
                  normally assume that they are fully insured.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 over-view-container m-auto">
          <div className="d-flex justify-content-center">
            <div>
              <div className="title-bold">FULL BENEFITS</div>
              <div className="common-title-italic">
                <p>
                  In Social Security language, an individual's full (100%)
                  old-age entitlement is referred to as their "Primary Insurance
                  account (PIA)". As a general rule, if you wait until reaching
                  the age of 65 before applying for old-age benefits, the amount
                  you receive is based on your full PIA entitlement. Note that
                  the age at which full retirement benefits are payable will be
                  gradually increased from age 65 to age 67 over the coming
                  years. This will be accomplished by increasing the age for
                  full benefits by two months per year for workers who will
                  reach the age of 62 in the year 2000. So if you were born in
                  1938 or after you may need to be 67 to receive full Social
                  Security.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3 over-view-container m-auto">
          <div className="d-flex justify-content-center">
            <div>
              <div className="title-bold">BASICS</div>
              <div>
                <ul
                  style={{
                    listStyleType: "disc",
                  }}
                  className="ul-text"
                >
                  <li>Make sure you have a recent SS statment</li>
                  <li>
                    Refer to the contact information below for a current
                    statement of benefits or visit your local office.
                  </li>
                  <li>Know your AGE 62 BENEFIT amount.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 over-view-container m-auto">
          <div>
            <div>
              <div className="title-bold">
                QUOTE FROM A SOCIAL SECURITY STATEMENT
              </div>
              <div className="ss-about-social-section mb-3">
                <div className="common-title">
                  <b> ABOUT SOCIAL SECURITY'S FUTURE...</b>
                </div>
                <div className="common-title">
                  <p>
                    Social Security is a contract between generations. Since
                    1935, America has kept the promise of security for its
                    workers and their families. Now, however, the Social
                    Security system is facing serious financial problems, and
                    action is needed soon to make sure the system will be sound
                    when today's younger workers are ready for retirement.
                    Without changes, in 2033 the Social Security Trust Fund will
                    be able to pay only about 75 cents for each dollar of
                    scheduled benefits.* We need to resolve these issues soon to
                    make sure Social Security continues to provide a foundation
                    of protection for future generations.
                  </p>
                </div>
                <ul
                  style={{
                    fontStyle: "italic",
                    listStyleType: "disc",
                  }}
                  className="ul-text"
                >
                  <li>
                    These estimates are based on the Intermediate assumptions
                    from the Social Security Trustees Annual Report to the
                    Congress.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <div className="head-title">The Solution</div>
        </div>

        <div className={isCapitalLegacy ? "section-group" : "section-group2"}>
          <div className="content-box">
            <div className="d-flex justify-content-center">
              <div>
                <div
                  className="fw-bold section-title"
                  style={{
                    width: "100%",
                    marginRight: "30px",
                  }}
                >
                  <div>{legencyDetail["title"]}</div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <div>
                <div className="fw-bold ">
                  <div
                    className="common-title"
                    style={{
                      color: "#347DB4",
                    }}
                  >
                    <b> Bi-Weekly Contribution:</b>
                  </div>
                </div>
              </div>
              <div className="input-wrapper">
                <b className="pt-1 b-weekly mx-2">$</b>
                <div className="text-wrapper">
                  <Form.Control
                    type="text"
                    placeholder="0.00"
                    readOnly
                    className="fegli-Continue fw-bold  w-75"
                    value={formatValue(legencyDetail["bi-weekly"])}
                  />
                </div>
              </div>
            </div>

            <div className="over-view-container m-auto">
              <div className="dispaly-security-data">
                {!isCapitalLegacy ? (
                  <div>
                    <div>
                      <div className="protection-th mt-3">
                        <div className="d-flex justify-content-center mt-3">
                          <div>
                            <div className="fw-bold section-title">
                              <div>LIFE INSURANCE</div>
                            </div>
                          </div>
                        </div>
                        <Row>
                          <Col md={6} sm={6} xs={6} className=""></Col>
                          <div className="mx-5 fw-bold">
                            <div className="row mx-5">
                              <Col md={7} sm={7} xs={7} className=" title">
                                <b></b>
                              </Col>
                              <Col md={5} sm={5} xs={5} className="p-0 title">
                                <b>PROTECTION</b>
                              </Col>
                            </div>
                          </div>
                        </Row>
                        <div className="mx-5">
                          {legencyDetail["lifeInsurance"] &&
                            legencyDetail["lifeInsurance"].length > 0 &&
                            legencyDetail["lifeInsurance"].map(
                              ({ name, value, protection }) => (
                                <div className="d-flex justify-content-between mx-5">
                                  <Col xs={4} className="title">
                                    {name}:
                                  </Col>
                                  <Col xs={4} className="title">
                                    ${value}
                                  </Col>
                                  <Col xs={4} className="  title">
                                    ${protection}
                                  </Col>
                                </div>
                              )
                            )}
                        </div>
                      </div>

                      <div>
                        <div className="greybox">
                          <ul className="grey-box-list1">
                            <li>Level Premium</li>
                            <li>Portable</li>
                            <li>Guaranteed Coverage Amount</li>
                          </ul>
                          <div
                            className="black-strip"
                            style={{ backgroundColor: "transparent" }}
                          >
                            &nbsp;
                          </div>
                          <ul className="grey-box-list1">
                            <li style={{ listStyleType: "none" }}>&nbsp;</li>
                            <li style={{ listStyleType: "none" }}>&nbsp;</li>
                            <li style={{ listStyleType: "none" }}>&nbsp;</li>
                            <li style={{ listStyleType: "none" }}>&nbsp;</li>
                            <li style={{ listStyleType: "none" }}>&nbsp;</li>
                            <li style={{ listStyleType: "none" }}>&nbsp;</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="protection-th mt-3">
                      <div className="d-flex justify-content-center mt-3">
                        <div>
                          <div className="fw-bold section-title">
                            <div>{legencyDetail["secondTitle"]}</div>
                          </div>
                        </div>
                      </div>
                      <div className="fw-bold">
                        <Row>
                          <Col md={6} sm={6} xs={6} className=""></Col>
                          <div className="mx-5 fw-bold">
                            <div className="row mx-5">
                              <Col md={7} sm={7} xs={6} className=" title">
                                <b></b>
                              </Col>
                              <Col md={5} sm={5} xs={6} className="title">
                                <b>VALUE @ 2.2%</b>
                              </Col>
                            </div>
                          </div>
                        </Row>
                        <div className="mx-5">
                          {legencyDetail["otherInsurance"] &&
                            legencyDetail["otherInsurance"].length > 0 &&
                            legencyDetail["otherInsurance"].map(
                              ({ name, value, protection }) => (
                                <div className="d-flex justify-content-between mx-5">
                                  <Col xs={4} className="p-0 title">
                                    {name}:
                                  </Col>
                                  <Col xs={4} className=" title">
                                    ${value}
                                  </Col>
                                  <Col xs={4} className=" greeny title">
                                    ${protection}
                                  </Col>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="greybox">
                        <ul className="grey-box-list1">
                          <li>Payroll Deduction</li>
                          <li>
                            Competitive Rates
                            <ul className="style-star">
                              <li>Guarantee 2.2%</li>
                              <li>Current 4%</li>
                            </ul>
                          </li>
                          <li>Flexible (increase, decrease, stop)</li>
                          <li>Retirement Supplement</li>
                          <li>Emergency Cash Option</li>
                        </ul>
                        <div className="black-strip">INTERIM PAY SOLUTION</div>
                        <ul className="grey-box-list1">
                          <li>Compound Interest</li>
                          <li>No Investment Risk</li>
                          <li>Income for Life Option</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="protection-th mt-3">
                      <div className="d-flex justify-content-center mt-3">
                        <div>
                          <div className="fw-bold section-title">
                            <div>LIFE INSURANCE</div>
                          </div>
                        </div>
                      </div>
                      <Row>
                        <Col md={7} sm={7} xs={7} className=""></Col>
                        <Col md={5} sm={5} xs={5} className="p-0 title">
                          <b>PROTECTION</b>
                        </Col>
                      </Row>
                      <div className="mx-5 fw-bold">
                        {legencyDetail["lifeInsurance"] &&
                          legencyDetail["lifeInsurance"].length > 0 &&
                          legencyDetail["lifeInsurance"].map(
                            ({ name, value, protection }) => (
                              <div className="row mx-5">
                                <Col xs={4} className="p-0 title">
                                  {name}:
                                </Col>
                                <Col xs={4} className=" title">
                                  ${value}
                                </Col>
                                <Col xs={4} className=" title">
                                  ${protection}
                                </Col>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                    <div>
                      <div className="greybox">
                        <ul className="grey-box-list1">
                          <li>
                            Endowment Paid After 10 years
                            <ul className="style-star">
                              <li>(1st year premium refunded)</li>
                            </ul>
                          </li>
                          <li>Level Premium</li>
                          <li>Portable</li>
                          <li>Guaranteed Coverage Amount</li>
                          <li>Preferred Rates Available</li>
                          <li style={{ listStyleType: "none" }}>&nbsp;</li>
                        </ul>
                        <div className="black-strip">
                          CAPITAL LEGACY PLUS Options Available
                        </div>
                        <ul className="grey-box-list1">
                          <li style={{ listStyleType: "none" }}>&nbsp;</li>
                          <li>Spouse Term Coverage Available</li>
                          <li>Child Term Coverage Available</li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div className="protection-th mt-3">
                        <div className="d-flex justify-content-center mt-3">
                          <div>
                            <div className="fw-bold section-title">
                              <div>{legencyDetail["secondTitle"]} </div>
                            </div>
                          </div>
                        </div>
                        <div className="fw-bold">
                          <Row>
                            <Col md={7} sm={7} xs={7} className=""></Col>
                            <Col md={5} sm={5} xs={5} className="p-0 title">
                              <b>VALUE @ 2.2%</b>
                            </Col>
                          </Row>
                          <div className="mx-5">
                            {legencyDetail["otherInsurance"] &&
                              legencyDetail["otherInsurance"].length > 0 &&
                              legencyDetail["otherInsurance"].map(
                                ({ name, value, protection }) => (
                                  <div className="row mx-5">
                                    <Col xs={4} className="p-0 title">
                                      {name}:
                                    </Col>
                                    <Col xs={4} className=" title">
                                      ${value}
                                    </Col>
                                    <Col xs={4} className=" greeny title">
                                      ${protection}
                                    </Col>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="greybox">
                        <ul className="grey-box-list1">
                          <li>Payroll Deduction</li>
                          <li>
                            Competitive Rates
                            <ul className="style-star">
                              <li>Guarantee 2.2%</li>
                              <li>Current 4%</li>
                            </ul>
                          </li>
                          <li>Flexible (increase, decrease, stop)</li>
                          <li>Retirement Supplement</li>
                          <li>Emergency Cash Option</li>
                        </ul>
                        <div className="black-strip">GROWS INCOME TAX FREE</div>
                        <ul className="grey-box-list1">
                          <li>Compound Interest</li>
                          <li>No Investment Risk</li>
                          <li>Income for Life Option</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </div>
  );
};

export default OverView;
