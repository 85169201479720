import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import {
  convertValue,
  formatCalculatedValue,
  formatValue,
  getMonthlyPayment,
  getMraAge,
  integerValue,
  showValue,
} from "../../../services/helperService";

const Summary = ({ submit }) => {
  const { plannerData, onChangeHandler } = usePlannerContext();

  const [formData, setformData] = useState({});
  const [fersBasicAnnuity, setFersBasicAnnuity] = useState();

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);

  useEffect(() => {
    const FEHB =
      convertValue(plannerData["pay_s2_32"]) +
      "." +
      convertValue(plannerData["pay_s2_33"]);
    const tsp_rate =
      convertValue(plannerData["pay_tsp_r_1"]) +
      "." +
      convertValue(plannerData["pay_tsp_r_2"]);
    const stateTax =
      convertValue(plannerData["pay_s2_8"]) +
      "." +
      convertValue(plannerData["pay_s2_9"]);
    const fefTax =
      convertValue(plannerData["pay_s2_5"]) +
      "." +
      convertValue(plannerData["pay_s2_6"]);
    const TAX = convertValue(fefTax) + convertValue(stateTax);
    const FEGLI = convertValue(plannerData["fegli_line4"]);

    const BASIC_LIFE = convertValue(plannerData["fegli_line4"]) * 0.25;
    const OPTION_A = convertValue(plannerData["option_a"]) * 0.25;
    const PROTECTION_TOTAl = OPTION_A + BASIC_LIFE;
    const monthlyGross = convertValue(plannerData["pay_s2_1"]) / 12;

    const fersAnnuityArr = [
      plannerData["fersBasicAnnuity"],
      plannerData["fersBasicAnnuity95"],
      plannerData["fersBasicAnnuity90"],
    ];
    const valueToUse = plannerData["valueToUse"]
      ? plannerData["valueToUse"] - 1
      : 0;
    setFersBasicAnnuity(fersAnnuityArr[valueToUse]);

    const Loss = convertValue(plannerData["loss_in_monthly_income"]);
    let srsyears = plannerData["yearsOfService"];
    if (
      plannerData.have_military_service == "yes" &&
      plannerData.s2s3_receiving_pension == "no"
    ) {
      let month = convertValue(plannerData.military_service_years) * 12;
      month = month + convertValue(plannerData.military_service_months);
      let year = month / 12;
      srsyears = srsyears - year;
    }

    const estimatedsrs =
      convertValue(plannerData["age_62_ss_benefit"]) * (srsyears / 40);

    const SURVIVOR = convertValue(fersBasicAnnuity) * 0.1;
    const TAXDEDUCTION = convertValue(fersBasicAnnuity) * 0.2;

    const FEHBINSURANCE =
      convertValue(plannerData["pay_s2_32"]) +
      "." +
      convertValue(plannerData["pay_s2_33"]);
    const TRADITIONALINSURANCE =
      convertValue(plannerData["pay_s2_26"]) +
      "." +
      convertValue(plannerData["pay_s2_27"]);
    const ROTHINSRANCE =
      convertValue(plannerData["pay_tsp_r_1"]) +
      "." +
      convertValue(plannerData["pay_tsp_r_2"]);
    const serviceYears = plannerData["yearsOfService"]
      ? plannerData["yearsOfService"]
      : convertValue(plannerData["yearsOfService1"]) +
        convertValue(plannerData["yearsOfService2"]);
    let payload = getMonthlyPayment(
      plannerData.tsp_opt_amount,
      plannerData["MRA"]
    );

    setformData({
      ...plannerData,
      ...payload,
      FEHB: formatCalculatedValue(FEHB),
      FEGLI: formatCalculatedValue(FEGLI),
      tsp_rate: formatCalculatedValue(tsp_rate),
      TAX: formatCalculatedValue(TAX),
      BASIC_LIFE: formatCalculatedValue(BASIC_LIFE),
      PROTECTION_TOTAl: PROTECTION_TOTAl ? PROTECTION_TOTAl : 0,
      Loss: formatCalculatedValue(Loss),
      monthlyGross: formatCalculatedValue(monthlyGross),
      estimatedsrs: formatCalculatedValue(estimatedsrs),
      TAXDEDUCTION: formatCalculatedValue(TAXDEDUCTION),
      SURVIVOR: formatCalculatedValue(SURVIVOR),
      FEHBINSURANCE: formatCalculatedValue(FEHBINSURANCE),
      ROTHINSRANCE: formatCalculatedValue(ROTHINSRANCE),
      TRADITIONALINSURANCE: formatCalculatedValue(TRADITIONALINSURANCE),
      OPTION_A: formatCalculatedValue(OPTION_A),
      total_life_insurance: plannerData["total_life_insurance"]
        ? plannerData["total_life_insurance"]
        : 0,
      serviceYears: serviceYears,
      yearsOfServiceat62: formatCalculatedValue(srsyears),
    });

    console.log(plannerData);
  }, [plannerData]);

  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body
        className="gap-section summary-section"
        style={{ marginLeft: "4%" }}
      >
        <div className="text-center">
          <div className="head-title">BENEFITS SUMMARY</div>
        </div>
        <Form
          name="FersForm"
          noValidate
          onSubmit={submitFormdata}
          className="summary"
        >
          <div className="qs-box single-screen">
            <div className="">
              <div className="single-field p-0">
                <div className="summary-container">
                  <div className="section-group">
                    <p className="group-title">
                      <strong>Employment, Pay & Benefits</strong>
                    </p>
                    <div className="section">
                      <table className="section-table-summary">
                        <tbody>
                          <tr>
                            <td className="col-1"></td>
                            <th className="col-2">Name:</th>

                            <td className="col-4">
                              {formData["firstname"]} {formData["lastname"]}
                            </td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <th className="col-2">Date:</th>

                            <td className="col-4">
                              {moment().format("MM/DD/yyyy")}
                            </td>
                            <td className="col-5"></td>
                          </tr>

                          <tr>
                            <td className="col-1"></td>
                            <th className="col-2">Retirement Eligibility:</th>
                            <td colspan="2">
                              Age {integerValue(formData.MRA)} w/{" "}
                              {formData.yearsOfService
                                ? showValue(formData.yearsOfService, false)
                                : 0}{" "}
                              years of service
                            </td>
                          </tr>

                          <tr>
                            <td className="col-1"></td>
                            <th className="col-2">Married:</th>
                            <td className="col-4  text-capitalize">
                              {formData["family_status"] == "married"
                                ? "Y"
                                : "N"}
                            </td>
                            <td className="col-5"></td>
                          </tr>

                          {formData["do_you_have_children"] == "yes" && (
                            <tr>
                              <td className="col-1"></td>
                              <th className="col-2"> Dependent Children:</th>
                              <td className="col-4  ">
                                {formData["dependent_children"]}
                              </td>
                              <td className="col-5"></td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="section mb-0">
                      <table className="section-table-summary tbl-heading">
                        <tbody>
                          <tr>
                            <td className="section-head">
                              PAY <span className="small">(USPS)</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="section-table-summary">
                        <tbody>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Base Rate of Pay</td>
                            <td className="col-4">
                              {showValue(formData["pay_s2_1"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>

                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Bi-weekly Gross Pay</td>

                            <td className="col-4">
                              {showValue(formData["pay_s2_2"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>

                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">FEHB</td>

                            <td className="col-4">
                              {showValue(formData["FEHB"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">FEGLI</td>

                            <td className="col-4">
                              {" "}
                              {showValue(formData["fegli_line4"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">
                              TSP{" "}
                              {formData["pay_s2_25"] == "$"
                                ? "(Fixed)"
                                : formData["pay_s2_25"] == "%"
                                ? "(" + formData["pay_s2_tsp_percent"] + "%)"
                                : "()"}
                            </td>

                            <td className="col-4  text-normal ">
                              {showValue(formData["TRADITIONALINSURANCE"])}
                            </td>
                            <td className="red-font col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">
                              TSP R{" "}
                              {formData["pay_s2_100"] == "$"
                                ? "(Fixed)"
                                : formData["pay_s2_100"] == "%"
                                ? "(" + formData["pay_s2_tsp_r_percent"] + "%)"
                                : "()"}
                            </td>
                            <td className="col-4 text-normal ">
                              {showValue(formData["ROTHINSRANCE"])}
                            </td>
                            <td className="red-font col-5"></td>
                          </tr>

                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">
                              Fed and State Withholdings
                            </td>

                            <td className="col-4">
                              {showValue(formData["TAX"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="section-group">
                    <p className="group-title">
                      <strong>
                        FEGLI - Federal Employee Group Life Insurance
                      </strong>
                    </p>

                    <div className="section">
                      <table className="section-table-summary tbl-heading">
                        <tbody>
                          <tr>
                            <td className="section-head">
                              Short-Term Protection{" "}
                              <span className="font-weight-normal">
                                (Today to Age 60)
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        className="section-table-summary"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tbody>
                          <tr>
                            <td></td>
                            <td className="col-2">Basic Life</td>

                            <td className="col-4  text-normal ">
                              {showValue(formData["fegli_line4"])}
                            </td>
                            <td className="red-font col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Extra Benefit</td>

                            <td className="col-4  text-normal ">
                              {" "}
                              {showValue(formData["fegli_line7"])}
                            </td>
                            <td className="col-5 red-font">
                              * Eliminated at Age 45
                            </td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Option A</td>

                            <td className="col-4">
                              {showValue(formData["option_a"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Option B</td>

                            <td className="col-4 text-normal ">
                              {showValue(formData["fegli_line10"])}
                            </td>
                            <td className="col-5 red-font font-weight-normal">
                              ** Increase in premium
                            </td>
                          </tr>

                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Option C</td>

                            <td className="col-4"> </td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2 indent">Spouse</td>

                            <td className="col-4">
                              {showValue(formData["optionCSpouce"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>

                          <tr>
                            <td className="col-1 border-bottom-2 p-0"></td>
                            <td className="col-2 indent border-bottom-2">
                              Child (Under age 22 or disabled)
                            </td>
                            <td className="col-4 border-bottom-2 p-0">
                              {showValue(formData["optionCChildren"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>

                          <tr className="f-22 font-weight-bold">
                            <td className="col-1"></td>
                            <td className="col-2 bold-total">Total </td>

                            <td className="col-4 bold-total">
                              {formData["total_life_insurance"]
                                ? showValue(formData["total_life_insurance"])
                                : "$0"}
                            </td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2"></td>

                            <td className="col-4"></td>
                            <td className="col-5"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="section">
                      <table className="section-table-summary tbl-heading">
                        <tbody>
                          <tr>
                            <td className="section-head">
                              Permanent Protection{" "}
                              <span className="font-weight-normal">
                                (Age 60+)
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        className="section-table-summary"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tbody>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Basic Life</td>

                            <td className="col-4 text-normal ">
                              {showValue(formData["BASIC_LIFE"])}
                            </td>
                            <td className="col-5 red-font">
                              * 75% reduction at Age 65
                            </td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Extra Benefit</td>

                            <td className="col-4 text-normal ">N/A</td>
                            <td className="col-5 red-font"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Option A</td>

                            <td className="col-4">
                              {" "}
                              {showValue(formData["OPTION_A"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Option B</td>

                            <td className="col-4 text-normal ">N/A</td>
                            <td className="col-5 red-font font-weight-normal">
                              * Unaffordable @{" "}
                              {showValue(formData["age65OptionB"])} mo.
                            </td>
                          </tr>

                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Option C</td>

                            <td className="col-4"></td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2 indent">Spouse</td>

                            <td className="col-4">N/A</td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1 border-bottom-2 p-0"></td>
                            <td className="col-2 indent border-bottom-2">
                              Child (Under age 22 or disabled)
                            </td>

                            <td className="col-4 border-bottom-2 p-0">N/A</td>
                            <td className="col-5"></td>
                          </tr>

                          <tr className="summary-total">
                            <td className="col-1 "></td>
                            <td className="col-2 bold-total">Total</td>

                            <td className="col-4 bold-total text-normal ">
                              {formData["PROTECTION_TOTAl"]
                                ? showValue(formData["PROTECTION_TOTAl"])
                                : "$0"}
                            </td>
                            <td className="col-5 red-font summary-total-text"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2"></td>

                            <td className="col-4"></td>
                            <td className="col-5"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="section">
                      <div className="item-of-imp">
                        FEGLI - ITEMS OF IMPORTANCE
                      </div>
                      <ul className="summary-fengli">
                        <li className="m-0">
                          * <strong>Extra Benefit</strong> eliminated for all
                          Federal Employees at 45.
                        </li>
                        <li className="m-0">
                          ** <strong>Option B</strong> Coverage increases
                          automatically every 5 yrs.
                        </li>
                        <li className="m-0">
                          ** Personal health and cost to fix FEGLI will be
                          jeopardized as you get older.
                        </li>
                        <li className="m-0">
                          ** Permanent Protection not sufficient at Age 90
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="section-group">
                    <p className="group-title">
                      <strong>FERS - Federal Employee Retirement System</strong>
                    </p>
                    <div className="section">
                      <table className="section-table-summary tbl-heading">
                        <tbody>
                          <tr>
                            <td className="section-head">
                              FERS{" "}
                              <span className="small">
                                {" "}
                                FERS Annuity Estimate
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="section-table-summary">
                        <tbody>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Current Age</td>

                            <td className="col-4">
                              {integerValue(formData["age"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">
                              Minimum Retirement Age (MRA)
                            </td>

                            <td className="col-4" colspan="2">
                              Age {integerValue(formData["MRA"])}
                            </td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Years of Service @ MRA</td>

                            <td className="col-4" colspan="2">
                              {formatValue(formData["yearsOfService"])} years of
                              service
                            </td>
                          </tr>

                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">
                              High Three Salary (pre-retirement)
                            </td>

                            <td className="col-4">
                              {showValue(formData["high3Avg"])}
                            </td>
                            <td className="col-5"></td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2">Pre Retirement Income</td>

                            <td className="col-4 text-normal ">
                              {showValue(formData["monthlyGross"])} Gross
                              Monthly
                            </td>
                            <td className="col-5 red-font text-normal ">
                              *({showValue(formData["Loss"])}) Loss of Income
                            </td>
                          </tr>

                          <tr>
                            <td className="col-1"></td>
                            <td className="col-2 summary-annuity">
                              Monthly Annuity
                            </td>

                            <td className="col-4 summary-annuity  text-normal ">
                              {showValue(fersBasicAnnuity)}{" "}
                              <span className="summary-annuity-title">
                                (w/o survivor)
                              </span>
                            </td>
                            <td className="col-5 red-font align-baseline">
                              ** (Taxable Income)
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="section">
                      <table className="section-table-summary tbl-heading">
                        <tbody>
                          <tr>
                            <td className="section-head">
                              SRS
                              <span className="small">
                                {" "}
                                Special Retirement Supplement (to Age 62)
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {formData["MRA"] < 62 &&
                      ((formData["MRA"] >= 60 &&
                        formData["yearsOfService"] >= 20) ||
                        (formData["MRA"] < 60 &&
                          formData["yearsOfService"] >= 30)) ? (
                        <table className="section-table-summary">
                          <tbody>
                            <tr>
                              <td className="col-1 "></td>
                              <td className="col-2 ">
                                Estimated Age 62 Social Security:
                              </td>

                              <td className="col-4" colspan="2">
                                {showValue(formData["age_62_ss_benefit"])}
                              </td>
                            </tr>
                            <tr>
                              <td className="col-1"></td>
                              <td className="col-2">Years of Service @ MRA:</td>

                              <td className="col-4" colspan="2">
                                {parseInt(formData["yearsOfServiceat62"])} years
                              </td>
                            </tr>
                            <tr>
                              <td className="col-1"></td>
                              <td className="col-2 summary-annuity text-normal align-baseline">
                                Estimated SRS Income (until 62)
                              </td>
                              <td className="col-4 summary-annuity align-baseline">
                                {showValue(formData["estimatedsrs"])}{" "}
                                <span className="summary-annuity-title">
                                  (until Age 62)
                                </span>
                              </td>
                              <td className="col-5 red-font align-baseline">
                                ** (Taxable Income)
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (formData["MRA"] < 62 &&
                          formData["special_provisions"] == "1" &&
                          ((formData["MRA"] >= 50 &&
                            formData["yearsOfService"] >= 20) ||
                            formData["yearsOfService"] >= 30)) ||
                        formData["ver_eligible"] == "1" ? (
                        <>
                          <table className="section-table-summary">
                            <tbody>
                              <tr>
                                <td className="col-1 "></td>
                                <td className="col-2 ">
                                  Estimated Age 62 Social Security:
                                </td>

                                <td className="col-4" colspan="2">
                                  {showValue(formData["age_62_ss_benefit"])}
                                </td>
                              </tr>
                              <tr>
                                <td className="col-1"></td>
                                <td className="col-2">
                                  Years of Service @ MRA:
                                </td>

                                <td className="col-4" colspan="2">
                                  {formatValue(formData["yearsOfServiceat62"])}{" "}
                                  years
                                </td>
                              </tr>
                              <tr>
                                <td className="col-1"></td>
                                <td className="col-2 summary-annuity text-normal align-baseline">
                                  Estimated SRS Income (until 62)
                                </td>
                                <td className="col-4 summary-annuity align-baseline">
                                  {showValue(formData["estimatedsrs"])}{" "}
                                  <span className="summary-annuity-title">
                                    (until Age 62)
                                  </span>
                                </td>
                                <td className="col-5 red-font align-baseline">
                                  ** (Taxable Income)
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {plannerData["ver_eligible"] == "1" && (
                            <table className="section-table-summary">
                              <tbody>
                                <tr>
                                  <td className="col-1"></td>
                                  <td className="col-2">
                                    {formData["MRA"] <
                                      getMraAge(plannerData.dob) && (
                                      <b>
                                        *SRS will start the month after you
                                        reach MRA.
                                      </b>
                                    )}
                                  </td>

                                  <td className="col-4"></td>
                                  <td className="col-5"></td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </>
                      ) : (
                        <table className="section-table-summary">
                          <tbody>
                            <tr>
                              <td className="col-1"></td>
                              <td className="col-2">
                                {plannerData["ver_eligible"] == "1" &&
                                formData["MRA"] < getMraAge(plannerData.dob) ? (
                                  <b>
                                    *SRS will start the month after you reach
                                    MRA.
                                  </b>
                                ) : (
                                  "Will Not Qualify for SRS"
                                )}
                              </td>

                              <td className="col-4"></td>
                              <td className="col-5"></td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                    <div className="section">
                      <div className="item-of-imp">
                        FERS &amp; SRS - ITEMS OF IMPORTANCE
                      </div>
                      <ul className="fers-items">
                        <li className="m-0">
                          *Loss of Income Per Month after Retirement (
                          {showValue(formData["Loss"])})
                        </li>
                        <li className="m-0">
                          *Survivor Deduction ({showValue(formData["SURVIVOR"])}
                          )
                        </li>
                        <li className="m-0">
                          **Estimated 20% Fed and State Tax Deduction (
                          {showValue(formData["TAXDEDUCTION"])})
                        </li>
                        <li className="m-0">
                          **FERS and SRS payments are fully taxable
                        </li>
                        <li className="m-0">
                          ****FEHB Health Insurance Premiums (
                          {showValue(formData["FEHBINSURANCE"])})
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="section-group">
                    <p className="group-title">
                      <strong>TSP - Thrift Savings Plan</strong>
                    </p>

                    <div className="section">
                      {(formData["pay_s2_26"] || formData["pay_tsp_r_1"]) && (
                        <>
                          {(formData["pay_s2_25"] == "%" ||
                            formData["pay_s2_25"] == "$") && (
                            <>
                              <div className="body-text-wrapper  mt-3">
                                <table className="section-table-summary tbl-heading">
                                  <tbody>
                                    <tr>
                                      <td className="section-head">
                                        TSP Current Contribution on Paycheck
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <div className="text-space">
                                  {formData["pay_s2_25"] == "%" ? (
                                    <Row>
                                      <Col md={1}></Col>
                                      <Col md={2}>Traditional %</Col>
                                      <Col md={4}>
                                        {showValue(
                                          formData["TRADITIONALINSURANCE"]
                                        )}{" "}
                                        {formData["pay_s2_tsp_percent"]
                                          ? "(" +
                                            formData["pay_s2_tsp_percent"] +
                                            "%" +
                                            ")"
                                          : ""}
                                      </Col>
                                      <Col md={5}></Col>
                                    </Row>
                                  ) : (
                                    formData["pay_s2_25"] == "$" && (
                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={2}>Traditional Fixed</Col>
                                        <Col md={4}>
                                          {showValue(
                                            formData["TRADITIONALINSURANCE"]
                                          )}{" "}
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>
                                    )
                                  )}

                                  {formData["pay_s2_100"] == "%" ? (
                                    <Row>
                                      <Col md={1}></Col>
                                      <Col md={2}>Roth %</Col>
                                      <Col md={4}>
                                        {showValue(formData["tsp_rate"])}{" "}
                                        {formData["pay_s2_tsp_r_percent"]
                                          ? "(" +
                                            formData["pay_s2_tsp_r_percent"] +
                                            "%" +
                                            ")"
                                          : ""}
                                      </Col>
                                      <Col md={5}></Col>
                                    </Row>
                                  ) : (
                                    formData["pay_s2_100"] == "$" && (
                                      <Row>
                                        <Col md={1}></Col>
                                        <Col md={2}>Roth Fixed</Col>
                                        <Col md={4}>
                                          {showValue(formData["tsp_rate"])}{" "}
                                        </Col>
                                        <Col md={5}></Col>
                                      </Row>
                                    )
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <div className="section">
                      <div className="item-of-imp">
                        TSP - ITEMS OF IMPORTANCE
                      </div>
                      <ul className="fers-items">
                        <li className="m-0">* Taxable income at retirement</li>
                        <li className="m-0">
                          * Not accessible until Age 59 1/2 (or retirement)
                          without loan or penalty unless separation occurs at
                          age 55 or later.
                        </li>
                        <li className="m-0">
                          * 10% + current tax rate penalty if withdrawn before
                          59 1/2 or retirement
                        </li>
                        <li className="m-0">
                          * Hardship withdrawals available if negative monthly
                          income is proven
                        </li>
                        <li className="m-0">
                          * Estimated TSP Monthly Income amounts displayed on
                          planner assumes current age and selection of a single
                          life annuity with level payments and no additional
                          features. Monthly amount calculated at a multiple of
                          .0045
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="section-group">
                    <p className="group-title">
                      <strong>Rainy Day Fund</strong>
                    </p>
                    <div className="section">
                      <table className="section-table-summary tbl-heading">
                        <tbody>
                          <tr>
                            <td className="section-head">Summary</td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="section-table-summary">
                        <tbody>
                          <tr className="font-weight-bold">
                            <td className="col-1"></td>
                            <td className="col-12">
                              <ul className="section-content">
                                <li className="m-0">
                                  I need more help understanding the importance
                                  of a Rainy Day Fund.
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr className="font-weight-bold">
                            <td className="col-1"></td>
                            <td className="col-12">
                              <ul className="section-content">
                                <li className="m-0">
                                  I need to create a Rainy Day Fund.
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td className="col-1"></td>
                            <td className="col-12">
                              <ul className="section-content">
                                <li className="m-0">
                                  <div className="font-weight-bold">
                                    I need to create a budget.
                                  </div>
                                </li>
                              </ul>
                              <div className="mx-4">
                                {formData["bpg_ret_f5"] &&
                                  Array.isArray(formData["bpg_ret_f5"]) &&
                                  formData["bpg_ret_f5"].length > 0 && (
                                    <div className="mx-2">
                                      Areas I would like help with:
                                      {formData["bpg_ret_f5"].map((list) => (
                                        <div className="mx-2">
                                          <input
                                            type="checkbox"
                                            checked={true}
                                          />{" "}
                                          {list}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="section">
                      <div className="item-of-imp">
                        Rainy Day Fund - ITEMS OF IMPORTANCE
                      </div>
                      <ul className="fers-items">
                        <li className="m-0">
                          *Always spend less than you earn
                        </li>
                        <li className="m-0">
                          **Rainy Day Funds eventually become Emergency Savings.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="section-group">
                    <p className="group-title">
                      <strong>Social Security</strong>
                    </p>
                    <div className="section">
                      <table className="section-table-summary tbl-heading">
                        <tbody>
                          <tr>
                            <td className="section-head">
                              Social Security Income
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="section-table-summary">
                        <tbody>
                          {convertValue(formData["s8s2_income_amount"]) ? (
                            <tr>
                              <td className="col-1"></td>
                              <td className="col-2 summary-annuity">
                                Income Amount:
                              </td>
                              <td className="col-4  text-normal  summary-annuity">
                                {" "}
                                {showValue(formData["s8s2_income_amount"])}
                              </td>
                              <td className="col-5 red-font">
                                *Taxable income
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {convertValue(formData["s8s2_age_to_begin"]) ? (
                            <tr>
                              <td className="col-1"></td>
                              <td className="col-2">Age to Begin:</td>
                              <td className="col-4  text-normal ">
                                {formData["s8s2_age_to_begin"]}
                              </td>
                              <td className="col-5 red-font"></td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {formData["s8s2_ss_amount_verified"] && (
                            <tr>
                              <td className="col-1"></td>
                              <td className="col-2">Verified by:</td>
                              <td className="col-4  text-normal ">
                                {formData["s8s2_ss_amount_verified"]}
                              </td>
                              <td className="col-5 red-font"></td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="section">
                      <div className="item-of-imp">
                        Social Security - ITEMS OF IMPORTANCE
                      </div>
                      <ul className="fers-items">
                        <li className="m-0">*Taxable income at retirement</li>
                        <li className="m-0">
                          *SS system is financially unstable
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="section-group bg-white">
                    <div className="item-of-imp">
                      ITEMS OF IMPORTANCE - COMPLETE OVERVIEW
                    </div>
                    <div className="item-of-imp-sec">
                      <p className="summary-title">
                        <strong>FEGLI</strong>
                      </p>
                      <ul className="fers-items">
                        <li className="m-0">
                          * <strong>Extra Benefit</strong> eliminated for all
                          Federal Employees at 45.
                        </li>
                        <li className="m-0">
                          ** <strong>Option B</strong> Coverage increases
                          automatically every 5 yrs.
                        </li>
                        <li className="m-0">
                          ** Personal health and cost to fix FEGLI will be
                          jeopardized as you get older.
                        </li>
                        <li className="m-0">
                          ** Permanent Protection not sufficient at Age 90
                        </li>
                      </ul>
                    </div>

                    <div className="item-of-imp-sec">
                      <p className="summary-title">
                        <strong>FERS and SRS</strong>
                      </p>
                      <ul className="fers-items">
                        <li className="m-0">
                          *Loss of Income Per Month after Retirement (
                          {showValue(formData["Loss"])})
                        </li>
                        <li className="m-0">
                          **Survivor Deduction (
                          {showValue(formData["SURVIVOR"])})
                        </li>
                        <li className="m-0">
                          ***Estimated 20% Fed and State Tax Deduction (
                          {showValue(formData["TAXDEDUCTION"])})
                        </li>
                        <li className="m-0">
                          ****FEHB Health Insurance Premiums (
                          {showValue(formData["FEHBINSURANCE"])})
                        </li>
                        <li className="m-0">
                          *****FERS and SRS payments are fully taxable
                        </li>
                      </ul>
                    </div>

                    <div className="item-of-imp-sec">
                      <p className="summary-title">
                        <strong>Thrift Savings Plan</strong>
                      </p>
                      <ul className="fers-items">
                        <li className="m-0">* Taxable Income at retirement</li>
                        <li className="m-0">
                          * Not accessible until Age 59 1/2 (or retirement)
                          without loan or penalty
                        </li>
                        <li className="m-0">
                          * 10% + current tax rate penalty if withdrawn before
                          59 1/2 or retirement
                        </li>
                        <li className="m-0">
                          * Hardship withdrawals available if negative monthly
                          income is proven
                        </li>
                      </ul>
                    </div>
                    <div className="item-of-imp-sec">
                      <p className="summary-title">
                        <strong>Rainy Day Fund</strong>
                      </p>
                      <ul className="fers-items">
                        <li className="m-0">
                          *Always spend less than you earn
                        </li>
                        <li className="m-0">
                          **Rainy Day Funds eventually become Emergency Savings.
                        </li>
                      </ul>
                    </div>
                    <div className="item-of-imp-sec">
                      <p className="summary-title">
                        <strong>Social Security</strong>
                      </p>
                      <ul className="fers-items">
                        <li className="m-0">* Taxable income at retirement</li>
                        <li className="m-0">
                          * SS system is financially unstable
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="my-2">
                    <div className="import-note-container">
                      <div className="import-note-title">
                        Important Notes to File (Optional)
                      </div>
                      <div className="agentnotes">
                        <textarea
                          name="agentNotes"
                          value={formData["agentNotes"]}
                          onChange={(event) =>
                            onChangeHandler(event, setformData)
                          }
                          className="agentnotes-input"
                          placeholder="Include any notes that will be helpful for the federal employee you're assisting. These notes will not included in their summary."
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-wrapper">
            <div className="text-center mt-4">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default Summary;
