import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.scss";
import "react-toastify/dist/ReactToastify.min.css";
import Login from "./components/auth/Login";
import PrivateRoute from "./routes/PrivateRoute";
import BenefitPlanner from "./components/benefitPlanner";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./services/ScrollToTop";
import Guide from "./components/planner-guide/index";
import Report from "./components/summary-report/index";
import benefitService from "./services/BenefitService";
import { PlannerContextProvider } from "./contexts/planner.contexts";
import NotFound from "./components/notFound";
import { useEffect, useState } from "react";

const App = () => {
  const [progressBarPer, setProgressBarPer] = useState(0);
  const [hasQueueReqs, setHasQueueReqs] = useState(false);

  // ID: 6
  const handleOnlineEvent = () => {
    if (navigator.onLine) {
      const queuedRequests =
        JSON.parse(localStorage.getItem("queuedRequests")) || [];
      const queuedRequestsEmail =
        JSON.parse(localStorage.getItem("queuedRequestsEmail")) || [];
      queuedRequests > 0 ? setHasQueueReqs(true) : setHasQueueReqs(false);
      queuedRequestsEmail > 0 ? setHasQueueReqs(true) : setHasQueueReqs(false);
      var prgressOfBar = 0;
      setProgressBarPer(0);
      setTimeout(() => {
        if (queuedRequests.length > 0) {
          // ID: 7
          queuedRequests.forEach((data) => {
            benefitService.updatePlanner(data).then((plandata) => {
              // ID: 8
              var perReqProgress =
                100 / (queuedRequests.length + queuedRequestsEmail.length);
              prgressOfBar = prgressOfBar + perReqProgress;
              setProgressBarPer(Math.round(prgressOfBar));
              console.log("System is online. Data updated.");
            });
          });

          queuedRequestsEmail.forEach((data) => {
            benefitService.sendEmail(data).then((plandata) => {
              // ID: 9
              var perReqProgress =
                100 / (queuedRequests.length + queuedRequestsEmail.length);
              prgressOfBar = prgressOfBar + perReqProgress;
              setProgressBarPer(Math.round(prgressOfBar));
              console.log("System is online. Data updated.");
            });
          });
          // ID: 10
          localStorage.removeItem("queuedRequests");
          localStorage.removeItem("queuedRequestsEmail");
        }
      }, 3000);
    }
  };

  useEffect(() => {
    // ID: 11
    window.addEventListener("online", handleOnlineEvent);

    // ID: 12
    return () => {
      window.removeEventListener("online", handleOnlineEvent);
    };
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <PlannerContextProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/agent-compliance-and-assistance-screen/*"
              element={<Guide />}
            />

            <Route
              path="/bpg/:id/*"
              element={
                <PrivateRoute
                  child={
                    <BenefitPlanner
                      offlineSyncProBar={progressBarPer}
                      syncHasQueueReqs={hasQueueReqs}
                    />
                  }
                />
              }
            />

            <Route
              path="/view-summary-report/:id"
              exact
              element={<PrivateRoute child={<Report />} />}
            />

            <Route path="/" exact element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </PlannerContextProvider>
    </div>
  );
};

const showOfflineNotification = () => {
  const notificationOptions = {
    body: "You are currently offline.",
    icon: "/images/myfedbenefits-logo2.png",
  };

  if ("Notification" in window && Notification.permission === "granted") {
    var notification = new Notification(
      "BPG Notification",
      notificationOptions
    );

    // ID: 13
    notification.id = "offline-notification";
  }
};

const checkOnlineStatus = () => {
  if (
    JSON.parse(localStorage.getItem("queuedRequests")) ||
    JSON.parse(localStorage.getItem("queuedRequestsEmail"))
  ) {
    if (!navigator.onLine) {
      showOfflineNotification();
    }
  }
};

const handleNotification = () => {
  checkOnlineStatus();
  setInterval(checkOnlineStatus, 300000);
};

// ID: 14
window.addEventListener("offline", handleNotification);

export default App;
