import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Form, Row, Col, Image, FloatingLabel } from "react-bootstrap";
import { usePlannerContext } from "../../../contexts/planner.contexts";
import {
  convertValue,
  formatCalculatedValue,
} from "../../../services/helperService";
import Images from "../../CRMs/crm2.0";
import moment from "moment";
const EmergencyForm = ({ submit, validated }) => {
  const { plannerData, handleRadioButton, setOnChangeStatus } =
    usePlannerContext();

  const [formData, setformData] = useState({});

  useEffect(() => {
    if (
      plannerData &&
      typeof plannerData === "object" &&
      Object.keys(plannerData).length > 0
    ) {
      setformData(plannerData);
    }
  }, [plannerData]);
  const [savings, setSavings] = useState("0.00");
  useEffect(() => {
    let savingAmount = formData["expenses_field_monthly_total"];
    savingAmount = convertValue(savingAmount) * (formData["age"] < 65 ? 6 : 12);
    savingAmount = formatCalculatedValue(savingAmount);
    setSavings(savingAmount);
  }, [formData["expenses_field_monthly_total"]]);

  const handleinputChange = (key, event, index) => {
    let payload = formData[key] ? formData[key] : [];
    if (Array.isArray(payload)) {
      payload[index] = event.target.value;
      handleStateByField(key, payload);
    } else {
      payload = [];
      payload[index] = event.target.value;
      handleStateByField(key, payload);
    }
  };

  const handleStateByField = (name, value) => {
    setOnChangeStatus(true);

    setformData({
      ...formData,
      [name]: value,
    });
  };

  const handleState = (name, value) => {
    setformData({
      ...formData,
      [name]: value,
    });
  };
  const handleThriftBlur = (key, index, event) => {
    let value = "";
    let payload = formData[key] ? formData[key] : [];

    if (event.target.value) {
      value = event.target.value.replaceAll(",", "");
      value = parseFloat(value);
    }

    payload[index] = value ? value : "0";

    handleState(key, payload);
  };

  const addMore = () => {
    const s14_account_type =
      formData.s14_account_type && Array.isArray(formData.s14_account_type)
        ? formData.s14_account_type
        : [""];
    const s14_institution_name =
      formData.s14_institution_name &&
      Array.isArray(formData.s14_institution_name)
        ? formData.s14_institution_name
        : [""];
    const s14_balance =
      formData.s14_balance && Array.isArray(formData.s14_balance)
        ? formData.s14_balance
        : [""];

    s14_account_type.push("");
    s14_institution_name.push("");
    s14_balance.push("");
    handleStateByField("s14_account_type", s14_account_type);
    handleStateByField("s14_institution_name", s14_institution_name);
    handleStateByField("s14_balance", s14_balance);
  };

  const removeField = (index) => {
    const s14_account_type = formData.s14_account_type;
    const s14_institution_name = formData.s14_institution_name;
    const s14_balance = formData.s14_balance;

    if (s14_account_type && s14_account_type.length > 0) {
      s14_account_type.splice(index, 1);
    }
    if (s14_institution_name && s14_institution_name.length > 0) {
      s14_institution_name.splice(index, 1);
    }
    if (s14_balance && s14_balance.length > 0) {
      s14_balance.splice(index, 1);
    }

    handleStateByField("s14_balance", s14_balance);
    handleStateByField("s14_institution_name", s14_institution_name);
    handleStateByField("s14_balance", s14_balance);
  };
  const submitFormdata = (event) => {
    submit(event, formData);
  };

  return (
    <>
      <Card.Body className="emergency-saving ">
        <Form
          name="esForm"
          onSubmit={submitFormdata}
          className="emergency-savingform"
          noValidate
          validated={validated}
        >
          <div style={{ textAlign: "center" }}>
            <div className="head-title">Emergency Savings at Retirement</div>
          </div>

          <div className="section">
            <div className="row emergency-saving-check">
              <div className="col-md-9 col-xs-9">
                <p className="section-desc emergency-saving-text">
                  <strong>
                    In the event of an unforeseen financial emergency, are you
                    comfortable with your current amount of emergency savings?
                  </strong>
                </p>
              </div>
              <div className="col-md-3 col-xs-3 d-flex align-items-center">
                <label className="radio-inline emergency-saving-label d-flex align-items-center">
                  <input
                    type="radio"
                    className="emergency-saving-input"
                    name="comfortable_with_your_current_liquid"
                    value="yes"
                    checked={
                      formData.comfortable_with_your_current_liquid == "yes"
                    }
                    onChange={(event) => {
                      handleRadioButton(event, "yes", setformData);
                    }}
                  />{" "}
                  Yes
                </label>
                <label className="radio-inline emergency-saving-label2 d-flex align-items-center">
                  <input
                    type="radio"
                    className="emergency-saving-input"
                    name="comfortable_with_your_current_liquid"
                    value="no"
                    checked={
                      formData.comfortable_with_your_current_liquid == "no"
                    }
                    onChange={(event) => {
                      handleRadioButton(event, "no", setformData);
                    }}
                  />{" "}
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="panel-heading mt-3">
            <i className="fa-solid fa-star start-icon"></i>
            MFB Tip: Why Do You Need A Rainy Day Fund
          </div>
          <div>
            <p className="emergency-saving-p">
              The purpose of a rainy day fund is to protect you against
              unforeseen one-time events, and give you the cash to deal with
              them without upsetting your monthly budget.
            </p>
          </div>
          <Row>
            <Col md={2}>
              <Image
                className="emergency-saving-image"
                src={Images.emergencySavingImage1}
              />
            </Col>
            <Col md={10}>
              <p className="emergency-saving-p">
                A healthy emergency fund will tide you over in the event of a
                major, long-term disruption in your life, such as divorce, job
                loss, medical illness or injury.
              </p>

              <p className="emergency-saving-p">
                Whatever your expenses, multiply them three times. Then you’ll
                know how much cash you need for an emergency fund that would
                last three months. <br></br>Multiply your monthly bills by six
                to calculate how much money you’d need for a prudent six-month
                emergency fund.
                <a href="">www.aarp.org</a>
              </p>
            </Col>
          </Row>
          <div className="panel-heading mt-2">
            <i className="fa-solid fa-star start-icon"></i>
            MFP Tip: Interim Pay
          </div>
          <div>
            <p className="emergency-saving-p">
              Shortly after you submit your application for retirement, OPM will
              send you a claim number called a CSA number. Once your retirement
              file is considered “clear”, you qualify for “Interim Pay” to
              provide you with an income until your claim is
              completed/adjudicated.
            </p>
          </div>
          <Row>
            <Col md={9}>
              <p className="emergency-saving-p">
                These monthly payments normally begin within 4-6 weeks of
                separation. The amount is usually between 40-90% of your
                anticipated retirement annuity, after estimated deductions for
                such items as health insurance, taxes, life insurance or other
                scheduled deductions. These payments last until your case is
                finalized, typically between 60-180 days. At that time, they
                will retroactively pay you a lump sum for any previous
                underpayments (including any unpaid FERS Supplement amount if
                applicable), and begin your retirement annuity.
              </p>
            </Col>
            <Col md={2}>
              <Image
                className="emergency-saving-image"
                src={Images.emergencySavingImage2}
              />
            </Col>
          </Row>

          <div className="panel-heading mt-2">
            <i className="fa-solid fa-star start-icon"></i>
            MFB Tip: Lump Sum Annual Leave Payment
          </div>
          <div>
            <p className="emergency-saving-p">
              At the time of retirement (does not apply to phased retirement)
              employees are entitled to payment for annual leave credited to
              their accounts, including the carry-over balance, the unused leave
              accrued during the year and any unused restored leave. The payment
              is calculated by multiplying the number of hours of accumulated
              annual leave by your applicable hourly rate of pay plus other
              types of pay you would have received while on annual leave. There
              is a maximum accrual amount based upon agency or level of service,
              but the general rule is 3240 hours for most employees, and
              typically 440 hours for USPS employees. This is normally paid out
              in your last paycheck.{" "}
              <span className="bold">
                Example: 440 hours accrued at $20 hourly rate = $8,800.
              </span>
            </p>
          </div>

          <div className="panel-heading mt-2">
            <i className="fa-solid fa-star start-icon"></i>
            MFB Tip: What TSP requires for Emergency Savings
          </div>
          <div>
            <p className="emergency-saving-p">
              Having an emergency fund is considered a necessity by the agencies
              that regulate the financial industry, to protect each individual’s
              retirement savings. However, the annuities offered through TSP and
              sponsored by MetLife do not require an emergency fund as part of
              your retirement planning strategy. This leaves your retirement
              savings at risk in the future, due to unforeseen financial events.
              In addition, after turning on your MetLife income annuity, it is
              important to understand you no longer have access to your funds in
              the event of unforeseen emergencies such as:
            </p>
          </div>
          <ul className="emergency-saving-ul">
            <li className="emergency-li">Family emergencies</li>
            <li className="emergency-li">Hospitalization</li>
            <li className="emergency-li">Vehicle Repairs</li>
            <li className="emergency-li">Medical/ Prescription Costs</li>
            <li className="emergency-li">Housing Repairs</li>
            <li className="emergency-li">Long Term Care Expenses</li>
          </ul>

          <div className="emergency-saving-red-text">
            {" "}
            EMERGENCY SAVING ANALYSIS{" "}
          </div>
          <div className="red-text-sub">
            <Row>
              <Col md={8} className="col-text">
                Minimum Emergency Savings (
                {formData["age"] < 65 ? "6 Months" : "12 Months"})
              </Col>
              <Col md={4} className="col-text">
                ${savings}
              </Col>
            </Row>
            <div style={{ marginLeft: "20px", marginRight: "20px" }}>
              <Row>
                <p className="emergency-saving-text">
                  How much money do you keep in your checking/savings account?
                </p>
              </Row>
              {formData.s14_balance && Array.isArray(formData.s14_balance) ? (
                formData.s14_balance.map((data, index) => (
                  <>
                    {" "}
                    <Row>
                      <Col md={4}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Account:"
                          className="mb-2"
                        >
                          <Form.Control
                            required
                            as="select"
                            type="text"
                            name="s14_account_type"
                            className={"required-input pt-3"}
                            onChange={(event) =>
                              handleinputChange(
                                "s14_account_type",
                                event,
                                index
                              )
                            }
                            value={
                              formData.s14_account_type &&
                              formData.s14_account_type[index]
                            }
                            isInvalid={
                              validated &&
                              (!formData.s14_account_type ||
                                !formData.s14_account_type[index])
                            }
                          >
                            <option></option>
                            <option value="Checking">Checking</option>
                            <option value="Savings">Savings</option>
                            <option value="Money_Market">Money Market</option>
                            <option value="other">other</option>
                          </Form.Control>
                        </FloatingLabel>
                      </Col>
                      <Col md={4}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Institution Name"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            className="thrift-input thrift-input-focus rounded-0 required-input"
                            type="text"
                            name="s14_institution_name"
                            placeholder="Institution Name"
                            autoComplete="off"
                            onChange={(event) =>
                              handleinputChange(
                                "s14_institution_name",
                                event,
                                index
                              )
                            }
                            value={
                              formData.s14_institution_name &&
                              formData.s14_institution_name[index]
                            }
                            isInvalid={
                              validated &&
                              (!formData.s14_institution_name ||
                                !formData.s14_institution_name[index])
                            }
                            required
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={4}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Balance"
                          className="mb-3 thrift-label"
                        >
                          <Form.Control
                            className="thrift-input thrift-input-focus rounded-0 required-input"
                            type="text"
                            min={0}
                            max={100}
                            name="s14_balance"
                            onBlur={(e) =>
                              handleThriftBlur("s14_balance", index, e)
                            }
                            placeholder="Balance"
                            onChange={(event) =>
                              handleinputChange("s14_balance", event, index)
                            }
                            value={
                              formData.s14_balance &&
                              formData.s14_balance[index]
                            }
                            isInvalid={
                              validated &&
                              (!formData.s14_institution_name ||
                                !formData.s14_institution_name[index])
                            }
                            required
                            autoComplete="off"
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    {index > 0 && (
                      <div className="c-pointer remove-align mb-1">
                        <div
                          className="survey-remove remove-text-savings"
                          onClick={() => removeField(index)}
                        >
                          Remove
                        </div>
                      </div>
                    )}
                  </>
                ))
              ) : (
                <Row>
                  <Col md={4}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Account:"
                      className="mb-2"
                    >
                      <Form.Control
                        required
                        as="select"
                        type="text"
                        name="s14_account_type"
                        className={"required-input pt-3"}
                        onChange={(event) =>
                          handleinputChange("s14_account_type", event, 0)
                        }
                        value={
                          formData.s14_account_type &&
                          formData.s14_account_type[0]
                        }
                        isInvalid={
                          validated &&
                          (!formData.s14_account_type ||
                            !formData.s14_account_type[0])
                        }
                      >
                        <option></option>
                        <option value="Checking">Checking</option>
                        <option value="Savings">Savings</option>
                        <option value="Money_Market">Money Market</option>
                        <option value="other">other</option>
                      </Form.Control>
                    </FloatingLabel>
                  </Col>
                  <Col md={4}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Institution Name"
                      className="mb-3 thrift-label"
                    >
                      <Form.Control
                        className="thrift-input thrift-input-focus rounded-0 required-input"
                        type="text"
                        name="s14_institution_name"
                        placeholder="Institution Name"
                        onChange={(event) =>
                          handleinputChange("s14_institution_name", event, 0)
                        }
                        value={
                          formData.s14_institution_name &&
                          formData.s14_institution_name[0]
                            ? formData.s14_institution_name[0]
                            : ""
                        }
                        isInvalid={
                          validated &&
                          (!formData.s14_institution_name ||
                            !formData.s14_institution_name[0])
                        }
                        autoComplete="off"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={4}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Balance"
                      className="mb-3 thrift-label"
                    >
                      <Form.Control
                        className="thrift-input thrift-input-focus rounded-0 required-input"
                        type="text"
                        min={0}
                        max={100}
                        name="s14_balance"
                        onBlur={(e) => handleThriftBlur("s14_balance", 0, e)}
                        placeholder="Balance"
                        onChange={(event) =>
                          handleinputChange("s14_balance", event, 0)
                        }
                        value={formData.s14_balance && formData.s14_balance[0]}
                        isInvalid={
                          validated &&
                          (!formData.s14_balance || !formData.s14_balance[0])
                        }
                        autoComplete="off"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              )}

              <div className="mt-2 c-pointer">
                <a className="thrift-a" onClick={addMore}>
                  +Add Account
                </a>
              </div>
            </div>{" "}
          </div>
          {validated && (
            <p className="red text-center mt-4">
              *Complete required fields to continue.
            </p>
          )}

          <div>
            <div className="text-center">
              <button className="btn-next btn question-next" type="submit">
                CONTINUE
              </button>
            </div>
          </div>
        </Form>
      </Card.Body>
    </>
  );
};

export default EmergencyForm;
